import React, { useContext, useState } from 'react';
import swal from 'sweetalert';
import validator from 'validator';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import Button from '../Button';
import securePinImg from '../../../assets/image/secure-pin.png';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpPost } from '../../../action/http';
// import { sendResetPinOtpViaEmail } from '../../../store/calls';
import VerifyOtp from './Otp/VerifyOtp';

export default function ResetPin(props) {
  const {
    showModal,
    hideModal,
    separator,
    title,
    description,
    buttonLabel,
    numInputs,
    handleResendOtp,
    setModalAction,
    otpType,
    businessId,
  } = props;
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [otp, setOtp] = useState('');
  const [showVerifyOtp, setShowVerifyOtp] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    profile: { email, phoneNumber },
  } = useContext(ProfileContext);

  const handleSubmit = async () => {
    setLoading(true);
    if (validator.isEmpty(pin)) {
      swal('Oops!', 'PIN is required', 'error');
      setLoading(false);
      return;
    }

    if (!validator.equals(pin, confirmPin)) {
      swal('Oops!', 'PIN do not match', 'error');
      setLoading(false);
      return;
    }

    if (validator.isEmpty(otp)) {
      swal('Oops!', 'Otp is required', 'error');
      setLoading(false);
      return;
    }

    console.log(otpType);

    const postData = {
      phoneOrEmail: otpType === 'email' ? email : phoneNumber,
      otp,
      pin,
      businessId,
    };

    const res = await httpPost(
      `/api/v1/pin/forgot-pin`,
      postData,
      customBaseUrl.authUrl
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);
      swal('Done', res.message, 'success').then(() => {
        setShowVerifyOtp(false);
        hideModal(false);
        setModalAction('verifyPin');
        //  localStorage.clear();
        //  window.location.reload(true);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  return (
    <div id="pin-modal">
      <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
        <ModalHeader className="justify-content-center border-0">
          <div className="text-center">
            <img src={securePinImg} alt="pin" style={{ height: '163px' }} />
          </div>
          <div>
            <h1 className="modal-header-data mb-0">{title}</h1>

            <p className="text-center text-secondary mt-2">{description}</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-10 col-lg-10 mx-auto">
            <div className="mt-3">
              <p className="mb-0">Input PIN</p>
              <OtpInput
                value={pin}
                onChange={(e) => setPin(e)}
                numInputs={numInputs}
                separator={separator}
                containerStyle={{ justifyContent: 'space-between' }}
                inputStyle="otp-inputs"
                isInputSecure
                isInputNum
              />
            </div>
            <div className="mt-3">
              <p className="mb-0">Confirm New PIN</p>
              <OtpInput
                value={confirmPin}
                onChange={(e) => setConfirmPin(e)}
                numInputs={numInputs}
                separator={separator}
                containerStyle={{ justifyContent: 'space-between' }}
                inputStyle="otp-inputs"
                isInputSecure
                isInputNum
              />
            </div>
            {/* <div className="mt-3">
              <p className="mb-0">Input Otp</p>
              <OtpInput
                value={otp}
                onChange={(e) => setOtp(e)}
                numInputs={numInputs}
                separator={separator}
                containerStyle={{ justifyContent: 'space-between' }}
                inputStyle="otp-inputs"
              />
            </div> */}

            <div className="mt-3 mb-4 text-center">
              <Button
                type="button"
                className="btn btn-primary btn-lg w-100"
                loading={loading}
                disabled={loading || false}
                onClick={handleSubmit}
                content={buttonLabel}
              />
            </div>
            <div className="mt-3 text-center">
              <a
                className="text-dark decoration-none"
                onClick={(e) => {
                  e.preventDefault();
                  handleResendOtp(true);
                }}
                role="button"
                tabIndex={0}
                aria-hidden="true"
                href="/#"
              >
                Resend Code
              </a>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify OTP"
          description="Please input the OTP sent to your email address or phone number"
          isResendOtp={false}
          buttonLabel="Verify"
          handleSubmit={() => setShowVerifyOtp(false)}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
    </div>
  );
}

ResetPin.defaultProps = {
  separator: <span>-</span>,
  title: '',
  description: '',
  buttonLabel: 'Login',
  numInputs: 4,
};

ResetPin.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  separator: PropTypes.node,
  buttonLabel: PropTypes.string,
  numInputs: PropTypes.number,
};
