import React, { useState, useEffect, useContext } from 'react';
// import merge from 'react-merge';
// import _ from 'lodash';
import { customBaseUrl, httpGet } from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';
import Layout from '../../shared/Layout';
import Team from '../../shared/Modals/Invite/Team';
// import Delete from '../../../assets/image/delete.sv/g';
import Settings from '../../../assets/image/settings.svg';
import Add from '../../../assets/add 1.svg';
// import TSuccess from '../../../assets/2fa-green.svg';
import Support from '../../../assets/support.svg';
// import TGrey from '../../../assets/2fa-grey.svg';
import Back from '../../../assets/image/back.svg';
import './index.scss';
import PinModal from '../../shared/Modals/PinModal';
import EditAccess from './EditAccess';
import RemoveTeamMember from './RemoveTeamMember';
import ChangeRole from './ChangeRole';
import ChangeBusinessOwner from './ChangeBusinessOwner';
import AddCustomRole from './AddCustomRole';
// import Table from '../../shared/Table';
import { hideLoader, showLoader } from '../../../utils/loader';
import UpdateRole from './updateRole';

export default function Index(props) {
  const {
    // profile: { userId },
    userDetails,
  } = useContext(ProfileContext);
  const [active, setActive] = useState('Team');

  const [team, setTeam] = useState([]);
  const [roles, setRoles] = useState([]);
  const [invitee, setInvitee] = useState({});
  const [selectedInvitee, setSelectedInvitee] = useState([]);
  const [showInviteModal, setInviteModal] = useState(false);
  const [showChangeRole, setShowChangeRole] = useState(false);
  const [showRemoveTeamMember, setShowRemoveTeamMember] = useState(false);
  const [showChangeBusinessOwner, setShowChangeBusinessOwner] = useState(false);
  const [showAddCustomRole, setShowAddCustomRole] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showEditAccess, setShowEditAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [teamMode, setTeamMode] = useState('table');
  const [otp, setOtp] = useState('');
  const [confirmEditAccess, setConfirmEditAccess] = useState(false);
  // const [privileges, setPrivileges] = useState();
  const [rolePrivileges, setRolePrivileges] = useState([]);
  // const [roleCannotList, setRoleCannotList] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [customRoles, setCustomRoles] = useState([]);
  const [privilleges, setPrivillages] = useState([]);
  const [showAddPermission, setShowAddPermission] = useState(false);
  const [roleCannotList, setRoleCannotList] = useState([]);
  const [roleCategory, setRoleCategory] = useState('');

  const items = [
    // 'Profile',
    // 'KYC',
    'Team',
    // 'Payout Account',
    // 'API Keys and Webhooks',
  ];

  // const mergeDistinctArrayObjects = (array) => {
  //   const merged = _.merge(_.keyBy(array, 'id'), _.keyBy(array, 'id'));
  //   return _.values(merged);
  // };

  // const getRoleCannot = (role) => {
  //   const allPrivileges = roles.map((x) => x.rolePrivileges);
  //   // console.log('allp111', allPrivileges);
  //   // const allPrivilegesFlat = _.flatten(allPrivileges);
  //   // console.log('allpFlat', allPrivilegesFlat);
  //   // allPrivileges = mergeDistinctArrayObjects(allPrivilegesFlat);
  //   // console.log('allp', allPrivileges);
  //   setRoleCannotList(
  //     allPrivileges.filter(
  //       (x) =>
  //         !role.privileges.find(
  //           (item) => item.id === x.id && item.name === x.name
  //         )
  //     )
  //   );
  // };

  const handleSetSelectedRolePrivileges = (role, category) => {
    setSelectedRole(role);
    getRoleCannot(role);
    console.log('privileges', role);
    setRolePrivileges(role.permission);
    setRoleCategory(category);
    // getRoleCannot(role);
    console.log('test', rolePrivileges);
  };
  // const getRoles = async () => {
  //   const res = await httpGet(`${customBaseUrl.roleUrl}/api/v1/roles/custom`);
  //   if (res) {
  //     setRoles(res);
  //   }
  // };
  const getRoles = async () => {
    showLoader();
    const response = await httpGet(`${customBaseUrl.roleUrl}/api/v1/role/all`);
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setRoles(response);
    console.log('roles', roles);
    //  handleSetSelectedRolePrivileges(response[0]);
  };

  const getCustomRoles = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/roles-permission`
    );
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setCustomRoles(response);
    console.log('customroles', response);
    //  handleSetSelectedRolePrivileges(response[0]);
  };
  const getPrevivllages = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/all/permissions`
    );
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setPrivillages(response);
    console.log('roles', roles);
    //  handleSetSelectedRolePrivileges(response[0]);
  };
  const getRoleCannot = (role) => {
    console.log('role perm', role.permission);
    setRoleCannotList(
      privilleges?.filter(
        (x) =>
          !role?.permission.find(
            (item) => item.description === x.description && item.code === x.code
          )
      )
    );
  };
  const getInvitee = async () => {
    const res = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/user-access/corporate/invitee`
    );
    if (res) {
      console.log('>>>', res?.data);
      setInvitee(res?.data);
    }
  };

  // const handleSetSelectedRolePrivileges = (role) => {
  //   setSelectedRole(role);
  //   // setRolePrivileges(role.privileges);
  //   // getRoleCannot(role);
  // };

  // const handleRolePriviledge = () => {
  //   setPrivileges();
  // };

  const handleVerifyPin = async () => {
    setShowPin(false);
  };

  // const getPreviledges = async () => {
  //   const response = await httpGet(
  //     `${customBaseUrl.roleUrl}/api/v1/all/permissions`
  //   );
  //   console.log('privileges', response);
  //   const body = response.body.data;
  //   setPrivileges(body.privileges);
  //   console.log('privil', privileges);
  // };

  const getTeam = async () => {
    const res = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/corporate/user/role/find/by/cooperate/user/id/${userDetails?.userId}`
    );

    if (res?.status) {
      // setTeam(res?.data);
      console.log('tttt', team);
    }
  };
  useEffect(() => {
    console.log('roleCannoT', roleCannotList);
    console.log('privill', privilleges);
  }, [selectedRole]);

  useEffect(() => {
    getTeam();
    getRoles();
    getCustomRoles();
    getPrevivllages();
    getInvitee();
    // getPermissions();
    // getPreviledges();
  }, []);
  console.log('TEAM', team);
  console.log('Roles', roles);
  console.log(`Invitee`, invitee);
  // console.log('cannot', roleCannotList);
  // console.log('user', userId);
  // console.log(`Permissions`, permissions);
  return (
    <Layout route={props} currentPage="settings">
      <div
        className="menu-options mt-3 w-100"
        style={{
          minHeight: '50px',
          position: 'relative',
          borderBottom: '1px solid #e5e5e5',
        }}
      >
        <div
          className="lato-text px-3 w-100"
          style={{
            color: '#b6b3b3',
            display: 'flex',
          }}
        >
          {items?.map((val) => (
            <div
              className={
                val === active ? 'active add-cursor me-5' : 'add-cursor me-5'
              }
              onClick={() => {
                setActive(val);
              }}
              key={Math.random()}
            >
              {val}
            </div>
          ))}
        </div>
      </div>

      <div
        className="bg-white mx-auto mt-5"
        style={{
          boxSizing: 'border-box',
          boxShadow: '0px 0px 0px #E5E5E5',
          borderRadius: '4px',
          width: '85%',
        }}
      >
        {active === 'Team' ? (
          <div className="w-100 ">
            {teamMode === 'table' ? (
              <div className="border p-5">
                <div className="top w-100 d-flex mb-3 justify-content-between">
                  <div className="left">
                    Team members-{invitee?.length ?? 0}
                  </div>
                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <button
                      type="button"
                      className="btn"
                      style={{
                        background: ' #F2F2F2',
                        border: '0.5px solid #E5E5E5',
                        boxSizing: 'border-box',
                        boxShadow: ' 0px 0px 0px #E5E5E5',
                        borderRadius: '2px',
                      }}
                      onClick={() => {
                        setTeamMode('roles');
                      }}
                    >
                      <img src={Settings} alt="" className="me-2" />
                      Manage roles
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary ms-3"
                      onClick={() => setInviteModal(true)}
                    >
                      Invite someone
                    </button>
                  </div>
                </div>
                {/* <Table
                  paging={false}
                  columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Roles', field: 'roles' },
                    { title: '2FA Status', field: 'permission' },
                    { title: 'Actions', field: 'action' },
                  ]}
                  data={
                    team?.length &&
                    team.map((member) => ({
                      name: member?.name,
                      email: member?.email,
                      roles: member?.roles,
                      permission: member?.status,
                      action: (
                        <div className="d-flex align-content-center">
                          <span
                            style={{
                              background: '#FFFFFF',
                              border: '1px solid #D0CFCF',
                              boxSizing: 'border-box',
                              borderRadius: '3px',
                              padding: '5px 5px',
                            }}
                            onClick={() => {
                              setShowEditAccess(true);
                            }}
                          >
                            Edit access
                          </span>
                          <img
                            className="my-auto"
                            src={Delete}
                            alt=""
                            style={{
                              width: '16px',
                              height: '16px',
                              marginLeft: '25px',
                            }}
                          />
                        </div>
                      ),
                    }))
                  }
                /> */}
                <table className="table  mx-auto">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Roles</th>
                      {/* <th scope="col">2FA Status</th> */}
                      <th scope="col">Phone Number</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {invitee?.length
                      ? invitee?.map((item) => (
                          <tr key={item?.id}>
                            <td>{item?.inviteeName}</td>
                            <td>{item?.email}</td>
                            <td>{item?.roleName}</td>
                            <td>
                              {/* <img src={TSuccess} alt="" /> Enabled */}
                              {item?.phoneNumber}
                            </td>
                            <td className="d-flex justify-content-between">
                              <button
                                type="button"
                                style={{
                                  width: '48.5%',
                                  background: '#FFFFFF',
                                  border: '1px solid #6A6A6A',
                                  boxSizing: 'border-box',
                                  borderRadius: '3px',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  fontSize: '10px',
                                  lineHeight: '12px',
                                  color: '#6A6A6A',
                                  padding: '6px 7px',
                                }}
                                onClick={() => {
                                  setSelectedInvitee(item);
                                  setShowChangeRole(true);
                                }}
                              >
                                Change Role
                              </button>
                              <button
                                type="button"
                                style={{
                                  width: '48.5%',
                                  background: '#FFFFFF',
                                  border: '1px solid #DADADA',
                                  boxSizing: 'border-box',
                                  borderRadius: '3px',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  fontSize: '10px',
                                  lineHeight: '12px',
                                  color: '#6A6A6A',
                                  padding: '6px 7px',
                                }}
                                onClick={() => {
                                  setSelectedInvitee(item);
                                  setShowRemoveTeamMember(true);
                                }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                </table>
              </div>
            ) : (
              ''
            )}

            {teamMode === 'roles' ? (
              <div className="w-100">
                <div className="w-100">
                  <div
                    onClick={() => {
                      setTeamMode('table');
                    }}
                  >
                    <img src={Back} alt="" /> Back
                  </div>
                </div>
                <div className="role  mt-3">
                  <div className="role-p-1">
                    <div className="role-p-1-1-head">Default Roles</div>
                    <div className="role-p-1-1-body">
                      {roles?.length ? (
                        roles?.map((role) => (
                          <div
                            className="role-p11-body-item"
                            key={Math.random()}
                            onClick={() => {
                              handleSetSelectedRolePrivileges(role, 'default');
                            }}
                          >
                            {role.name}
                          </div>
                        ))
                      ) : (
                        <div className="role-p11-body-item">Null</div>
                      )}
                    </div>
                    <div className="role-p-1-2-head">Custom Roles</div>
                    <div className="role-p-1-2-body">
                      {customRoles?.map((e) => (
                        <div
                          key={e?.name}
                          className="mb-4"
                          onClick={() => {
                            handleSetSelectedRolePrivileges(e, 'custom');
                          }}
                        >
                          {e?.name}
                        </div>
                      ))}
                      <div
                        className="d-flex  mb-5"
                        onClick={() => {
                          setShowAddCustomRole(true);
                        }}
                      >
                        <img src={Add} alt="" />
                        <span>Add Custom Role</span>
                      </div>
                    </div>
                  </div>
                  <div className="role-p-2">
                    <div className="role-p-2-head">
                      <div className="role-p-2-h1">
                        {selectedRole.description}
                      </div>
                      <div className="role-p-2-h2">
                        {/* This role grants users the permissions to manage
                        everything on the dashboard*/}
                      </div>
                    </div>
                    <div className="role-p-2-head-2">
                      {/* 10 Members with this role:Adeyanju Akorede, Philip
                      Templar, Adeyanju Gabriel, Clever Eziogor, Samuel
                      Aniefiok, John Mary, Adeyanju Akorede, Philip Templar,
                      Adeyanju Gabriel, Clever Eziogor, John Mary */}
                      {/* {selectedRole.description} */}
                      {selectedRole.description && roleCategory === 'custom' && (
                        <div
                          className="d-flex  mb-5"
                          onClick={() => {
                            setShowAddPermission(true);
                          }}
                        >
                          <img src={Add} alt="" />
                          <span>Add privilleges</span>
                        </div>
                      )}
                    </div>
                    <div className="body-content">
                      <div className="permitted">
                        <ul className="list-group">
                          <li
                            className="list-group-item "
                            style={{
                              background: 'rgba(139, 184, 159, 0.2)',
                              color: '#52936F',
                            }}
                          >
                            {`${selectedRole.description} `}
                            Can
                          </li>
                          {rolePrivileges?.map((d) => (
                            <li className="list-group-item" key={d.name}>
                              {d.description}
                            </li>
                          ))}
                          {/* <li className="list-group-item">
                            Can View Business Performance Metrics
                          </li>
                          <li className="list-group-item">
                            Can View Transactions
                          </li>
                          <li className="list-group-item ">
                            Can Export Transactions
                          </li>
                          <li className="list-group-item ">
                            Can Manage Refunds & Disputes
                          </li>
                          <li className="list-group-item ">
                            Can View Business Performance Metrics
                          </li>
                          <li className="list-group-item ">
                            Can Manage Refunds & Disputes
                          </li>
                          <li className="list-group-item ">
                            Can View Business Performance Metrics
                          </li>
                          <li className="list-group-item ">
                            Can Manage Refunds & Disputes
                          </li>
                          <li className="list-group-item ">
                            Can View Business Performance Metrics
                          </li> */}
                        </ul>
                      </div>
                      <div className="not-permitted">
                        <ul className="list-group">
                          <li
                            className="list-group-item"
                            style={{
                              background: 'rgba(218, 142, 142, 0.2)',
                              color: '#C67777',
                            }}
                          >
                            {selectedRole.description}
                            Cannot
                          </li>
                          {roleCannotList?.map((d) => (
                            <li className="list-group-item" key={d.name}>
                              {d.description}
                            </li>
                          ))}
                          <li className="list-group-item ">
                            {` Can't View Business Performance Metrics`}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <div
        className="w-100 my-5 d-flex justify-content-end"
        style={{
          paddingRight: '91px',
          paddingLeft: '91px',
        }}
      >
        <button
          className="d-flex"
          type="button"
          style={{
            background: '#FF6634',
            border: '0.5px solid #E0E0E0',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 0px #E5E5E5',
            borderRadius: '25px',
            padding: '8px 10px',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '20px',
            letterSpacing: '0.2px',
            color: '#FFFFFF',
          }}
        >
          <img src={Support} alt="" className="me-2" />
          <span>support</span>
        </button>
      </div>*/}

      {showInviteModal ? (
        <Team
          showModal={showInviteModal}
          hideModal={setInviteModal}
          id="invite-team"
          setData={setData}
          data={data}
          setShowPin={setShowPin}
          defaultRoles={roles}
        />
      ) : (
        ''
      )}
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          setLoading={setLoading}
          separator=""
          buttonLabel="Continue"
          title="Invite Team Member"
          description="Enter Your PIN to Continue"
          handleSubmit={handleVerifyPin}

          // setShowResendOtp={setShowResendOtp}
          // setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
      {confirmEditAccess ? (
        <PinModal
          center
          showModal={confirmEditAccess}
          hideModal={setConfirmEditAccess}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          setLoading={setLoading}
          separator=""
          buttonLabel="Continue"
          title="Edit Access"
          description="Enter Your PIN to Continue"
          handleSubmit={handleVerifyPin}
        />
      ) : (
        ''
      )}

      {showEditAccess ? (
        <EditAccess
          showModal={showEditAccess}
          hideModal={setShowEditAccess}
          setShowPin={setConfirmEditAccess}
        />
      ) : (
        ''
      )}

      {showChangeRole ? (
        <ChangeRole
          showModal={showChangeRole}
          hideModal={setShowChangeRole}
          data={data.roleId}
          setData={setData}
          role={roles}
          selectedUser={selectedInvitee}
          getInvitee={getInvitee}
        />
      ) : (
        ''
      )}

      {showChangeBusinessOwner ? (
        <ChangeBusinessOwner
          showModal={showChangeBusinessOwner}
          hideModal={setShowChangeBusinessOwner}
        />
      ) : (
        ''
      )}
      {showRemoveTeamMember ? (
        <RemoveTeamMember
          showModal={showRemoveTeamMember}
          hideModal={setShowRemoveTeamMember}
          id={selectedInvitee.id}
          email={selectedInvitee.email}
          getInvitee={getInvitee}
        />
      ) : (
        ''
      )}

      {showAddCustomRole ? (
        <AddCustomRole
          showModal={showAddCustomRole}
          hideModal={setShowAddCustomRole}
          privilleges={privilleges}
          getCustom={getCustomRoles}
        />
      ) : (
        ''
      )}
      {showAddPermission ? (
        <UpdateRole
          showModal={showAddPermission}
          hideModal={setShowAddPermission}
          privilleges={roleCannotList}
          getCustom={getCustomRoles}
          selectedRole={selectedRole}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
