import React, { useState, useContext, useEffect } from 'react';
import validator from 'validator';
import swal from 'sweetalert';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import btnIcon from '../../../../assets/image/bt-icon.png';
import ussdIcon from '../../../../assets/image/ussd-icon.png';
import Button from '../../Button';
import Generate from '../../Generate';
import { CardContext } from '../../../../store/context/CardContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import moneyFomatter from '../../../../utils/moneyFormatter';

// import { AddMoreIcon, RepeatIcon } from '../../Icons';
// import { FundViaBankAccountOptions } from './style';

export const MainContent = (props) => {
  const { setShowPin, walletList, setData, data, myCardList } = props;
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <ModalBody>
        <div className="text-center mt-3 mb-3">Bank Card</div>
        <form className="mx-auto">
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={data.cardNumber}
              onChange={(e) => {
                setData({
                  ...data,
                  cardNumber: e.target.value,
                });
              }}
            >
              <option value="Select" selected disabled>
                Card to Debit
              </option>
              {myCardList.length
                ? myCardList.map((item) => (
                    <option value={item.cardNumber} key={item.cardNumber}>
                      {`${item.accountName}-${item.type}`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">Your Card to debit</span>
          </div>

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              defaultValue={data.walletAccountNo}
              onChange={(e) => {
                const [acccountNo, amount] = e.target.value.split(',');
                setData({
                  ...data,
                  walletAccountNo: acccountNo,
                });
                setBalance(amount);
              }}
            >
              <option value="Select" selected disabled>
                Select Account
              </option>
              {walletList.length
                ? walletList.map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item?.accountNo}
                    >
                      {`${item?.description} - ${item?.accountNo} (${
                        item.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Amount"
              type="number"
              value={data.amount}
              onChange={(e) => {
                setData({
                  ...data,
                  amount: e.target.value,
                });
              }}
            />
            <span className="input-detail">How much do you want to send?</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="btn btn-primary mx-auto mb-3 mt-3"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            setLoading(true);
            if (validator.isEmpty(data?.cardNumber || '')) {
              swal('Oops!', 'You are yet to select a card', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data?.walletAccountNo || '')) {
              swal('Oops!', 'Select Account to credit to proceed', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data?.amount || '')) {
              swal('Oops!', 'Amount cannot be empty', 'error');
              setLoading(false);
              return;
            }
            setLoading(false);
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
};

export function BankTransfer(props) {
  const { virtualAccount, walletList } = props;
  const [defaultWallet, setDefaultWallet] = useState([]);
  useEffect(() => {
    console.log('walll', walletList);
    const result = walletList.filter((item) => item?.walletDefault);
    setDefaultWallet(result[0]);
    console.log('default', result[0]);
  }, []);

  return (
    <div
      className="mt-3 mb-3 px-5 text-center section-a h-100"
      style={{ overflow: 'auto' }}
    >
      <h3 className="mt-3">
        <img src={btnIcon} alt="btn" /> Bank Transfer
      </h3>
      <p style={{ opacity: '0.5' }}>
        Fund your Wayabank Account via Bank Transfer. Here is your Account
        Details.
      </p>
      <div className="p-2 dial-card mb-3 mt-3 mx-auto copy-card">
        <p>{defaultWallet?.acct_name}</p>
        <p> {defaultWallet?.accountNo}/ WAYA MICROFINANCE Bank </p>
      </div>
      <div
        className="text-start"
        style={{ fontSize: '13px', fontWeight: '600' }}
      >
        <p className="">
          Fund your Wayabank Account from any E-Bank/Mobile Banking Or From Any
          Bank Desk
        </p>
        <p className="">
          This serve as your account number you can also use it to receieve
          money from others in the manner as your various bank accounts
        </p>
        <ol>
          <li>Copy your Waya Account Number above</li>
          <li>Go to your e-Banking / Mobile banking / Bank Desk</li>
          <li>Choose WAYA MICROFINANCE BANK as bank name</li>
          <li>Enter your Wayabank Account Number</li>
          <li>Enter amount</li>
          <li>On the Ref, enter your note</li>
          <li>Click on transfer button & refresh your Wayabank App</li>
          <li>Transfer</li>
          <li>Your account would be funded</li>
        </ol>
      </div>
    </div>
  );
}

export function BankAccount(props) {
  // const { setShowExistingModal } = props;
  const {
    setShowPin,
    walletList,
    setData,
    data,
    // bankAccountList,
    // availableBank,
  } = props;
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <ModalBody>
        <div className="text-center mt-3 mb-3">Bank Account</div>
        <form className="mx-auto">
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            {/* <select
              name="filter"
              className=""
              value={data.bankName}
              onChange={(e) => {
                const [name, code, bankName] = e.target.value.split(',');
                // resolveBank(code, name, e.target.value);
                if (availableBank.data.find((item) => item.code === code)) {
                  console.log('found', code, name);
                } else {
                  swal(
                    'Wrong',
                    `${bankName} is not supported for wallet funding. However it can be used for withdrawal`,
                    'warning'
                  );
                }
              }}
            >
              <option value="Select" selected disabled>
                Select Bank Account
              </option>
              {bankAccountList.length
                ? bankAccountList.map((item) => (
                    <option
                      value={`${item.accountName},${item.bankCode},${item.bankName}`}
                      key={item.bankCode}
                    >
                      {item.bankName}
                    </option>
                  ))
                : ''}
            </select> */}
          </div>
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              defaultValue={data.walletAccountNo}
              onChange={(e) => {
                setBalance('0.00');
                const [accountNo, amount] = e.target.value.split(',');
                setData({
                  ...data,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
              }}
            >
              <option value="Select" selected disabled>
                Select Account
              </option>
              {walletList.length
                ? walletList.map((item) => (
                    <option
                      value={`${item.accountNo},${item?.clr_bal_amt}`}
                      key={item.accountNo}
                    >
                      {`${item?.description}-${item?.accountNo}-(${item?.acct_crncy_code}${item?.clr_bal_amt})`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Amount"
              type="number"
              onChange={(e) => {
                setData({
                  ...data,
                  amount: e.target.value,
                });
              }}
            />
            <span className="input-detail">How much do you want to send?</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="btn btn-primary mx-auto mb-3 mt-3"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            setLoading(true);
            if (validator.isEmpty(data?.walletAccountNo || '')) {
              swal('Oops!', 'Select account to credit to proceed', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data?.amount || '')) {
              swal('Oops!', 'Amount cannot be empty', 'error');
              setLoading(false);
              return;
            }
            setLoading(false);
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}

export function USSDCode(props) {
  const {
    setData,
    data,
    handleFundByUssdTopUp,
    handleFundUssdByWithdraw,
    handleFundUssdByTransferToWallet,
    loading,
    ussdCode,
  } = props;
  const [balanceTo, setBalanceTo] = useState(null);
  const [balanceFrom, setBalanceFrom] = useState(null);
  const { cardList } = useContext(CardContext);
  const { walletList } = useContext(WalletContext);
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
  const [ussdType, setUssdType] = useState('USSD_TOPUP');
  const toggle = () => setShowMoreInfoModal(!showMoreInfoModal);

  const [walletListTwo, setWalletListTwo] = useState([]);

  const handleWallet = (acctNo) => {
    const removedSelected = [...walletList].filter(
      (item) => item.accountNo !== acctNo
    );
    setWalletListTwo(removedSelected);
  };

  useEffect(() => {
    setData({
      ...data,
      type: 'USSD_TOPUP',
    });
  }, []);

  return (
    <>
      <ModalBody>
        <div className="text-center mb-3">
          <button type="button" onClick={toggle}>
            More info
          </button>
          <Modal
            isOpen={showMoreInfoModal}
            className="text-center mt-3 mb-3 h-100"
          >
            <div
              className="p-2 text-center section-a mx-auto my-auto"
              style={{ height: '200px' }}
            >
              <h3 className="mt-3">
                <img src={ussdIcon} alt="ussd" /> USSD Code
              </h3>
              <div className="text-primary p-2 dial-card copy-card mx-auto mt-3 mb-3">
                <a
                  className="text-decoration-none"
                  href={`tel:${ussdCode} || '*347*006#'`}
                >
                  Dial USSD Code
                </a>
              </div>
              <div>
                Dial{' '}
                <a
                  className="text-decoration-none"
                  href={`tel:${ussdCode} || '*384*0035#'`}
                >
                  *384*0035#
                </a>{' '}
                to perform Waya transaction
              </div>
            </div>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-primary mb-3 mt-3"
                color="primary"
                onClick={toggle}
              >
                Ok
              </button>
            </ModalFooter>
          </Modal>
        </div>
        <form className="mx-auto">
          <div className="inputbox-with-one-input">
            <select
              value={ussdType}
              onChange={(e) => {
                setUssdType(e.target.value);
                setData({
                  ...data,
                  type: e.target.value,
                });
              }}
            >
              <option value="USSD_TOPUP">Top up wallet</option>
              <option value="USSD_WITHDRAWAL">External Transfer</option>
              <option value="USSD_WALLET_TO_WALLET">Internal Transfer</option>
            </select>
          </div>
          {ussdType === 'USSD_TOPUP' ? (
            <div className="inputbox-with-one-input">
              <select
                onChange={(e) => {
                  setData({
                    ...data,
                    card_index: e.target.value,
                  });
                }}
              >
                <option value="Select" selected disabled>
                  Select Card
                </option>
                {cardList.length
                  ? cardList.map((item, index) => (
                      <option value={index} key={item.cardNumber}>
                        {`${item.accountName}-${item.type}`}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : ussdType === 'USSD_WITHDRAWAL' ? (
            <div className="inputbox-with-one-input">
              <input
                type="text"
                placeholder="Enter beneficiary Account Number"
                value={data.recipient_account_no}
                onChange={(e) => {
                  setData({
                    ...data,
                    recipient_account_no: e.target.value,
                  });
                }}
              />
            </div>
          ) : (
            <>
              <div className="inputbox-with-one-input mt-3">
                <select
                  name="filter"
                  className=""
                  defaultValue={data.owner_wallet}
                  onChange={(e) => {
                    const [accountNo, amount] = e.target.value.split(',');
                    setData({
                      ...data,
                      owner_wallet: accountNo,
                    });
                    handleWallet(accountNo);
                    setBalanceFrom(amount);
                  }}
                >
                  <option value="Select" selected disabled>
                    Select Account to Debit
                  </option>
                  {walletList.length
                    ? walletList.map((item) => (
                        <option
                          value={`${item?.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {`${item?.acct_crncy_code}-${item?.accountNo}`}
                        </option>
                      ))
                    : ''}
                </select>

                <span className="input-detail">
                  Your Balance is N{balanceFrom || 0}.00
                </span>

                <select
                  name="filter"
                  className=""
                  defaultValue={data.recipient_wallet}
                  onChange={(e) => {
                    const [accountNo, amount] = e.target.value.split(',');
                    setData({
                      ...data,
                      recipient_wallet: accountNo,
                    });
                    setBalanceTo(amount);
                  }}
                >
                  <option value="Select" selected disabled>
                    Select Account to Credit
                  </option>
                  {walletListTwo.length
                    ? walletListTwo.map((item) => (
                        <option
                          value={`${item?.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {`${item?.acct_crncy_code}-${item?.accountNo}`}
                        </option>
                      ))
                    : ''}
                </select>
                <span className="input-detail">
                  Your Balance is N{balanceTo || 0}.00
                </span>
              </div>
            </>
          )}

          <div className="inputbox-with-one-input mb-2">
            <input
              type="number"
              placeholder="Amount"
              value={data.amount}
              onChange={(e) => {
                setData({
                  ...data,
                  amount: e.target.value,
                });
              }}
            />
            <span className="input-detail">How much do you want to send?</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Generate
          type="button"
          className="btn btn-primary mx-auto mb-5"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            if (ussdType === 'USSD_TOPUP') {
              if (validator.isEmpty(data.card_index || '')) {
                return swal('Oops!', 'You are yet to select a card', 'error');
              }
              if (validator.isEmpty(data.amount)) {
                return swal('Oops!', 'Amount cannot be empty', 'error');
              }
              return handleFundByUssdTopUp();
            }
            if (ussdType === 'USSD_WITHDRAWAL') {
              return handleFundUssdByWithdraw();
            }
            return handleFundUssdByTransferToWallet();
          }}
        >
          Generate USSD Code
        </Generate>
      </ModalFooter>
    </>
  );
}
