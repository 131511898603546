import React, { useContext } from 'react';
import swal from 'sweetalert';
import './style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { customBaseUrl, httpPost, httpPut } from '../../../action/http';
import Button from '../../shared/Button';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { hideLoader, showLoader } from '../../../utils/loader';

const ChangeRole = (props) => {
  const {
    showModal,
    hideModal,
    selectedUser,
    data,
    setData,
    role,
    getInvitee,
  } = props;
  // const [roles, setRoles] = useState([]);
  const { userDetails: userId } = useContext(ProfileContext);

  // const getRoles = async () => {
  //   const res = await httpGet(`${customBaseUrl.roleUrl}/api/v1/roles/custom`);
  //   if (res) {
  //     setRoles(res);
  //   }
  //   console.log('roles1', roles);
  // };

  console.log('roles1', role);
  const handleTransfer = async () => {
    showLoader();
    const roleData = {
      roleId: data,
      email: selectedUser?.email,
    };
    console.log('resres', role);
    console.log('res', data);
    const res = await httpPut(
      `/api/v1/user-access/invite/corporate/waya/${selectedUser?.id}`,
      roleData,
      customBaseUrl.roleUrl
    );
    if (res?.status !== 404 && res?.status !== false) {
      hideLoader();
      swal('Role Change', res?.message);
      getInvitee();
    } else {
      hideLoader();
      swal('Error', res?.message);
    }
    hideModal();
  };

  // useEffect(() => {
  //   getRoles();
  // }, []);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      size="md"
    >
      <ModalHeader
        className="justify-content-center"
        style={{
          background: '#F1F7FA',
          fontFamily: 'Lucida Bright',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '28px',
          color: '#064A72',
          padding: '30px',
          borderRadius: '14px 14px 0 0 !important',
        }}
      >
        Change Role
      </ModalHeader>
      <ModalBody
        className="text-center"
        style={{
          padding: '49px 55px 33px 55px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '135.6%',
          color: '#4F4F4F',
        }}
      >
        <div className="mb-4 w-100">
          This action will change the role of this user. This user will no
          longer have the access to the previous role.
        </div>
        <div className="my-5 w-100">
          <select
            name=""
            id=""
            className="w-100"
            style={{
              fontFamily: 'Lucida Bright',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '19px',
              /* identical to box height */
              color: '#828282',
            }}
            value={data}
            onChange={(e) => {
              setData({
                ...data,
                roleId: e.target.value,
              });
            }}
          >
            <option value="">Select New Role For This User</option>
            {role?.length
              ? role.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))
              : ''}
          </select>
        </div>

        <Button
          content="Transfer"
          onClick={handleTransfer}
          style={{
            width: '100%',
            marginTop: '50px',
          }}
        />
        <div
          className="text-primary mt-2"
          onClick={() => {
            hideModal(false);
          }}
        >
          Cancel
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChangeRole;
