import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPut,
} from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';
import LinkBvn from '../../shared/Modals/Bvn';
import PinModal from '../../shared/Modals/PinModal';
import Button from '../../shared/Button';
import ManualBvn from './uploadManualBvn';
import KYCUploadMode from './uploadModeModal';

export default function VerifyEmailAndPhone(props) {
  const { showAlert, type, setShowAlert } = props;
  const [showBvn, setShowBvn] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendVerifyEmailOtp, setResendVerifyEmailOtp] = useState(false);
  const [nibssUrl, setNibssUrl] = useState('');
  const [showManualBvn, setShowManualBvn] = useState(false);
  const [showMode, setShowMode] = useState(false);
  const [uploadMode, setUploadMode] = useState('auto');
  const {
    profile,
    setVerifiedEmailReload,
    setEmailVerified,
    setPhoneVerified,
    setReloadUser,
    reloadUser,
    kycData,
  } = useContext(ProfileContext);
  const getNibss = async () => {
    const res = await httpGet(`${customBaseUrl.kycUrl}/api/v1/kyc/redirect`);
    // console.log('nibsss', res);
    setNibssUrl(res);
  };

  useEffect(() => {
    if (type === 'bvn') setShowMode(true);
    getNibss();
    if (resendVerifyEmailOtp) console.log('resend otp to email');
    return () => {
      setResendVerifyEmailOtp(false);
    };
  }, [resendVerifyEmailOtp]);

  const handleUpdateKycEmail = async () => {
    const email1 = kycData?.tier1?.filter(
      (e) => e?.requirementType === 'EMAIL'
    )[0];
    await httpPut(
      `/api/v1/kyc/updateCustomerTierData/${email1.id}/${email1.customerId}?attachment=${profile.email}&reference=email`,
      {},
      customBaseUrl.kycUrl
    );
  };
  const handleUpdateKycPhone = async () => {
    const phone1 = kycData?.tier1?.filter(
      (e) => e?.requirementType === 'PHONE_NUMBER'
    )[0];
    await httpPut(
      `/api/v1/kyc/updateCustomerTierData/${phone1.id}/${phone1.customerId}?attachment=${phone1.attachment}&reference=${phone1?.verificationReference}`,
      {},
      customBaseUrl.kycUrl
    );
  };

  const handleVerifyEmail = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp-mail/${profile.email}?businessId=${profile.loginId}`
    );
    if (res.status) {
      swal('Done', res.message, 'success');
      setShowPin(true);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  const handleVerifyEmailOtp = async () => {
    const res = await httpPost(
      `/api/v1/auth/verify-email`,
      {
        phoneOrEmail: profile.email,
        otp,
        businessId: profile.loginId,
      },
      customBaseUrl.authUrl
    );
    if (res.status) {
      swal('Done', 'Email verified successfully.', 'success');
      setShowPin(false);
      setOtp('');
      setEmailVerified(true);
      handleUpdateKycEmail();
      setVerifiedEmailReload(true);
      setReloadUser(!reloadUser);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  const handleResendOtpPhone = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/${profile.phoneNumber}?businessId=${profile.loginId}`
    );
    if (res.status) {
      swal('Done', res.message, 'success');
      setShowPin(true);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  const handleVerifyPhoneOtp = async () => {
    const res = await httpPost(
      `/api/v1/auth/verify-phone`,
      {
        phoneOrEmail: profile.phoneNumber,
        otp,
        businessId: profile.loginId,
      },
      customBaseUrl.authUrl
    );
    if (res.status) {
      swal('Done', 'Phone number verified successfully.', 'success');
      setShowPin(false);
      setOtp('');
      handleUpdateKycPhone();
      setPhoneVerified(true);
      setVerifiedEmailReload(true);
      setReloadUser(!reloadUser);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  const handleSendOtpTo = () => {
    if (type === 'verifyEmail') {
      handleVerifyEmailOtp();
    } else {
      handleVerifyPhoneOtp();
    }
  };

  return (
    // <div
    //   className={!showAlert ? 'd-none' : 'text-center notification-div mx-auto'}
    // >
    <Modal
      isOpen={showAlert}
      toggle={() => setShowAlert(false)}
      centered
      id="verification"
    >
      <ModalBody>
        {type === 'bvn' && (
          <div className="">
            <p className="text-center">Click on Verify to Add BVN</p>
            <div className="w-100 mt-5 text-center">
              <Button
                type="button"
                className="btn btn-primary btn-lg w-50"
                loading={false}
                disabled={false}
                onClick={() => {
                  if (uploadMode === 'manual') {
                    setShowManualBvn(true);
                  } else {
                    setShowBvn(true);
                  }
                }}
                content="verify"
              />
            </div>
          </div>
        )}

        {type === 'verifyEmail' && (
          <div className="">
            <p className="text-center">
              Click on Verify to recieve OTP to your registered Email address
              for Verififcation
            </p>
            <div className="w-100 mt-5 text-center">
              <Button
                type="button"
                className="btn btn-primary btn-lg w-50"
                loading={false}
                disabled={false}
                onClick={() => {
                  handleVerifyEmail();
                }}
                content="verify"
              />
            </div>
          </div>
        )}

        {type === 'verifyPhone' && (
          <div className="">
            <p className="text-center">
              Click on Verify to recieve OTP to your registered Phone Number for
              Verififcation
            </p>
            <div className="w-100 mt-5 text-center">
              <Button
                type="button"
                className="btn btn-primary btn-lg w-50"
                loading={false}
                disabled={false}
                onClick={() => {
                  handleResendOtpPhone();
                }}
                content="verify"
              />
            </div>
          </div>
        )}

        {showBvn ? (
          <LinkBvn
            showModal={showBvn}
            hideModal={setShowBvn}
            id="bvn-wrapper"
            nibssUrl={nibssUrl}
          />
        ) : (
          ''
        )}

        {showPin ? (
          <PinModal
            center
            showModal={showPin}
            hideModal={setShowPin}
            otp={otp}
            setOtp={setOtp}
            numInputs={6}
            separator=""
            buttonLabel="Verify"
            title="Verify Your Account"
            description={
              type === 'verifyEmail'
                ? 'Please input the OTP sent to your Email Address'
                : 'Please input the OTP sent to your Phone'
            }
            isResendOtp
            resendOtp={setResendVerifyEmailOtp}
            handleSubmit={handleSendOtpTo}
          />
        ) : (
          ''
        )}
        {showManualBvn ? (
          <ManualBvn
            hideModal={setShowManualBvn}
            showModal={showManualBvn}
            // uploadMode={uploadMode}
            // setUploadMode={setUploadMode}
            hideAlert={setShowAlert}
          />
        ) : (
          ''
        )}
        {showMode ? (
          <KYCUploadMode
            hideModal={setShowMode}
            showModal={showMode}
            uploadMode={uploadMode}
            setUploadMode={setUploadMode}
          />
        ) : (
          ''
        )}
      </ModalBody>
    </Modal>
  );
}
