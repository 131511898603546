import React, { useState, useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { MdRadioButtonUnchecked, MdOutlineContentCopy } from 'react-icons/md';
import { BsCheckCircle } from 'react-icons/bs';
import CopyToClipboard from 'react-copy-to-clipboard';
// import excapeRegExp from 'escape-string-regexp';
// import { useHistory } from 'react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import bgRectangle from '../../../assets/image/Rectangle-1430.svg';
// import searchIcon from '../../../assets/image/mdi_search.svg';
import airbnbLogo from '../../../assets/image/Airbnb.svg';
import youtubeIcon from '../../../assets/image/YouTubeIcon.svg';
import uberLogo from '../../../assets/image/UberLogo.svg';
import barcodeIcon from '../../../assets/image/s-barcode.png';
import questionIcon from '../../../assets/image/question.png';
// import Copy from '../../../assets/image/u_copy-alt.svg';

import { CardDetails } from './style';
// import truncateWithEllipses from '../../../utils/truncate';
// import { ProfileContext } from '../../../store/context/ProfileContext';
import { WalletContext } from '../../../store/context/WalletContext';
import moneyFomatter from '../../../utils/moneyFormatter';
import { BlockRounded, VerifiedUserOutlined } from '@material-ui/icons';

export const historyDatas = [
  {
    text: 'Maitama, Abuja',
    date: 'Yesterday',
    time: '11:22 AM',
    amount: '-N5000',
    icon: airbnbLogo,
  },
  {
    text: 'Maitama, Abuja',
    date: 'Nov 26th, 2020',
    time: '10:03 PM',
    amount: '+N5000',
    icon: youtubeIcon,
  },
  {
    text: 'Wuse Trip',
    date: 'Nov 26th, 2020',
    time: '02:00 AM',
    amount: '-N2000',
    icon: uberLogo,
  },
];

export default function TransactionContent(props) {
  //   const TRUE = true;
  const { /* setShowQrCodeModal, */ showAlert, clear } = props;
  //   const { profile } = useContext(ProfileContext);
  const { walletList, /* transactionHistory, */ setDefaultWallet } =
    useContext(WalletContext);
  const [walletNo, setWalletNo] = useState(0);
  const [copied, setCopied] = useState(false);

  const updateCurrentSlide = (index) => {
    setWalletNo(index);
    setDefaultWallet(walletList[index]);
    clear();
  };
  //   console.log('walletNo', walletList);
  useEffect(() => {
    if (walletList?.length > 0) {
      updateCurrentSlide(0);
    }
  }, [walletList]);

  return (
    <>
      <div
        className="mid-bottom-content"
        style={{ marginTop: 15, marginBottom: 15 }}
      >
        <div
          className={
            showAlert
              ? 'wayapay-wallet mt-2 border-0'
              : 'wayapay-wallet border-0'
          }
        >
          <div className="scrollLeftContainer">
            <div className="scrollLeft">
              {walletList?.length ? (
                walletList?.map((item, i) => (
                  <div
                    className="each-item_2"
                    key={Math.random()}
                    onClick={() => updateCurrentSlide(i)}
                    style={{
                      border: `1px solid ${
                        walletNo === i ? '#FF6700' : '#e0e0e0'
                      }`,
                    }}
                  >
                    <CardDetails>
                      <div
                        className="d-flex"
                        onClick={() => {
                          //   setShowQrCodeModal(true);
                        }}
                      >
                        <img src={barcodeIcon} alt="barcode" />
                        <span className="ms-2 text-primary">
                          {item.accountNo}
                        </span>
                        <span
                          style={{
                            color: '#828282',
                            marginLeft: 5,
                            cursor: 'pointer',
                          }}
                        >
                          <CopyToClipboard
                            text={item.accountNo}
                            onCopy={() => {
                              setCopied(true);
                              setTimeout(() => {
                                setCopied(false);
                              }, 1000);
                            }}
                          >
                            <span className="d-flex">
                              <MdOutlineContentCopy size={20} />
                              {/* <img src={Copy} alt="" className="me-2" /> */}
                              {/* <div>{copied === false ? '' : 'Copied'}</div> */}
                            </span>
                          </CopyToClipboard>
                        </span>
                        <span className="mx-3">{item.description}</span>
                      </div>
                      <div className="mt-2">
                        <span className="me-2" style={{ color: '#828282' }}>
                          Available balance {i + 1}/{walletList.length}
                        </span>
                        <img src={questionIcon} alt="barcode" />
                      </div>
                      <h6 style={{ color: '#828282' }} className="text-start">
                        NGN{' '}
                        {moneyFomatter(Number(item?.clr_bal_amt).toFixed(2))}
                      </h6>
                      {/* <p style={{ margin: 0, padding: 0, color: '#82828f' }}>
                        {item?.walletDefault ? 'Default Account' : ''}
                      </p> */}
                      <div className="mt-0">
                        <span className="me-2" style={{ color: '#828282' }}>
                          Total balance
                        </span>
                        <img src={questionIcon} alt="barcode" />
                      </div>
                      <h6
                        style={{ color: '#828282', marginBottom: 10 }}
                        className="text-start"
                      >
                        NGN{' '}
                        {moneyFomatter(
                          (
                            Number(item?.un_clr_bal_amt) +
                            Number(item?.blockAmount)
                          ).toFixed(2)
                        )}
                      </h6>
                    </CardDetails>
                    <div style={{ textAlign: 'right', marginRight: 20 }}>
                      {item?.walletDefault ? (
                        <VerifiedUserOutlined
                          style={{ color: 'green', marginTop: 20 }}
                        />
                      ) : item?.del_flg ? (
                        <BlockRounded style={{ color: 'red', marginTop: 20 }} />
                      ) : (
                        ''
                      )}
                    </div>
                    {
                      // <CheckBox status={walletNo === i} />
                    }
                  </div>
                ))
              ) : (
                <>
                  {' '}
                  <div>
                    <Skeleton height={150} width={550} />
                  </div>
                  <div>
                    <Skeleton height={150} width={550} />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* </Carousel> */}
        </div>
      </div>
      {/* <div className="mb-5 mt-5">
        <select
          style={{ width: '40%', margin: 5, border: 3 }}
          onChange={(e) => updateCurrentSlide(e.target.value)}
          className="form-select border"
        >
          <option value={null}>Select Account Number</option>
          {walletList?.map((e, i) => (
            <option key={i} value={i}>
              {`${e?.description} - ${e?.accountNo} - NGN ${moneyFomatter(
                Number(e?.clr_bal_amt).toFixed(2)
              )}`}
            </option>
          ))}
        </select>
      </div> */}
    </>
  );
}

// TransactionContent.propTypes = {
//   setShowFund: PropTypes.func.isRequired,
// };

const CheckBox = ({ status }) => {
  return (
    <div style={{ color: '#828282' }} className="last_one">
      Click to view transactions{' '}
      <span style={{ marginRight: 30, marginLeft: 10 }}>
        {status ? (
          <BsCheckCircle color="#ff6700" size={35} />
        ) : (
          <MdRadioButtonUnchecked size={40} />
        )}
      </span>
    </div>
  );
};

export const NONWayaBox = ({ data, n }) => (
  <div className="non_waya_card">
    <div className="category">{data.name}</div>
    <div className="amount">
      {n && 'N'}
      {data.prize ? moneyFomatter(data.prize) : 0.0}
    </div>
  </div>
);

export const NonWayaBoxContainer = ({ data, n }) => {
  return (
    <div className="non_waya_container">
      {data.map((el) => (
        <NONWayaBox key={el.name} data={el} n={n} />
      ))}
    </div>
  );
};
export const Tabs = ({ data, tab, setTab, rounded }) => {
  return (
    <div className="transaction_tabs">
      {data.map((el, i) => (
        <div
          key={el}
          className={`item ${
            i + 1 === tab && `active ${rounded && 'rounded'}`
          }`}
          onClick={() => setTab(i + 1)}
        >
          {el}
        </div>
      ))}
    </div>
  );
};
