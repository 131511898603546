import React from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Paper } from '@material-ui/core';
import tableIcons from './icons';
import './table.css';

import { Row } from '../containers/containers';
import RightIconInput from '../inputRightIcon/inputs';

export default function NotificationTable(props) {
  const { options, columns, data, title, setDate, date, filter } = props;
  const { RangePicker } = DatePicker;

  // const handleChange = () => [];
  const dateFormat = 'YYYY/MM/DD';

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: function cont(prop) {
            return <Paper {...prop} elevation={0} />;
          },
          Toolbar: function toolb() {
            return (
              <Row width="100%" style={{ marginTop: 50 }}>
                <RightIconInput />
                <Row
                  width="100%"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    height: '100%',
                  }}
                >
                  <RangePicker
                    format={dateFormat}
                    style={{ width: '100%', height: '100%' }}
                    onChange={(e) => {
                      const userId = localStorage.getItem('userId');
                      const dd = e.map((el) => el.toISOString());
                      filter(userId, dd[0], dd[1]);
                      // console.log('format', dd);
                      setDate(dd);
                    }}
                    value={
                      date === ''
                        ? null
                        : [
                            moment(date[0], dateFormat),
                            moment(date[1], dateFormat),
                          ]
                    }
                  />
                </Row>
              </Row>
            );
          },
        }}
        icons={tableIcons}
        options={
          {
            exportButton: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: '#FF6700',
              color: '#FFFFFF',
              whiteSpace: 'nowrap',
              zIndex: 0,
            },
          } || options
        }
        columns={columns}
        data={data || []}
        title={title || ''}
      />
    </div>
  );
}
