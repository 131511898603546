import React, { useState } from 'react';
import moment from 'moment';
import drLogo from '../../../assets/image/debit-transaction-type-icon.svg';
import crLogo from '../../../assets/image/credit-transaction-type-icon.svg';
import cpIco from '../../../assets/image/copy-icon.svg';
import TransReceipt from '../../shared/Modals/Receipt/TransReceipt';
import { Row } from 'antd';
import moneyFomatter from '../../../utils/moneyFormatter';

export default function HistoryTab(props) {
  const { historyData, noBorder } = props;
  const [showReceipt, setShowReceipt] = useState(false);
  const [receiptInfo, setReceiptInfo] = useState({});

  // const sortDate = (list) => {
  //   const selectedDates = [];
  //   if (list.length) {
  //     list.map((item) => {
  //       if (selectedDates.find(item.createdAt)) {
  //         selectedDates.push(item.createdAt);
  //       }
  //     });
  //   }
  // };
  const maskingString = (str, start, end) => {
    if (
      !str ||
      start < 0 ||
      start >= str.length ||
      end < 0 ||
      end > str.length ||
      start >= end
    ) {
      return str;
    }
    const maskLength = end - start;
    const maskedStr =
      str.substring(0, start) + '*'.repeat(maskLength) + str.substring(end);
    return maskedStr;
  };
  return (
    <div className="middle mx-auto" style={{ width: '80%', fontSize: '10px' }}>
      <table className="table table-borderless">
        <tbody>
          {historyData.length ? (
            historyData.map((item) => (
              <tr
                key={Math.random()}
                onClick={() => {
                  console.log('item', item);
                  setReceiptInfo({
                    'Transaction Status': item?.posted_flg
                      ? 'Successful'
                      : 'Failed',
                    'Transaction Amount': `${moneyFomatter(item?.tranAmount)}${
                      item?.tranCrncyCode
                    }`,
                    Date: moment(item?.createdAt).format('LLL'),
                    'Reference Id': item?.paymentReference,
                    'Transaction Type':
                      item?.partTranType === 'C' ? 'Credit' : 'Debit',
                    'Transaction Category': item?.tranCategory,
                    'Sender Name': item?.senderName || '----',
                    'Sender Account Number': maskingString(item?.acctNum, 3, 7),
                    'Receiver Name': item?.receiverName || '----',
                    // 'Receiver Account Number': item?.receiverAcctNum,
                    Narration: item?.tranNarrate,
                  });
                  setShowReceipt(true);
                }}
              >
                <td span="4">
                  <Row>
                    <img
                      src={item?.partTranType === 'C' ? crLogo : drLogo}
                      alt=""
                    />
                    <div>
                      <h6>{item?.tranCategory}</h6>
                      <small>{moment(item?.createdAt).format('LL LT')}</small>
                    </div>
                  </Row>
                </td>
                <td>
                  <h5
                    className={
                      item?.partTranType === 'C'
                        ? 'text-success me-4'
                        : 'text-danger me-4'
                    }
                  >
                    {item?.partTranType === 'C'
                      ? `+ ${item?.tranCrncyCode}${moneyFomatter(
                          item?.tranAmount
                        )}`
                      : `- ${item?.tranCrncyCode}${moneyFomatter(
                          item?.tranAmount
                        )}`}
                  </h5>
                  REF: {item?.paymentReference}
                  <img src={cpIco} alt="arrow" />
                </td>
              </tr>
            ))
          ) : (
            <h4 className="text-center fs-6 fs mt-3">No transaction</h4>
          )}
        </tbody>
      </table>
      {showReceipt ? (
        <TransReceipt
          showModal={showReceipt}
          hideModal={setShowReceipt}
          data={receiptInfo}
          id="receipt-wrapper"
          title="Transaction Receipt"
          maskingString={maskingString}
        />
      ) : (
        ''
      )}
    </div>
  );
}
