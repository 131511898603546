import React, { useState, useContext } from 'react';
import swal from 'sweetalert';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ProgressBar from './ProgressBar';
// import DonationPrivacy from './DonationPrivacy';
import DonationTarget from './DonationTarget';
import DonationInfo from './DonationInfo';
import PublishDonation from './PublishDonation';
import PageCount from './Pagecount';
import StepButton from './StepButton';
import { headers, donationButtonTitle } from './data';
import './index.scss';
import { WayagramContext } from '../../../../../store/context/WayagramContext';
import { hideLoader, showLoader } from '../../../../../utils/loader';
import { customBaseUrl, httpPost } from '../../../../../action/http';
import TermsAndCondition from '../../Terms/TermsAndCondition';

const DonationModal = (props) => {
  const { wayagramProfile: profile } = useContext(WayagramContext);
  const [values, setValues] = useState({
    step: 0,
    totalSteps: 3,
    title: '',
    organizerName: '',
    description: '',
    tags: '',
    estimatedAmount: '',
    displayTotalDonation: 'true',
    donationImage: '',
  });
  const { showModal, hideModal } = props;

  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  const handleFormInput = (input, name) => {
    setValues({ ...values, [name]: input });
  };

  const handleSubmit = async () => {
    showLoader();

    if (values.title === '') {
      swal('error', 'title Name cannot be empty');
      hideLoader();
      return;
    }

    if (values.description === '') {
      swal('error', 'description cannot be empty');
      hideLoader();
      return;
    }

    if (values.organizerName === '') {
      swal('error', 'Event start time and end time cant be thesame');
      hideLoader();
      return;
    }
    if (values.estimatedAmount === ' ') {
      swal('error', 'Event start time and end time cant be thesame');
      hideLoader();
      return;
    }

    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    values.donationImage &&
      newForm.append('donationImage', values.donationImage);
    newForm.append('profile_id', profile.id);
    newForm.append('title', values.title);
    newForm.append('description', values.description);
    newForm.append(
      'displayTotalDonation',
      values.displayTotalDonation || false
    );
    newForm.append('estimatedAmount', values.estimatedAmount);
    // newForm.append('organizerName', values.organizerName);

    // values.tag && newForm.append('tag', values.tag);

    const res = await httpPost(
      `/all-posts/create-donation`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideLoader();
        hideModal(false);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message, 'error');
    }
  };

  const submitDonation = () => {
    console.log(values);
    handleSubmit();
    hideModal();
    console.log('donation submitted....');
  };

  return (
    <Modal
      isOpen={showModal}
      id="donation-modal"
      toggle={() => hideModal(false)}
      centered
      size="lg"
    >
      <ModalHeader className="text-left" toggle={() => hideModal(false)}>
        <div className="interest-header normal-header">
          <h3 className="interest-p1">{headers[values.step].header}</h3>
          <div className="description-header mt-3">
            <p className="description-text">
              {headers[values.step].description}
            </p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <PageSwitch
          step={values.step}
          totalSteps={values.totalSteps}
          handleSteps={handleSteps}
          stepBackward={stepBackward}
          submitDonation={submitDonation}
          handleFormInput={handleFormInput}
          values={values}
        />
      </ModalBody>
    </Modal>
  );
};

function PageSwitch(props) {
  const {
    step,
    totalSteps,
    handleSteps,
    submitDonation,
    handleFormInput,
    stepBackward,
    values,
  } = props;
  const [showTermsModal, setShowTermsModal] = useState(false);

  const onAction = [handleSteps, handleSteps, handleSteps, submitDonation];

  return (
    <div className="donation-container pb-5 mb-3">
      <ProgressBar totalSteps={totalSteps} step={step} />
      <div className="info-container">
        {step === 0 && (
          <DonationInfo handleFormInput={handleFormInput} values={values} />
        )}
        {step === 1 && (
          <DonationTarget handleFormInput={handleFormInput} values={values} />
        )}

        {step === 2 && (
          <PublishDonation handleFormInput={handleFormInput} values={values} />
        )}
        <PageCount
          currentPage={step + 1}
          totalPage={totalSteps}
          stepBackward={stepBackward}
        />
        {step < 2 && (
          <div className="termsnconditionText my-3">
            By sending, you agree with our{' '}
            <u onClick={() => setShowTermsModal(true)}>terms and conditions</u>{' '}
            of donation creation
          </div>
        )}
      </div>

      <StepButton
        className="mb-3"
        title={donationButtonTitle[step]}
        onClick={onAction[step]}
      />
      {showTermsModal ? (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default DonationModal;
