import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
import './App.scss';
Bugsnag.start({
  apiKey: 'e36dc0fc301b6bc98687a965fd5f7862',
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
ReactDOM.render(
  // root recoil
  <ErrorBoundary>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </ErrorBoundary>,
  document.getElementById('root')
);
