import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './update.scss';
import Button from '../../shared/Button';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import { useEffect } from 'react';

const UpdateLimit = (props) => {
  const { showModal, hideModal, mode } = props;
  const [otp, setOtp] = useState('');
  const [volume, setVolume] = useState('');
  const { profile } = useContext(ProfileContext);
  /*    const data =
    mode === 'profile'
      ? {
          id: profile.userId,
          phone: profile.phoneNumber,
          firstName: profile.firstName,
          lastName: profile.surname,
          email: profile.email,
        }
      : {
          referralCode,
        }; */
  const getLimit = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/setup?id=${profile?.userId}`
    );
    hideLoader();
    if (res.status === true) {
      setVolume(res?.data?.transactionLimit);
    } else {
      console.log(res);
    }
  };
  const updateLimit = async () => {
    const data = {
      otp,
      transactionLimit: volume,
      userId: profile?.userId,
    };
    showLoader();
    const res = await httpPost(
      `/api/v1/user/updateLimit`,
      data,
      customBaseUrl.authUrl
    );
    if (res.status === true) {
      hideLoader();
      swal('Done', res?.message);
      hideModal(false);
    } else {
      swal('Oops!', res?.message);
      hideLoader();
    }
    return [];
  };

  useEffect(() => {
    getLimit();
  }, []);
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader>
        <h5 className="modal-header-data"> Update Transaction Limit </h5>
      </ModalHeader>
      <ModalBody>
        <div
          id="qr-body"
          style={mode === 'profile' ? { marginBottom: '20px' } : {}}
        >
          <div>
            <h5>Daily Transaction Volume</h5>
            <div className="inputbox-with-one-input">
              <input
                value={volume}
                placeholder="100000"
                type="text"
                onChange={(e) => setVolume(e?.target.value)}
              />
            </div>
          </div>
          <div>
            <h5>OTP</h5>
            <div className="inputbox-with-one-input">
              <input
                value={otp}
                type="text"
                onChange={(e) => setOtp(e?.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="submit-modal-btn-wrap">
          <Button onClick={updateLimit} type="button" content="Save changes" />
        </div>
      </ModalBody>
    </Modal>
  );
};

UpdateLimit.defaultProps = {
  mode: 'profile',
  showModal: false,
};

UpdateLimit.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  mode: PropTypes.string,
};

export default UpdateLimit;
