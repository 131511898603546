import React from 'react';
import googlePlay from '../../../assets/image/GooglePlay-logo.svg';
import appleStore from '../../../assets/image/Apple-logo.svg';

const DownloadApp = ({ styles }) => {
  return (
    <div className="items-right-bottom">
      <div className={styles}>
        <h4>Download our App</h4>
        <p>
          Download our mobile application for better experience and to receive
          custom notifications in real time.
        </p>
        <div className="app-store-btn-2">
          <a href="https://apps.apple.com/app/wayabank/id1658552439">
            <img src={appleStore} alt="" className="ios-store-2" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=ng.wayabank">
            <img src={googlePlay} alt="" className="google-store-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
