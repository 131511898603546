import dotenv from 'dotenv';

dotenv.config();

const {
  REACT_APP_IS_LIVE,
  REACT_APP_WEBSITE_APP,
  REACT_APP_SOCIAL_APP,
  REACT_APP_BASE_URL,
  REACT_APP_LOGIN_PAGE,
  REACT_APP_CORPORATE_APP,
  REACT_APP_PERSONAL_APP,
} = process.env;

const envConfig = {
  isLive: REACT_APP_IS_LIVE === 'live' || false,
  websiteAppUrl: REACT_APP_WEBSITE_APP || 'https://wayabank.ng',
  socialAppUrl: REACT_APP_SOCIAL_APP,
  baseUrl: REACT_APP_BASE_URL || 'https://services.wayabank.ng',
  login: REACT_APP_LOGIN_PAGE || 'https://wayabank.ng/login',
  corporateAppUrl: REACT_APP_CORPORATE_APP || '',
  personalAppUrl: REACT_APP_PERSONAL_APP || '',
};

export default envConfig;
