import React, { useContext, useState } from 'react';
import QRCode from 'react-qr-code';
import swal from 'sweetalert';
import { ModalFooter, Button, ModalBody } from 'reactstrap';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { customBaseUrl, httpPostBlob } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { WalletContext } from '../../../../store/context/WalletContext';
import moneyFomatter from '../../../../utils/moneyFormatter';

export default function ScanToReceive(props) {
  const { showQrCode, data } = props;
  const { profile } = useContext(ProfileContext);
  const { walletList } = useContext(WalletContext);
  //  const [amount, setAmount] = useState('');
  const [postData, setPostData] = useState({ qrType: 'custom' });
  const [imgUrl, setFileUrl] = useState('');
  const generateQr = async () => {
    if (!postData?.accountNo) return swal('Error!', 'Select account');
    if (postData?.qrType === 'dynamic' && !postData?.amount)
      return swal('Error!', 'amount field cannot be empty');

    showLoader();
    try {
      let res;
      if (postData?.qrType === 'dynamic') {
        res = await httpPostBlob(
          `/api/v1/generateCustomQrCode?accountNumber=${postData?.accountNo}&amount=${postData?.amount}&profileId=${profile?.id}&qrImageHeight=200&qrImageWidth=200&userId=${profile?.userId}`,
          {},
          customBaseUrl.QrUrl
        );
      } else {
        res = await httpPostBlob(
          `/api/v1/generateCustomQrCode?accountNumber=${postData?.accountNo}&profileId=${profile?.id}&qrImageHeight=200&qrImageWidth=200&userId=${profile?.userId}`,
          {},
          customBaseUrl.QrUrl
        );
      }
      hideLoader();
      if (res?.status === 400) {
        swal('Error', res?.message ?? 'Error!');
      } else {
        const file = new Blob([res]);
        //  if (file) return console.log({ file });
        // Build a URL from the file
        const fileURL = window.URL.createObjectURL(file);
        setFileUrl(fileURL);
        // if (fileURL) return console.log({ fileURL });
        // const alink = document.createElement('a');
        // alink.href = fileURL;
        // alink.download = `QRCode.PNG`;
        // alink.click();
        // setAmount('');
        // swal('Done', 'QR code generated');
        //  hideModal(false);
      }
    } catch (err) {
      console.log('error', err);
      swal('Oops!', err, 'error');
    }
    /* if (res?.status) {
      hideLoader();
      swal('Oops!', res.message, 'error');
    } else {
      hideLoader();
      swal('Oops!', res?.message, 'error');
    } */
  };
  const downloadQr = async () => {
    //  if (!o) return swal('Error!', 'Select account');
    //  showLoader();
    const alink = document.createElement('a');
    alink.href = imgUrl;
    alink.download = `QRCode_${postData?.qrType}.PNG`;
    alink.click();
  };
  return (
    <>
      <ModalBody>
        <div className="text-center mt-3 mb-3">
          <form className="mx-auto mt-3 px-3 mx-auto">
            <div
              className="text-center bg-white p-3"
              id="qr-body"
              style={!showQrCode ? { display: 'none' } : {}}
            >
              <QRCode value={`${JSON.stringify(data)}`} />

              <p className="mt-3">
                Name: {`${data.firstName} ${data.surname}`}
              </p>
              <p className="mt-3">
                Amount: {`${Number(data.amount).toFixed(2)}`}
              </p>
              <p className="mt-3">Reason: {data.reason}</p>
            </div>
            <div className="inputbox-with-one-input">
              <select
                name="filter"
                className="input-select"
                defaultValue={postData?.qrType}
                onChange={(e) => {
                  setFileUrl('');
                  setPostData({
                    ...postData,
                    amount: '',
                    qrType: e?.target.value,
                  });
                }}
              >
                <option value="Select" selected disabled>
                  Select QR Type
                </option>
                <option value="custom">Custom</option>
                <option value="dynamic">Dynamic</option>
              </select>
            </div>
            {postData?.qrType === 'dynamic' ? (
              <div className="inputbox-with-one-input text-start">
                <input
                  className=""
                  value={postData?.amount}
                  type="number"
                  min={0}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      amount: e.target.value,
                    })
                  }
                />
                <label>Custom amount</label>
              </div>
            ) : (
              ''
            )}
            <div className="inputbox-with-one-input">
              <select
                name="filter"
                className="input-select"
                defaultValue={postData?.accountNo}
                onChange={(e) => {
                  setFileUrl('');
                  const [accountNo] = e.target.value.split(',');
                  setPostData({ ...postData, accountNo });
                }}
              >
                <option value="Select" selected disabled>
                  Select account
                </option>
                {walletList?.length
                  ? walletList?.map((item) => (
                      <option
                        value={`${item?.accountNo},${item?.clr_bal_amt}`}
                        key={item?.accountNo}
                      >
                        {`${item?.description} - ${item?.accountNo} (${
                          item?.acct_crncy_code
                        } ${moneyFomatter(
                          Number(item?.clr_bal_amt).toFixed(2)
                        )})`}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
            {imgUrl ? (
              <div className="mx-auto text-center">
                <img src={imgUrl} width={200} height={200} />
              </div>
            ) : (
              ''
            )}
            <div style={showQrCode ? { display: 'none' } : {}}>
              {/* <div className="inputbox-with-one-input">
                <input
                  placeholder="Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <span className="input-detail">
                  How much do you want to Receive?
                </span>
              </div> */}
              {/* <div className="inputbox-with-one-input">
                <input
                  placeholder="Description"
                  type="text"
                  value={data.reason}
                  onChange={(e) => {
                    setData({ ...data, reason: e.target.value });
                  }}
                />
                <span className="input-detail">Short description</span>
              </div> */}
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          /*  onClick={() => {
            console.log('@@', data);
            if (!showQrCode) {
              setShowQrCode(true);
              return;
            }
            setShowQrCode(false);
            setData({ ...data, amount: '' });
          }}  */
          onClick={imgUrl ? downloadQr : generateQr}
        >
          {imgUrl ? 'Download' : 'Generate Qr code'}
        </Button>
      </ModalFooter>
    </>
  );
}
