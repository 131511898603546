import React from 'react';
import OtpInput from 'react-otp-input';
import { ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../Button';
import secureIcon from '../../../../assets/secure.svg';
import moneyFomatter from '../../../../utils/moneyFormatter';

const WithdrawalDetails = (props) => {
  const {
    data,
    // setShowPin,
    setMode,
    otp,
    setOtp,
    loading,
    separator,
    handleSubmit,
    buttonLabel,
    saveBen,
    setSaveBen,
    charges,
  } = props;

  return (
    <div>
      <ModalBody>
        <div className="pin-input-container">
          <div
            onClick={() => {
              setMode('savedBeneficiary');
            }}
            style={{
              alignSelf: 'flex-end',
              marginRight: '40px',
              marginBottom: '20px',
              marginTop: '-28px',
            }}
          >
            x
          </div>

          <div className="pin-input-header">Input your 4 digit PIN</div>
          <div className="pin-input-description">
            Please input your 4 digit PIN to transfer fund
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Receipient</div>
            <div className="transaction-detail-right text-end">
              {data.crAccountName}
            </div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Amount</div>
            <div className="transaction-detail-right">
              {moneyFomatter(data.amount)}
            </div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Beneficiary Bank:</div>
            <div className="transaction-detail-right">{data.crAccount}</div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">From</div>
            <div className="transaction-detail-right">
              {data.walletAccountNo}
            </div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Transaction fee:</div>
            <div className="transaction-detail-right">N{charges}</div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Total Amount:</div>
            <div className="transaction-detail-right">
              N{moneyFomatter(+data.amount.replace(',', '') + charges)}
            </div>
          </div>
          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            separator={separator}
            containerStyle={{ justifyContent: 'center' }}
            inputStyle="otp-input otp-bottom"
            isInputSecure
            isInputNum
          />
        </div>

        <div className="d-flex w-80 mx-auto mt-5">
          <div>Save as beneficiary</div>
          <div className="form-check form-switch ml-auto">
            <input
              checked={saveBen}
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              onChange={(e) => {
                if (e.target.checked) {
                  setSaveBen(true);
                } else {
                  setSaveBen(false);
                }
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 mt-5 text-center">
          <Button
            type="button"
            className="btn btn-primary btn-lg w-50"
            loading={loading}
            disabled={loading || false}
            onClick={handleSubmit}
            content={buttonLabel}
          />
        </div>
      </ModalFooter>
      <div
        className="flex_center"
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <img
          src={secureIcon}
          alt=""
          style={{ marginBotton: 5, marginRight: 5 }}
        />
        <div>
          Secured by <span style={{ color: '#FF6700' }}>WayaBank</span>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalDetails;
