import React, { useState, useEffect, useContext } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';
import CurrencyInput from 'react-currency-input';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { CardContext } from '../../../../store/context/CardContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import NumberFormat from 'react-number-format';

export default function NewBeneficiary(props) {
  const { profile } = useContext(ProfileContext);
  const { data, setData, setMode, walletList, myAccountList, getCharges } =
    props;
  const { bankList } = useContext(CardContext);
  const [type, setType] = useState('new');
  const [balance, setBalance] = useState(null);
  const [account, setAccount] = useState();

  useEffect(() => {
    // const { otherDetails } = profile;
    //  console.log('details', otherDetails);
    getCharges(account, data.amount);
  }, [data, account]);

  const resolveBank = async (value) => {
    showLoader();
    if (value === '') {
      swal('Oops!', 'Account Number is required', 'error');
      return;
    }
    const postData = {
      accountNumber: value,
      bankCode: data.bankCode,
      bankName: data.bankName,
    };
    const res = await httpPost(
      `/bankAccount/resolveAccountNumber/WD`,
      postData,
      customBaseUrl.cardUrl
    );
    if (res.status) {
      hideLoader();
      if (res.data === '') {
        swal('invalid account number', 'error');
      }
      setData({
        ...data,
        crAccountName: res.data,
        crAccount: value,
      });
    } else {
      swal('Oops!', res.message, 'error');
    }
    hideLoader();
  };

  useEffect(() => {
    if (myAccountList?.length === 1) {
      setData({
        ...data,
        crAccount: myAccountList[0].accountNumber,
        crAccountName: myAccountList[0].accountName,
        bankCode: myAccountList[0].bankCode,
        bankName: myAccountList[0].bankName,
      });
    }
  }, []);
  return (
    <>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              onChange={(e) => {
                setType(e.target.value);
                setData({});
              }}
            >
              <option value="" disabled>
                Select Withdrawal type
              </option>
              <option value="saved" key="saved" selected>
                Withdraw to Saved Bank
              </option>
              <option value="new" key="new">
                Withdraw to New Bank
              </option>
            </select>
            <span className="input-detail">Select where to withdraw to</span>
          </div>
          {type === 'saved' ? (
            <div>
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  value={`${data.crAccount},${data.crAccountName},${data.bankCode},${data.bankName}`}
                  onChange={(e) => {
                    console.log(e.target.value, 'acc');
                    const [accountNumber, accountName, bankCode, bankName] =
                      e.target.value.split(',');
                    setData({
                      ...data,
                      crAccount: accountNumber,
                      crAccountName: accountName,
                      bankCode,
                      bankName,
                    });
                  }}
                >
                  <option value="Select" selected>
                    Select Bank Account
                  </option>
                  {myAccountList?.length
                    ? myAccountList.map((item) => (
                        <option
                          value={`${item.accountNumber},${item.accountName},${item.bankCode},${item.bankName}`}
                          key={item.accountNumber}
                        >
                          {`${item.accountName}-${item.bankName}`}
                        </option>
                      ))
                    : ''}
                </select>
                <span className="input-detail">
                  Select Account you want to withdraw to //{' '}
                </span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Bank Name"
                  type="text"
                  value={data.bankName}
                />

                <span className="input-detail">Account Name of reciepient</span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Account Number"
                  type="text"
                  value={data.crAccount}
                />
                <span className="input-detail">
                  Account Number of reciepient
                </span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Add a Note"
                  type="text"
                  required
                  value={data.narration}
                  onChange={(e) => {
                    setData({ ...data, narration: e.target.value });
                  }}
                />

                <span className="input-detail">Narration</span>
              </div>
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  value={data.walletAccount}
                  onChange={(e) => {
                    const [accountNo, amount, acctName] =
                      e.target.value.split(',');
                    setAccount(accountNo);
                    console.log('actname', acctName);
                    setData({
                      ...data,
                      walletAccountNo: accountNo,
                      debitAccountName:
                        profile?.otherDetails?.organisationName ?? acctName,
                      balance: amount,
                    });
                    setBalance(amount);
                  }}
                >
                  <option
                    value="Select"
                    key="select"
                    selected="Select"
                    disabled
                  >
                    Select Account
                  </option>
                  {walletList?.length
                    ? walletList.map(
                        (item) =>
                          item.savingsProductName !== 'COMMISSION' && (
                            <option
                              value={`${item.accountNo},${item?.clr_bal_amt},${item?.acct_name}`}
                              key={item.accountNo}
                            >
                              {`${item?.description}
                                - ${item?.accountNo}
                                (${item.acct_crncy_code} ${moneyFomatter(
                                Number(item?.clr_bal_amt).toFixed(2)
                              )})`}
                            </option>
                          )
                      )
                    : ''}
                </select>
                <span className="input-detail">
                  Your Balance is N{balance || 0}.00
                </span>
              </div>
              <div className="inputbox-with-one-input">
                {/* <input
                  placeholder="N0.0"
                  min="0"
                  type="number"
                  value={+data.amount}
                  onChange={(e) => setData({ ...data, amount: e.target.value })}
                /> */}
                {/* <CurrencyInput
                  id="input-amount"
                  name="input-amount"
                  placeholder="Amount"
                  defaultValue={0.0}
                  decimalsLimit={2}
                  onValueChange={(value, name, values) =>
                    setData({ ...data, amount: values?.value })
                  }
                /> */}
                <NumberFormat
                  placeholder="Amount"
                  thousandSeparator
                  decimalScale="2"
                  fixedDecimalScale
                  onChange={(e) =>
                    setData({ ...data, amount: e?.target.value })
                  }
                />
                <span className="input-detail">Top-up Amount</span>
              </div>
            </div>
          ) : (
            ''
          )}
          {type === 'new' ? (
            <div>
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  value={`${data.bankName},${data.bankCode}`}
                  onChange={(e) => {
                    const [bankName, bankCode] = e.target.value.split(',');
                    setData({
                      ...data,
                      bankCode,
                      bankName,
                    });
                  }}
                >
                  <option value="Select" key="select" selected>
                    Select Bank
                  </option>
                  {bankList?.length
                    ? bankList?.map((item) => (
                        <option
                          value={`${item.name},${item.code}`}
                          key={item.code}
                        >
                          {item.name}
                        </option>
                      ))
                    : ''}
                </select>
                <span className="input-detail">Select Reciepient Bank</span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Account Number"
                  type="text"
                  maxLength={10}
                  defaultValue={data.crAccount}
                  onChange={(e) => {
                    if (e.target.value?.length > 9) {
                      resolveBank(e.target.value);
                    }
                  }}
                />
                <span className="input-detail">
                  Account Number of Reciepient
                </span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Account Name"
                  type="text"
                  value={data.crAccountName}
                  disabled
                />
                <span className="input-detail">Account Name of Reciepient</span>
              </div>

              <div className="inputbox-with-one-input">
                <input
                  placeholder="Add a Note"
                  type="text"
                  required
                  value={data.narration}
                  onChange={(e) => {
                    setData({ ...data, narration: e.target.value });
                  }}
                />
                <span className="input-detail">Narration</span>
              </div>
              <div className="inputbox-with-one-input">
                {/* <input placeholder="Card to Debit" type="text" /> */}
                <select
                  name="filter"
                  className=""
                  value={data.walletAccountId}
                  onChange={(e) => {
                    const [accountNo, amount, acctName] =
                      e.target.value.split(',');
                    setAccount(accountNo);
                    console.log('g111', acctName);
                    setData({
                      ...data,
                      walletAccountNo: accountNo,
                      debitAccountName:
                        profile?.otherDetails?.organisationName ?? acctName,
                    });
                    setBalance(amount);
                  }}
                >
                  <option value="Select" selected disabled>
                    Select Account
                  </option>
                  {walletList?.length
                    ? walletList.map(
                        (item) =>
                          item.savingsProductName !== 'COMMISSION' && (
                            <option
                              value={`${item.accountNo},${item?.clr_bal_amt},${item?.acct_name}`}
                              key={item.accountNo}
                            >
                              {`${item?.description}
                                - ${item?.accountNo}
                                (${item.acct_crncy_code} ${moneyFomatter(
                                Number(item?.clr_bal_amt).toFixed(2)
                              )})`}
                            </option>
                          )
                      )
                    : ''}
                </select>
                <span className="input-detail">
                  Your Balance is N{balance || 0}.00
                </span>
              </div>
              <div className="inputbox-with-one-input">
                {/* <input
                  placeholder="N0.0"
                  min="0"
                  type="number"
                  value={+data.amount}
                  onChange={(e) => setData({ ...data, amount: e.target.value })}
                /> */}
                {/* <CurrencyInput
                  id="input-amount"
                  name="input-amount"
                  placeholder="Amount"
                  //  decimalsLimit={2}
                  value={data.amount}
                  onChangeEvent={(e) =>
                    setData({ ...data, amount: e?.target.value })
                  }
                /> */}
                <NumberFormat
                  placeholder="Amount"
                  thousandSeparator
                  decimalScale="2"
                  fixedDecimalScale
                  onChange={(e) =>
                    setData({ ...data, amount: e?.target.value })
                  }
                />
                <span className="input-detail">Withdraw Amount</span>
              </div>
            </div>
          ) : (
            ''
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          onClick={() => {
            if (!data.amount || data.amount === '') {
              swal('Oops!', 'Amount input field can not be empty', 'error');
              return;
            }
            if (!data.bankCode || data.bankCode === '') {
              swal('Oops!', 'Bank field can not be empty', 'error');
              return;
            }
            if (!data.crAccount || data.crAccount === '') {
              swal('Oops!', 'Account details not correct', 'error');
              return;
            }
            if (!data.crAccountName || data.crAccountName === '') {
              swal('Oops!', 'account name can not be empty', 'error');
              return;
            }
            if (!data.narration || data.narration === '') {
              swal('Oops!', 'narration input field can not be empty', 'error');
              return;
            }
            if (!data.walletAccountNo || data.walletAccountNo === '') {
              swal('Oops!', 'wallet field can not be empty', 'error');
              return;
            }
            setMode('withdrawalDetails');
          }}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}

// <form className="mx-auto mt-3">
//   <div className="inputbox-with-one-input">
//     {/* <input placeholder="Card to Debit" type="text" /> */}
//     <select
//       name="filter"
//       className=""
//       value={data.walletAccount}
//       onChange={(e) => {
//         const [accountNumber, accountName, bankCode, bankName] =
//           e.target.value.split(',');
//         setData({
//           ...data,
//           crAccount: accountNumber,
//           crAccountName: accountName,
//           bankCode,
//           bankName,
//         });
//       }}
//     >
//       <option value="Select" selected disabled>
//         Select Beneficiaries
//       </option>
//       {myAccountList.length
//         ? myAccountList.map((item) => (
//             <option
//               value={`${item.accountNumber},${item.accountName},${item.bankCode},${item.bankName}`}
//               key={item.accountNo}
//             >
//               {`${item.accountName}-${item.bankName}`}
//             </option>
//           ))
//         : ''}
//     </select>
//     <span className="input-detail">
//       Select Bank you want to withdraw to
//     </span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input placeholder="Bank Name" type="text" value={data.bankName} />
//     <span className="input-detail">Type account number</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Account Number"
//       type="text"
//       value={data.crAccount}
//     />
//     <span className="input-detail">Account Number of Recipent</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Account Name"
//       type="text"
//       value={data.crAccountName}
//     />
//     <span className="input-detail">Account Name of Recipent</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Amount"
//       type="text"
//       value={data.amount}
//       onChange={(e) => setData({ ...data, amount: e.target.value })}
//     />
//     <span className="input-detail">How much do you want to Send?</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     {/* <input placeholder="Card to Debit" type="text" /> */}
//     <select
//       name="filter"
//       className=""
//       value={data.walletAccountId}
//       onChange={(e) => {
//         const [accountNo, amount] = e.target.value.split(',');
//         setData({
//           ...data,
//           walletAccountNo: accountNo,
//         });
//         setBalance(amount);
//       }}
//     >
//       <option value="Select" selected disabled>
//         Select Wallet
//       </option>
//       {walletList.length
//         ? walletList.map(
//             (item) =>
//               item.savingsProductName !== 'COMMISSION' && (
//                 <option
//                   value={`${item.accountNo},${item?.clr_bal_amt}`}
//                   key={item.accountNo}
//                 >
//                   {`${item?.acct_crncy_code}-${item?.accountNo}`}
//                 </option>
//               )
//           )
//         : ''}
//     </select>
//     <span className="input-detail">Your Balance is N{balance || 0}.00</span>
//   </div>
//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Add a Note"
//       type="text"
//       value={data.narration}
//       onChange={(e) => {
//         setData({ ...data, narration: e.target.value });
//       }}
//     />
//     <span className="input-detail">Optional?</span>
//   </div>
// </form>;
