import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import swal from 'sweetalert';
import validator from 'validator';
import { notification } from 'antd';
import { parsePhoneNumber } from 'react-phone-number-input';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import '../FundWallet/index.scss';
import Container from '../../Container';
import PayToEmail from './PayToEmail';
import PayToWayaId from './PayToWayaId';
import PayToPhone from './PayToPhone';
import PinDetails from '../Confirm/PinDetails';
import PayToScan from './PayToScan';
import { WalletContext } from '../../../../store/context/WalletContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
// import { PaymentContext } from '../../../../store/context/PaymentContext';
import Button from '../../Button';
import CommingSoonModal from '../comingSoon/CommingSoonModal';
import PayToAccount from './payToAccount';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { duplicateTransChecker } from '../../../../utils/helper';
import moneyFomatter from '../../../../utils/moneyFormatter';

const Index = (props) => {
  const [showPin, setShowPin] = useState(false);
  const { showModal, hideModal, id, fromLocation, fromData, scan } = props;
  const [mode, setMode] = useState(scan ? 'scan' : 'email');
  const [data, setData] = useState({});
  const [query, setQuery] = useState('');
  const [pin, setPin] = useState('');
  const [displayData, setDisplayData] = useState({});
  const { setReload, reload, walletList, defaultWallet } =
    useContext(WalletContext);
  const {
    profile: { userId, firstName, surname },
  } = useContext(ProfileContext);
  // const { charges } = useContext(PaymentContext);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState('wayaUser');
  const [commingSoon, setCommingSoon] = useState(false);
  const [charges, setCharges] = useState(0);
  const profileId = localStorage?.profileId;

  const getCharges = async (acctNo, amt) => {
    const res = await httpGet(
      `${
        customBaseUrl.temporalservice
      }/api/v1/wallet/transaction/get-user-transaction-fee/${acctNo}/${Number(
        amt?.replace(',', '')
      )}/WAYATRAN`
    );
    if (res) {
      setCharges(res);
    }
  };

  const handlePayToPhone = async () => {
    if (validator.isEmpty(data.phoneNumber)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }

    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      setLoading(false);
      return;
    }
    const postData = {
      accountNumber: false,
      amount: data?.amount.replace(',', ''),
      beneficiaryProfileId: data?.receiverProfileId,
      beneficiaryUserId: data?.receiverId,
      description: !data?.reason || data?.reason === '' ? null : data?.reason,
      senderAccountNumber: data.senderAccountNumber,
    };
    const duplicateData = {
      accountNo: data?.phoneNumber,
      amount: data?.amount.replace(',', ''),
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    localStorage.setItem('transaction', JSON.stringify(duplicateData));
    // const formattedPhone = `${
    //   parsePhoneNumber(data.phoneNumber).countryCallingCode
    // }${parsePhoneNumber(data.phoneNumber).nationalNumber}`;
    showLoader();
    const res = await httpPost(
      `/contact/account/service/send/money/to/phoneNumber`,
      postData,
      customBaseUrl.contactUrl,
      pin
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer successful`, 'success').then(() => {
        setData({});
        hideModal(false);
        setPin('');
        setVerifyLoading(false);
        setReload(!reload);
      });
    } else {
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handlePayToId = async () => {
    if (validator.isEmpty(data.userId)) {
      swal('Oops!', 'User cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      setLoading(false);
      return;
    }

    const postData = {
      accountNumber: false,
      amount: data.amount.replace(',', ''),
      beneficiaryProfileId: data?.receiverProfileId,
      beneficiaryUserId: data?.userId,
      senderAccountNumber: data.senderAccountNumber || defaultWallet?.accountNo,
      description: !data?.reason || data?.reason === '' ? null : data?.reason,
    };
    const duplicateData = {
      accountNo: data?.userId,
      amount: data?.amount.replace(',', ''),
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    localStorage.setItem('transaction', JSON.stringify(duplicateData));
    showLoader();
    const res = await httpPost(
      `/contact/account/service/send/money/to/userId/`,
      postData,
      customBaseUrl.contactUrl,
      pin
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer successful`, 'success').then(() => {
        setData({});
        hideModal(false);
        setVerifyLoading(false);
        setPin('');
        setReload(!reload);
      });
    } else {
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const getDetails = async (receiverAccount) => {
    //  if (!data?.receiverAccount) return swal('Account No can not be null');
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/fetchUserByAccountNo/${receiverAccount}`
    );
    showLoader();
    if (res?.status) {
      hideLoader();
      notification.success(res?.message);
      // setData({
      //   ...data,
      //   receiverAccount,
      //   receiverProfileId: res?.data?.profileId,
      //   receiverEmail: res?.data?.email,
      //   receiverPhoneNumber: res?.data?.phoneNumber,
      //   receiverName: res?.data?.accountName,
      //   receiverId: res?.data?.userId,
      // });
      const postData = {
        accountNumber: true,
        amount: data?.amount.replace(',', ''),
        beneficiaryProfileId: res?.data?.profileId,
        beneficiaryUserId: res?.data?.userId,
        description: !data?.reason || data?.reason === '' ? null : data?.reason,
        senderAccountNumber: data.senderAccountNumber,
      };
      handleScanWayaUser(postData, receiverAccount);
    } else {
      hideLoader();
      setData({
        ...data,
        receiverProfileId: '',
        receiverEmail: '',
        receiverPhoneNumber: '',
        receiverId: '',
        receiverName: '',
      });
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
    return res;
  };
  const handleScanWayaUser = async (postData, receiverAccount) => {
    // const postData = {
    //   accountNumber: true,
    //   amount: data?.amount,
    //   beneficiaryProfileId: data?.receiverProfileId,
    //   beneficiaryUserId: data?.receiverId,
    //   description: !data?.reason || data?.reason === '' ? null : data?.reason,
    //   senderAccountNumber: data.senderAccountNumber,
    // };
    const duplicateData = {
      accountNo: postData?.beneficiaryProfileId,
      amount: data?.amount.replace(',', ''),
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    localStorage.setItem('transaction', JSON.stringify(duplicateData));
    showLoader();
    const res = await httpPost(
      `/contact/account/service/send/money/userWallet/to/wallet/${receiverAccount}`,
      postData,
      customBaseUrl.contactUrl,
      pin
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer successful`, 'success').then(() => {
        setData({});
        hideModal(false);
        setVerifyLoading(false);
        setPin('');
        setReload(!reload);
      });
    } else {
      hideModal(false);
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const handleScan = async () => {
    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (data?.wayaMerchant) {
      getDetails(data?.wayaAccount);
      return;
    }

    const postData = {
      amount: data?.amount.replace(',', ''),
      locationGPS: `${data?.cords?.lat},${data?.cords?.long}`,
      scannedQCode: data?.qrCode,
      userAccountNumber: data.senderAccountNumber || defaultWallet?.accountNo,
    };
    const duplicateData = {
      accountNo: data?.userId,
      amount: data?.qrCode,
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    localStorage.setItem('transaction', JSON.stringify(duplicateData));
    showLoader();
    let res;
    if (data?.qrPaymentType === 'FIXED_PAYMENT') {
      res = await httpPost(
        `/green-gate/api/v1/fixed-qr-code-payment`,
        postData,
        customBaseUrl.QrUrl
      );
    } else {
      res = await httpPost(
        `/contact/account/service/send/money/to/userId/`,
        postData,
        customBaseUrl.QrUrl
      );
    }

    // console.log(res);
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', res?.message, 'success').then(() => {
        setData({});
        hideModal(false);
        setVerifyLoading(false);
        setPin('');
        setReload(!reload);
      });
    } else {
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res?.message || 'Something went wrong', 'error');
    }
  };
  const handlePayToAccount = async () => {
    if (validator.isEmpty(data.receiverName)) {
      swal('Oops!', 'Account name cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      setLoading(false);
      return;
    }

    const postData = {
      //  accountNumber: true,
      amount: data?.amount,
      benefAccountNumber: data?.receiverAccount,
      beneficiaryName: data?.beneficiaryName,
      //  beneficiaryProfileId: data?.receiverProfileId,
      //  beneficiaryUserId: data?.receiverId,
      tranNarration: !data?.reason || data?.reason === '' ? null : data?.reason,
      debitAccountNumber: data.senderAccountNumber,
      tranCrncy: 'NGN',
      transactionChannel: 'WEB',
      tranType: 'LOCAL',
      transactionCategory: 'TRANSFER',
      paymentReference: uuid(),
      senderName: `${firstName} ${surname}`,
    };
    const duplicateData = {
      benefAccountNumber: data?.receiverAccount,
      amount: data?.amount,
      tranNarration: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    localStorage.setItem('transaction', JSON.stringify(duplicateData));
    showLoader();
    const res = await httpPost(
      `/api/v1/wallet/sendmoney/account`,
      postData,
      customBaseUrl.walletUrl,
      pin
    );
    // console.log(res);
    if (res?.status === true) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer successful`, 'success').then(() => {
        setData({});
        hideModal(false);
        setVerifyLoading(false);
        setPin('');
        setReload(!reload);
      });
    } else {
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res?.message || 'Something went wrong', 'error');
    }
  };

  const handlePayToEmail = async () => {
    if (validator.isEmpty(data.email)) {
      swal('Oops!', 'Email cannot be empty', 'error');
      setLoading(false);
      return;
    }

    if (!validator.isEmail(data.email)) {
      swal('Oops!', 'Invalid email formtat', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      setLoading(false);
      return;
    }
    const postData = {
      accountNumber: false,
      beneficiaryProfileId: data?.receiverProfileId,
      beneficiaryUserId: data?.receiverId,
      description: !data?.reason || data?.reason === '' ? null : data?.reason,
      amount: data.amount.replace(',', ''),
      senderAccountNumber: data.senderAccountNumber,
    };
    const duplicateData = {
      accountNo: data?.receiverProfileId,
      amount: data?.amount.replace(',', ''),
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    showLoader();
    localStorage.setItem('transaction', JSON.stringify(duplicateData));

    const res = await httpPost(
      `/contact/account/service/send/money/to/email`,
      postData,
      customBaseUrl.contactUrl,
      pin
    );
    // console.log(res);
    if (res?.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer successful`, 'success').then(() => {
        setData({});
        hideModal(false);
        setVerifyLoading(false);
        setPin('');
        setReload(!reload);
      });
    } else {
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handlePayToNonWaya = async () => {
    // console.log('>>><<<');
    let formattedPhone;
    if (mode === 'email') {
      if (validator.isEmpty(data.email)) {
        swal('Oops!', 'Email cannot be empty', 'error');
        setLoading(false);
        return;
      }

      if (!validator.isEmail(data.email)) {
        swal('Oops!', 'Invalid email formtat', 'error');
        setLoading(false);
        return;
      }
    } else {
      if (validator.isEmpty(data.phoneNumber)) {
        swal('Oops!', 'Phone number cannot be empty', 'error');
        setLoading(false);
        return;
      }

      formattedPhone = `${
        parsePhoneNumber(data.phoneNumber).countryCallingCode
      }${parsePhoneNumber(data.phoneNumber).nationalNumber}`;
    }

    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      setLoading(false);
      return;
    }

    // {
    //   "amount": 3500,
    //   "customerDebitAccountNo": "2016706839",
    //   "emailOrPhoneNo": "saniefiok21@gmail.com",
    //   "fullName": "Prince Sam",
    //   "paymentReference": "566321444555666999833",
    //   "tranCrncy": "NGN",
    //   "tranNarration": "Take your wife to ZANIBAR"
    // }

    const profileId = localStorage.getItem('profileId');
    const postData = {
      tranNarration: !data?.reason || data?.reason === '' ? null : data?.reason,
      tranCrncy: 'NGN',
      fullName: data.name,
      amount: Number(data.amount.replace(',', '')),
      senderProfileId: profileId,
      customerDebitAccountNo: data.senderAccountNumber,
      paymentReference: uuid(),
      emailOrPhoneNo: mode === 'email' ? data.email : formattedPhone,
      senderName: `${firstName} ${surname}`,
      receiverName: data?.name,
    };
    const duplicateData = {
      accountNo: mode === 'email' ? data.email : formattedPhone,
      amount: data?.amount.replace(',', ''),
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      setShowPin(false);
      hideModal(false);
      return;
    }
    showLoader();
    localStorage.setItem('transaction', JSON.stringify(duplicateData));

    const res = await httpPost(
      `/api/v1/wallet/non-waya/payment/new`,
      postData,
      customBaseUrl.walletUrl,
      pin
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer successful`, 'success').then(() => {
        setData({});
        hideModal(false);
        setVerifyLoading(false);
        setReload(!reload);
        setPin('');
        setRequestType('wayaUser');
      });
    } else {
      hideLoader();
      setLoading(false);
      setVerifyLoading(false);
      setPin('');
      setShowPin(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  useEffect(() => {
    setData({});
    if (fromLocation === 'contacts') {
      setMode('phone');
      setData(fromData);
    }
    if (fromLocation === 'chats') {
      setMode('wayaId');
      setQuery(fromData.name);
      setData(fromData);
    }

    return () => {
      setMode('email');
    };
  }, []);
  /*  useEffect(() => {
    getCharges();
  }, [data.amount]); */

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Transfer Funds</ModalHeader>
      <div className="text-center fund-tab-wrap pb-2 pb-3">
        <div className="row m-0 fund-tab">
          <Container
            className={`fs-6 col ${mode === 'email' ? 'fund-tab-active' : ''}`}
            onClick={() => {
              setMode('email');
              setData({});
              getCharges(data.senderAccountNumber, data.amount);
            }}
          >
            Pay to Email
          </Container>
          <Container
            className={`fs-6 col ${mode === 'wayaId' ? 'fund-tab-active' : ''}`}
            onClick={() => {
              setMode('wayaId');
              setData({});
              getCharges(data.senderAccountNumber, data.amount);
            }}
          >
            Pay to Waya ID
          </Container>
          <Container
            className={`fs-6 col ${
              mode === 'account' ? 'fund-tab-active' : ''
            }`}
            onClick={() => {
              setMode('account');
              setData({});
              getCharges(data.senderAccountNumber, data.amount);
            }}
          >
            Pay to Account
          </Container>
          <Container
            className={`fs-6 col ${mode === 'scan' ? 'fund-tab-active' : ''}`}
            onClick={() => {
              setMode('scan');
              setData({});
              getCharges(data.senderAccountNumber, data.amount);
            }}
          >
            Scan to Pay
          </Container>
          <Container
            className={`fs-6 col ${mode === 'phone' ? 'fund-tab-active' : ''}`}
            onClick={() => {
              setMode('phone');
              setData({});
              getCharges(data.senderAccountNumber, data.amount);
            }}
          >
            Pay to Phone
          </Container>
        </div>
      </div>
      {mode === 'email' ? (
        <PayToEmail
          getCharges={getCharges}
          setCommingSoon={setCommingSoon}
          data={data}
          setData={setData}
          walletList={walletList}
          setRequestType={setRequestType}
          requestType={requestType}
        />
      ) : mode === 'wayaId' ? (
        <PayToWayaId
          getCharges={getCharges}
          data={data}
          setData={setData}
          walletList={walletList}
          query={query}
          setQuery={setQuery}
        />
      ) : mode === 'phone' ? (
        <PayToPhone
          getCharges={getCharges}
          setCommingSoon={setCommingSoon}
          data={data}
          setData={setData}
          walletList={walletList}
          setRequestType={setRequestType}
          requestType={requestType}
        />
      ) : mode === 'account' ? (
        <PayToAccount
          getCharges={getCharges}
          setCommingSoon={setCommingSoon}
          data={data}
          setData={setData}
          walletList={walletList}
          setRequestType={setRequestType}
          requestType={requestType}
        />
      ) : (
        <PayToScan
          getCharges={getCharges}
          setData={setData}
          data={data}
          setDisplayData={setDisplayData}
          setShowPin={setShowPin}
          walletList={walletList}
          hideModal={hideModal}
        />
      )}
      <CommingSoonModal showModal={commingSoon} hideModal={setCommingSoon} />

      <ModalFooter>
        <Button
          //  style={mode === 'scan' ? { display: 'none' } : {}}
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            getCharges(data.senderAccountNumber, data.amount);
            if (!Object.keys(data).length) {
              swal('Oops!', 'All input field cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (!data.amount || data.amount === '') {
              swal('Oops!', 'Amount input field can not be empty', 'error');
              setLoading(false);
              return;
            }
            if (!data.reason || data.reason === '') {
              swal(
                'Oops!',
                'Transaction description field can not be empty',
                'error'
              );
              setLoading(false);
              return;
            }
            if (!data.senderAccountNumber || data.senderAccountNumber === '') {
              swal('Oops!', 'Account input field can not be empty', 'error');
              setLoading(false);
              return;
            }
            if (
              Number(charges) + Number(data.amount.replace(',', '')) >
              Number(data?.balance)
            ) {
              swal(
                'Oops!',
                `You have insufficient balance in the selected account to proceed \n You need NGN ${Number(
                  Number(charges) + Number(data.amount.replace(',', ''))
                )} in your account `,
                'error'
              );
              return;
            }
            if (mode === 'email') {
              if (!data.email || data.email === '') {
                swal('Oops!', 'Email input field can not be empty', 'error');
                setLoading(false);
                return;
              }
              setDisplayData({
                recipent:
                  requestType === 'wayaUser' ? data.receiverName : data?.name,
                amount: `${moneyFomatter(data.amount)}NGN`,
                reason: data.reason,
                'Transaction Fee': `${charges}NGN`,
                Total: `${moneyFomatter(
                  +data.amount.replace(',', '') + charges
                )} NGN`,
              });
            }
            if (mode === 'account') {
              if (!data.receiverAccount || data.receiverAccount === '') {
                swal(
                  'Oops!',
                  'receiver account input field can not be empty',
                  'error'
                );
                setLoading(false);
                return;
              }
              setDisplayData({
                recipent: data.receiverName,
                amount: `${moneyFomatter(data.amount)}NGN`,
                reason: data.reason,
                'Transaction Fee': `${charges}NGN`,
                Total: `${moneyFomatter(
                  +data.amount.replace(',', '') + charges
                )} NGN`,
              });
            }

            if (mode === 'wayaId') {
              if (!data.userId || data.userId === '') {
                swal('Oops!', 'user input field can not be empty', 'error');
                setLoading(false);
                return;
              }
              setDisplayData({
                recipent:
                  fromLocation === 'chats'
                    ? `${fromData.name}`
                    : `${data?.receiverName}`,
                amount: `${moneyFomatter(data.amount)}NGN`,
                reason: data.reason,
                'Transaction Fee': `${charges}NGN`,
                Total: `${moneyFomatter(
                  +data.amount.replace(',', '') + charges
                )} NGN`,
              });
            }

            if (mode === 'phone') {
              if (!data.phoneNumber || data.phoneNumber === '') {
                swal(
                  'Oops!',
                  'Phone no. input field can not be empty',
                  'error'
                );
                setLoading(false);
                return;
              }
              setDisplayData({
                recipent: data?.name,
                amount: `${moneyFomatter(data.amount)}NGN`,
                reason: data.reason,
                'Transaction Fee': `${+charges}NGN`,
                Total: `${moneyFomatter(
                  +data.amount.replace(',', '') + +charges
                )} NGN`,
              });
            }
            if (mode === 'scan') {
              setDisplayData({
                recipent: data?.name,
                amount: `${moneyFomatter(data.amount)}NGN`,
                reason: data.reason,
                'Transaction Fee': `${+charges}NGN`,
                Total: `${moneyFomatter(+data.amount + +charges)} NGN`,
              });
            }
            // setLoading(true);
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={() => {
            if (mode === 'phone') {
              if (requestType === 'wayaUser') handlePayToPhone();
              if (requestType === 'nonWayaUser') handlePayToNonWaya();
            } else if (mode === 'wayaId') {
              handlePayToId();
            } else if (mode === 'account') {
              handlePayToAccount();
            } else if (mode === 'email') {
              if (requestType === 'wayaUser') handlePayToEmail();
              if (requestType === 'nonWayaUser') handlePayToNonWaya();
            } else if (mode === 'scan') {
              handleScan();
            }
          }}
          id="success-wrapper"
          title="Transfer Funds"
          otp={pin}
          setOtp={setPin}
          displayData={displayData || null}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
