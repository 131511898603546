import React from 'react';
import styles from './styles.module.css';
import { BoldText, NormalText } from '../../../../shared/texts/allText';
import { Col, Row } from '../../../../shared/containers/containers';

const CommissionStats = ({ name, count, color }) => {
  return (
    <Col justify="space-between" className={styles.TransactionStats}>
      <BoldText color={color} size={20}>
        {count}
      </BoldText>
      <NormalText bottom={25} size={14} color={color}>
        {name}
      </NormalText>
    </Col>
  );
};
export const RevenueStats = ({ name, count, color }) => {
  return (
    <Col justify="space-between" className={styles.RevenueStats}>
      <BoldText color={color} size={20}>
        {count}
      </BoldText>
      <NormalText bottom={25} size={14} color={color}>
        {name}
      </NormalText>
    </Col>
  );
};
export const CommissionCategory = ({ name, count, color }) => {
  return (
    <Col justify="space-between" className={styles.CommissionStats}>
      <BoldText color={color} size={25}>
        {count}
      </BoldText>
      <NormalText bottom={25} size={13} color={color}>
        {name}
      </NormalText>
    </Col>
  );
};
export default CommissionStats;
