/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
// import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import swal from 'sweetalert';
import './index.css';
import Button from '../../../shared/Button';
import Back from '../../../../assets/backArrow.svg';
import {
  customBaseUrl,
  httpPostFormData,
  httpGet,
  // httpPost,
} from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';

const CreateDispute = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { showModal, hideModal, id, setRefresh, refresh } = props;
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(false);
  // const [DisputeCategory, setDisputeCategory] = useState('');
  const [disputeTypes, setDisputeTypes] = useState([]);
  const { userDetails } = useContext(ProfileContext);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    (async () => {
      // const url =
      //   'https://services.staging.wayabank.ng/complaint-service/ticket-category';
      // const res = await httpGet(url);
      const res = await httpGet(
        `${customBaseUrl.wayapayDispute}/ticket-category`
      );
      console.log(res);
      setDisputeTypes([...(res?.data?.ticketCategoryResponses ?? '')]);
    })();
  }, []);

  // onchange
  // const onChange = (e) => {
  //   e.preventDefault();
  //   let files;
  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //   }
  //   setPostData({ ...postData, attachment: files[0] });
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     // setPreview(reader.result);
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  const handleSubmit = async () => {
    console.log('post Data', postData);
    setIsloading(true);
    const formData = new FormData();
    formData.append('categoryId', postData?.categoryId);
    formData.append('disputeDescription', postData?.disputeDescription);
    formData.append('disputeSubject', postData?.disputeSubject);
    formData.append('disputeType', postData?.disputeType);
    formData.append('transactionAmount', postData?.transactionAmount);
    formData.append('file', postData?.file);
    formData.append('transactionId', postData?.transactionId);
    formData.append('userId', userDetails?.userId);

    // const url = `${customBaseUrl.wayapayDispute}/dispute`;
    const res = await httpPostFormData(
      `/dispute-extended/${postData.categoryId}`,
      formData,
      customBaseUrl.wayapayDispute
    );
    setIsloading(false);
    if (res.status) {
      setRefresh(!refresh);
      swal('Success', res.message).then(() => {
        setLoading(false);
        hideModal(false);
      });
    }
    console.log('Dispute ticket', res);
  };
  /* const handleSocialSubmit = async () => {
    /* const dispute = {
      disputeTypeId: disputeTypes.filter(
        (el) => el.disputeTypeName === category
      )[0].disputetype_id,
      disputeCategoryId: 1,
      subjectOfDispute: postData.subject,
      disputeDescription: postData.description,
      categories: {
        categoryId: 0,
        disputeCategories: DisputeCategory,
      },
      disputeTypes: {
        disputeTypeName: category,
        disputetype_id: disputeTypes.filter(
          (el) => el.disputeTypeName === category
        )[0].disputetype_id,
      },
    };
  */ /*
    const newForm = new FormData();

    newForm.append(
      'dispute'
      // new Blob([JSON.stringify(dispute)], { type: 'application/json' })
      // JSON.stringify(dispute)
      // 'dispute.json'
    );

    postData.attachment &&
      newForm.append('attachment', postData.attachment, 'cacDocument.jpeg');
    // console.log('disputeData', dispute);
    const res = await httpPostFormData(
      '/api/v1/dispute',
      newForm,
      customBaseUrl.disputeUrl
    );
    // console.log('res', res);
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setLoading(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  */
  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        hideModal(false);
        setCurrentPage(1);
      }}
      id={id}
      centered
    >
      <div
        className="text-end w-100"
        onClick={() => {
          hideModal(false);
          setCurrentPage(1);
        }}
      >
        <FaTimes />
      </div>
      <ModalBody className="createDispute">
        <div className="dispute-header">
          {currentPage > 1 && (
            <img
              src={Back}
              alt=""
              className="ms-0"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
          )}
          <div className="mx-auto">
            {currentPage === 1 ? 'Create New Dispute' : 'Dispute Log'}
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">Dispute Type</div>
          <div className="pg-1-input">
            <select
              name=""
              id=""
              onChange={(e) => {
                const { category } = JSON.parse(e.target.value);
                setPostData({
                  ...postData,
                  disputeType: category,
                  categoryId: JSON.parse(e.target.value).id,
                });
              }}
            >
              <option>Select Dispute Type</option>
              {disputeTypes.map((el) => (
                <option key={el} value={JSON.stringify(el)}>
                  {el.category}
                </option>
              ))}
            </select>
          </div>
        </div>
        {postData.disputeType === 'Payment Dispute' && (
          <div className="pg-1-1">
            <div className="title">Dispute Amount</div>
            <div className="pg-1-input">
              <input
                type="number"
                value={postData.transactionAmount}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    transactionAmount: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="pg-1-1">
          <div className="title">Dispute Subject</div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.disputeSubject}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  disputeSubject: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">Dispute Description</div>
          <div className="pg-1-input">
            <textarea
              required
              value={postData.disputeDescription}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  disputeDescription: e.target.value,
                })
              }
              cols="40"
              rows="5"
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">Transaction ID</div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.transactionId}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  transactionId: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">Attach File</div>
          <div className="pg-1-input">
            <input
              type="File"
              onChange={(e) =>
                setPostData({
                  ...postData,
                  file: e.target.files[0],
                })
              }
            />
          </div>
        </div>
        {/* category && currentPage === 1 && (
          <div className="page-1">
            <div className="pg-1-1">
              <div className="title">Transaction Amount</div>
              <div className="pg-1-input">
                <input
                  type="number"
                  value={postData.transactionAmount}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      transactionAmount: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="pg-1-1">
              <div className="title">Narration of Dispute</div>
              <div className="pg-1-input">
                <input
                  type="text"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      narrationOfDispute: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="pg-1-1">
              <div className="title">Extra Details</div>
              <div className="pg-1-input">
                <input
                  type="text"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      extraDetails: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="page-1">
              <div className="pg-1-input">
                <div className="title">Enter transaction date</div>
                <div className="pg-1-input">
                  <input
                    type="date"
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        transactionDate: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="page-1">
              <div className="pg-1-input">
                <div className="title">Transaction ID</div>
                <div className="pg-1-input">
                  <input
                    type="text"
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        transactionId: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {category === 'payment' && currentPage === 2 && (
          
        )} */}

        {/* category === 'socials' && (
          <div className="page-1">
            <div className="pg-1-input">
              <div className="title">Dispute Category</div>
              <div className="pg-1-input">
                <select
                  name=""
                  id=""
                  onChange={(e) => setDisputeCategory(e.target.value)}
                >
                  <option value="">Select Dispute Category</option>
                  <option value="Donations">Donations</option>
                  <option value="Events">Events</option>
                  <option value="Polling">Polling</option>
                  <option value="Post">Post</option>
                  <option value="Comment">Comment</option>
                  <option value="Moment">Moment</option>
                  <option value="Page">Page</option>
                  <option value="Groups">Groups</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="pg-1-1">
              <div className="title">Dispute Subject</div>
              <div className="pg-1-input">
                <input
                  type="text"
                  value={postData.subject}
                  onChange={(e) =>
                    setPostData({ ...postData, subject: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="pg-1-input">
              <div className="title">Dispute Description</div>
              <textarea
                name=""
                id=""
                style={{
                  height: '120.76px',
                }}
                value={postData.description}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        {category === 'auth' && (
          <div className="page-1">
            <div className="pg-1-input">
              <div className="title">Dispute Category</div>
              <div className="pg-1-input">
                <select name="" id="">
                  <option value="">Select Dispute Category</option>
                  <option value="Email Notifications">
                    Email Notifications
                  </option>
                  <option value="SMS Notifications">SMS Notifications</option>
                  <option value="In App Notifications">
                    In App Notifications
                  </option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>

            <div className="pg-1-input">
              <div className="title">Dispute Description</div>
              <textarea
                name=""
                id=""
                style={{
                  height: '120.76px',
                }}
              />
            </div>
          </div>
        )}
        {/* {category !== '' && (
          <div className="pg-1-1">
            <div className="title">Attach Files</div>
            <FileInput onChange={onChange} />
          </div>
        )} */}
        <div className="btn-div">
          <Button
            // className="w-100"s
            role="button"
            disabled={loading || false}
            loading={isLoading}
            content="Submit"
            onClick={handleSubmit}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
// const FileInput = ({ onChange }) => {
//   const inputFileRef = useRef();
//   return (
//     <div className="pg-1-input" style={{ position: 'relative' }}>
//       <input
//         disabled
//         type="text"
//         // value={postData.phoneNumber}
//         // onChange={(e) =>
//         //   setPostData({ ...postData, phoneNumber: e.target.value })
//         // }
//       />
//       <div
//         style={{
//           paddingRight: 10,
//           paddingLeft: 10,
//           backgroundColor: '#ECECEC',
//           width: '20%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'absolute',
//           right: 0,
//           paddingTop: 11,
//           marginRight: 1,
//           borderRadius: '0px 3px 3px 0px',
//         }}
//       >
//         <input
//           onChange={(e) => onChange(e)}
//           ref={inputFileRef}
//           type="file"
//           style={{ display: 'none' }}
//         />
//         <p
//           style={{
//             color: '#666666',
//             fontSize: 13,
//             height: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//           onClick={() => {
//             inputFileRef.current.click();
//           }}
//         >
//           Browse
//         </p>
//       </div>
//     </div>
//   );
// };
export default CreateDispute;
