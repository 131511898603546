import React from 'react';
import { CustomerServiceOutlined } from '@ant-design/icons';
import envConfig from '../../../utils/envConfig';
function SupportButton() {
  return (
    <footer style={{ position: 'fixed', left: 0, bottom: 20, width: '100%' }}>
      <div>
        <div className="col-md-11 text-right">
          <a
            href={`${envConfig.websiteAppUrl}/support.html`}
            target="_blank"
            rel="noreferrer"
          >
            <button
              type="button"
              className="btn text-right"
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                float: 'right',
                background: '#FF6700',
              }}
            >
              <CustomerServiceOutlined />
              <span>Support </span>
            </button>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default SupportButton;
