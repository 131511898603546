import React from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Paper } from '@material-ui/core';
import tableIcons from './icons';
import './table.css';
// import search from '../../../assets/search.svg';
// import log from '../../../assets/log.svg';

import { Row } from '../containers/containers';
import RightIconInput from '../inputRightIcon/inputs';

export default function ActivitiesTable(props) {
  const { options, columns, data, title, setDate, date, onChange } = props;
  const { RangePicker } = DatePicker;

  // const handleChange = () => [];
  const dateFormat = 'YYYY/MM/DD';

  return (
    <div className="table-wrap border styleTable" style={{ width: '95%' }}>
      <MaterialTable
        components={{
          Container: function cont(prop) {
            return <Paper {...prop} elevation={0} />;
          },
          Toolbar: function toolb() {
            return (
              <Row width="100%">
                <RightIconInput />
                <Row width="100%">
                  <RangePicker
                    defaultValue={[
                      moment('2019/01/01', dateFormat),
                      moment('2019/01/01', dateFormat),
                    ]}
                    value={date ? date.map((el) => moment(el, dateFormat)) : []}
                    format={dateFormat}
                    onChange={(e) => {
                      const mapped = e.map((el) => el.format(dateFormat));
                      setDate(mapped);
                      onChange(mapped);
                    }}
                  />
                </Row>
              </Row>
            );
          },
          // Header: function headi(prop) {
          //   return (
          //     <div
          //     // style={{ backgroundColor: 'rgba(231, 71, 44, 0.16)' }}
          //     >
          //       <MTableHeader {...prop} />
          //     </div>
          //   );
          // },
        }}
        icons={tableIcons}
        options={
          {
            exportButton: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: '#FF6700',
              color: '#FFFFFF',
              whiteSpace: 'nowrap',
              zIndex: 0,
            },
          } || options
        }
        columns={columns}
        data={data || []}
        title={title || ''}
      />
    </div>
  );
}
