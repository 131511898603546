import React from 'react';
import MaterialTable, { MTablePagination } from 'material-table';
import { Paper } from '@material-ui/core';
import tableIcons from './icons';
import './table.css';
// import search from '../../../assets/search.svg';
// import log from '../../../assets/log.svg';

export default function DisputeTable(props) {
  const { options, columns, data, title } = props;

  // const handleChange = () => [];
  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: function cont(prop) {
            return <Paper {...prop} elevation={0} />;
          },
        }}
        icons={tableIcons}
        options={
          {
            exportButton: false,
            search: true,
            sorting: true,
            headerStyle: {
              backgroundColor: '#FF6700',
              color: '#FFFFFF',
              whiteSpace: 'nowrap',
              zIndex: 0,
            },
            pageSize: 10,
          } || options
        }
        columns={columns}
        data={data || []}
        title={title || ''}
      />
    </div>
  );
}
