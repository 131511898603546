import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

const MoneyInput = (props) => {
  const { value, onChange, className, placeholder } = props;
  const amount = '0.00';
  return (
    <CurrencyInput
      className={className}
      prefix="&#8358;"
      placeholder={placeholder}
      value={value || amount}
      onChangeEvent={onChange}
    />
  );
};

MoneyInput.defaultProps = {
  placeholder: '0.00',
  className: '',
};

MoneyInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MoneyInput;
