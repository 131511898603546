import React, { useContext, useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import swal from 'sweetalert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  customBaseUrl,
  httpPost,
  httpPostFormData,
} from '../../../action/http';
import Button from '../../shared/Button';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { indexOf } from 'lodash';
import { DeleteFilled } from '@ant-design/icons';
import { WalletContext } from '../../../store/context/WalletContext';
import moneyFomatter from '../../../utils/moneyFormatter';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { hideLoader, showLoader } from '../../../utils/loader';
import invoicePdf from '../Setting/invoicePdf';
// import { ProfileContext } from '../../../store/context/ProfileContext';

const initialItemsState = {
  description: '',
  quantity: '',
  unitPrice: '',
};
const CreateInvoice = (props) => {
  const elementRef = useRef(null);
  const { getInvoice, setActive, organisationName } = props;
  const { walletList } = useContext(WalletContext);
  const {
    userDetails: { email, phoneNumber, userId },
    profile: { address, otherDetails },
  } = useContext(ProfileContext);
  // const {
  //   profile: { userId },
  // } = useContext(ProfileContext);
  const [items, setItems] = useState([initialItemsState]);
  const [data, setData] = useState([]);
  const [roleData, setRoleData] = useState({
    permissions: [],
    name: '',
    description: '',
  });
  const handleAddItem = async () => {
    setItems([...items, initialItemsState]);
  };

  const handleDelete = async (i) => {
    // const newItem = [];
    // console.log({ items });
    // await items?.map((e, x) => {
    //   if (i !== x) newItem.push(e);
    // });
    // console.log({ i, del: items[i] });
    const newL = items;
    newL.splice(i, 1);
    console.log({ newL });
    setItems(newL);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    let newForm = new FormData();
    newForm.append('file', data.file);
    newForm.append('fileName', 'Logo');
    newForm.append('userId', userId);
    const resp = await httpPostFormData(
      `/upload/others`,
      newForm,
      customBaseUrl.fileResourseUrl
    );

    if (resp.status) {
      const postData = {
        accountNumber: data.senderAcctNo,
        address,
        bankName: 'Waya MFB',
        businessSlogan: data.slogan,
        buyerAddress: data.buyerAddress,
        buyerEmailAddress: data?.buyerEmail,
        buyerName: data?.buyerName,
        buyerPhoneNumber: data?.buyerPhone,
        email,
        invoiceDate: new Date(),
        itemsList: items,
        logo64: data?.logo64,
        logo: resp?.data ?? '',
        name: otherDetails?.organisationName,
        paymentDueDate: data.dueDate,
        phoneNumber,
      };
      const res = await httpPost(
        `/api/v1/wayabank/generate-invoice`,
        postData,
        customBaseUrl.invoiceUrl
      );
      if (res?.status) {
        invoicePdf(res?.data, postData);
        hideLoader();
        getInvoice();
        setActive('My Invoice');
      } else {
        hideLoader();
      }
    } else {
      hideLoader();
    }
    // console.log({ res });
    // const file = new Blob([res]);
    // const fileURL = URL.createObjectURL(file);
    // let alink = document.createElement('a');
    // alink.href = fileURL;
    // alink.download = `wayabank invoice.pdf`;
    // alink.click();
  };
  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = dataUrl;
        const reader = new FileReader();
        const file = new Blob([dataUrl]);
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          console.log({ dataUrl });
          // setData({ ...data, file: e.target.files[0] });
          //  setPreview1(reader.result);
        };
        return reader;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Merchant Name<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <input
            type="text"
            placeholder="Merchant name"
            className="w-75"
            disabled
            required
            value={otherDetails?.organisationName}
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
          />
        </div>
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Email<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <input
            type="text"
            placeholder="merchant Email"
            className="w-75"
            disabled
            required
            value={email}
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
          />
        </div>
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Phone Number<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <input
            type="text"
            placeholder="Phone number"
            className="w-75"
            disabled
            required
            value={phoneNumber}
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
          />
        </div>
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Address<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <input
            type="text"
            placeholder="Address"
            className="w-75"
            disabled
            required
            value={address}
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
          />
        </div>
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Business Logo<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <input
            type="file"
            placeholder="Describe this item"
            className="w-75"
            required
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
            onChange={(e) => {
              const reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);
              reader.onloadend = () => {
                setData({
                  ...data,
                  file: e.target.files[0],
                  logo64: reader.result,
                });
                //  setPreview1(reader.result);
              };
              return reader;
            }}
          />
        </div>
        <div className="my-3 w-50">
          <div>Business Slogan</div>
          <input
            type="text"
            placeholder="Business Slogn"
            className="w-75"
            value={data.slogan}
            onChange={(e) => setData({ ...data, slogan: e.target.value })}
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
          />
        </div>
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Account to receive Payment<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <select
            name="filter"
            className="border w-75"
            onChange={(e) => {
              const [accountNo] = e.target.value.split(',');
              setData({
                ...data,
                senderAcctNo: accountNo,
              });
            }}
          >
            <option value="Select" selected disabled>
              Select account
            </option>
            {walletList.length
              ? walletList.map((item) => (
                  <option
                    value={`${item?.accountNo},${item?.clr_bal_amt}`}
                    key={item?.accountNo}
                  >
                    {`${item?.description} - ${item?.accountNo} (${
                      item.acct_crncy_code
                    } ${moneyFomatter(Number(item?.clr_bal_amt).toFixed(2))})`}
                  </option>
                ))
              : ''}
          </select>
        </div>
        <div className="my-3 w-50">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Payment Due Date<h6 style={{ color: 'red' }}>*</h6>
          </div>
          <input
            type="date"
            placeholder="due date"
            className="w-75"
            required
            value={data.dueDate}
            onChange={(e) => setData({ ...data, dueDate: e.target.value })}
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
          />
        </div>
      </div>
      <div>
        <hr />
        <div>Buyer s Details</div>
        <div className="row">
          <div className="my-3 w-50">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Buyer Name<h6 style={{ color: 'red' }}>*</h6>
            </div>
            <input
              type="text"
              placeholder="Buyer Details"
              className="w-75"
              required
              value={data?.buyerName}
              onChange={(e) => setData({ ...data, buyerName: e.target.value })}
              style={{
                background: '#FFFFFF',
                border: '1px solid #BDBDBD',
                borderRadius: '4px',
                padding: '15px 12px',
              }}
            />
          </div>
          <div className="my-3 w-50">
            <div>Email</div>
            <input
              type="email"
              placeholder="Buyer Email"
              className="w-75"
              value={data.buyerEmail}
              onChange={(e) => setData({ ...data, buyerEmail: e.target.value })}
              style={{
                background: '#FFFFFF',
                border: '1px solid #BDBDBD',
                borderRadius: '4px',
                padding: '15px 12px',
              }}
            />
          </div>
          <div className="my-3 w-50">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Phone Number<h6 style={{ color: 'red' }}>*</h6>
            </div>
            <input
              type="text"
              placeholder="Buyer Phone no."
              className="w-75"
              value={data.buyerPhone}
              required
              onChange={(e) => setData({ ...data, buyerPhone: e.target.value })}
              style={{
                background: '#FFFFFF',
                border: '1px solid #BDBDBD',
                borderRadius: '4px',
                padding: '15px 12px',
              }}
            />
          </div>
          <div className="my-3 w-50">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Buyer Address<h6 style={{ color: 'red' }}>*</h6>
            </div>
            <input
              type="text"
              placeholder="Buyer Address"
              className="w-75"
              value={data.buyerAddress}
              required
              onChange={(e) =>
                setData({ ...data, buyerAddress: e.target.value })
              }
              style={{
                background: '#FFFFFF',
                border: '1px solid #BDBDBD',
                borderRadius: '4px',
                padding: '15px 12px',
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h5>Item List</h5>
        <h6 style={{ color: 'red' }}>*</h6>
      </div>
      <table className="table px-3">
        <thead>
          <th>Description</th>
          <th>QTY</th>
          <th>Unit Price</th>
          {/* <th>Action</th> */}
        </thead>
        <tbody>
          {items?.map((item, i) => (
            <tr key={i}>
              <td>
                <div className="my-3 w-100">
                  <input
                    type="text"
                    value={item[i]?.description}
                    placeholder="Describe this item"
                    className="w-100"
                    style={{
                      background: '#FFFFFF',
                      border: '1px solid #BDBDBD',
                      borderRadius: '4px',
                      padding: '15px 12px',
                    }}
                    onChange={(e) => {
                      const newState = items.map((obj, b) => {
                        if (i == b) {
                          return {
                            ...obj,
                            description: e.target.value,
                          };
                        }
                        return obj;
                      });
                      setItems(newState);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="my-3 w-100">
                  <input
                    type="number"
                    min={1}
                    value={item[i]?.quantity}
                    placeholder="Quantity of this item"
                    className="w-100"
                    style={{
                      background: '#FFFFFF',
                      border: '1px solid #BDBDBD',
                      borderRadius: '4px',
                      padding: '15px 12px',
                    }}
                    onChange={(e) => {
                      const newState = items.map((obj, b) => {
                        if (i == b) {
                          return {
                            ...obj,
                            quantity: e.target.value,
                          };
                        }
                        return obj;
                      });
                      setItems(newState);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="my-3 w-100">
                  <input
                    type="number"
                    min={0}
                    value={item[i]?.unitPrice}
                    placeholder="Amount"
                    className="w-100"
                    style={{
                      background: '#FFFFFF',
                      border: '1px solid #BDBDBD',
                      borderRadius: '4px',
                      padding: '15px 12px',
                    }}
                    onChange={(e) => {
                      const newState = items.map((obj, b) => {
                        if (i == b) {
                          return {
                            ...obj,
                            unitPrice: e.target.value,
                          };
                        }
                        return obj;
                      });
                      setItems(newState);
                    }}
                  />
                </div>
              </td>
              {/* <td style={{ textAlign: 'center', paddingTop: '20px' }}>
                <DeleteFilled
                  onClick={() => {
                    console.log({ hh: i });
                    handleDelete(i);
                  }}
                />
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        content="Add Item"
        type="button"
        onClick={handleAddItem}
        style={{ marginTop: '50px' }}
      />
      <div className="text-center">
        <button
          content="Continue"
          type="submit"
          className="btn btn-primary text-center"
          // onClick={handleSubmit}
          style={{
            textAlign: 'center',
            width: '50%',
            marginTop: '50px',
          }}
        >
          Continue
        </button>
      </div>
      <div style={{ height: 200 }} />
    </form>
  );
};

export default CreateInvoice;
