import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
import { customBaseUrl, httpDelete, httpGet } from '../../../../action/http';
import leftArrow from '../../../../assets/image/left-arrow.png';
import plusIcon from '../../../../assets/image/plus-circle.png';
import verveIcon from '../../../../assets/image/verve.png';
// import visaIcon from '../../../../assets/image/visa-card2.png';
import masterIcon from '../../../../assets/image/master-card.png';
import './index.scss';
import { CardContext } from '../../../../store/context/CardContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import PinModal from '../PinModal';

export default function CardList(props) {
  const { showModal, hideModal, bankCardList, setShowLinkCardModal } = props;
  const { setReload, reload } = useContext(CardContext);
  const [pin, setPin] = useState('');
  const { profile } = useContext(ProfileContext);
  const myRef = React.useRef(null);
  const [showPin, setShowPin] = useState();
  const [eachAccount, setEachAccount] = useState();
  const handleDelete = async (accountNumber) => {
    const res = await httpDelete(
      `/card/delete/${profile.userId}/${accountNumber}`,
      {},
      customBaseUrl.cardUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success');
      setReload(!reload);
      hideModal(false);
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const handleVerifyPin = async () => {
    if (!pin || pin === '') {
      swal('Oops!', 'pin is required', 'error');

      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${pin}`
    );
    // console.log(res);
    if (res.status) {
      // setLoading(false);
      handleDelete(eachAccount);
    } else {
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  return (
    <div ref={myRef} className="bank-list">
      <Modal
        isOpen={showModal}
        id="manage-bank-list-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div className="header">
          <div className="row">
            <div className="col-3 h-left">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal(false);
                }}
              >
                <img src={leftArrow} alt="left" />
              </a>
            </div>
            <div className="col-6 h-center text-center">Manage Card</div>
            <div className="col-3 h-right text-center">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowLinkCardModal(true);
                  hideModal(false);
                }}
              >
                <img src={plusIcon} alt="plus" />
              </a>
            </div>
          </div>
        </div>
        <ModalBody>
          {bankCardList.length
            ? bankCardList.map((item) => {
                return (
                  <Collapsible
                    trigger={
                      <div className="card-list-div">
                        <div className="row">
                          <div className="col-2 img-div">
                            <img
                              src={item.type ? verveIcon : masterIcon}
                              alt="visa"
                            />
                          </div>
                          <div className="col-6 pt-2">
                            <p className="mb-0">{`***********${item.cardNumber.slice(
                              0,
                              4
                            )}`}</p>
                            <p className="mb-0">{item.accountName}</p>
                          </div>
                        </div>
                      </div>
                    }
                    key={Math.random()}
                  >
                    <div className="col-12 account-details">
                      <div className="row">
                        <div className="col-6">Card type</div>
                        <div className="col-6 text-end">{item.type}</div>
                      </div>
                      <div className="row">
                        <div className="col-6">Card name</div>
                        <div className="col-6 text-end">{item.accountName}</div>
                      </div>
                    </div>

                    <div className="text-center">
                      <a
                        href="/#"
                        className="text-primary text-decoration-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setEachAccount(item.cardNumber);
                          setShowPin(true);
                        }}
                      >
                        Delete Bank Card
                      </a>
                    </div>
                  </Collapsible>
                );
              })
            : ''}
          {showPin && (
            <PinModal
              center
              showModal={showPin}
              hideModal={setShowPin}
              otp={pin}
              setOtp={setPin}
              handleSubmit={handleVerifyPin}
              separator=""
              // buttonLabel={showFund ? 'Top-up Wallet' : 'Send'}

              title="Input your 4 digit PIN"
              description="Please input your 4 digit PIN to confirm transaction"
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

CardList.defaultProps = {
  showModal: false,
  // bankCardList: [],
};

CardList.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  // bankCardList: PropTypes.arrayOf(PropTypes.object),
};
