import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import validator from 'validator';
import { Button, Modal } from 'reactstrap';
import { customBaseUrl, httpGet } from '../../action/http';
import ResendOtp from '../../components/shared/Modals/Otp/ResendOtp';
import PinModal from '../../components/shared/Modals/PinModal';
import ResetPin from '../../components/shared/Modals/ResetPin';
import SecurePin from '../../components/shared/Modals/SecurePin';
import { sendResetPinOtpViaEmail, sendResetPinOtpViaPhone } from '../calls';
import Profile from '../../components/shared/Modals/Profile';
import { ProfileContext } from './ProfileContext';

export const ModalContext = createContext();

export const ModalContextProvider = (props) => {
  const { children } = props;
  const history = useHistory();
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [showPinVerify, setShowPinVerify] = useState(false);
  const [showResetPin, setShowResetPin] = useState(false);
  const [showCreatePin, setShowCreatePin] = useState(false);
  const [isValidatePin, setIsValidatePin] = useState(false);
  const [showCreateProfile, setCreateProfile] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [verified, setVerified] = useState(false);
  const [createPinVerified, setCreatePinVerified] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [otpType, setOtpType] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [showClientModal, setShowClientModal] = useState(false);
  const [changePasswordVerified, setChangePasswordVerified] = useState(false);
  const { profile } = useContext(ProfileContext);
  const messages = {
    createPin: {
      title: 'OTP Verification',
      description:
        'Please input your 6 digits OTP sent to your email address or phone number',
    },
    changePassword: {
      title: 'OTP Verification',
      description:
        'Please input your 6 digits OTP sent to your email address or phone number',
    },
    verifyTimeoutPin: {
      title: 'Session has been Idle for too long',
      description: 'Please unlock session with your security PIN',
    },
    verifyPin: {
      title: 'Input your 4 digit PIN',
      description: 'Please input your 4 PIN confirm',
    },
    others: {
      title: 'Input your 4 digit OTP',
      description: 'Please input your 4 OTP confirm',
    },
  };

  const validatePin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res?.status) {
      setLoading(false);
      setVerified(true);
      setShowPinVerify(false);
      setModalAction('');
      setOtp('');
      setIsValidatePin(false);
      // swal('Done', `Pin verified`, 'success').then(() => {
      //   setVerified(true);
      //   setShowPinVerify(false);
      //   setModalAction('');
      // });
    } else {
      setLoading(false);
      setVerified(false);
      swal('Oops!', res?.message || 'Something went wrong', 'error');
    }
  };

  const handleSubmit = async () => {
    console.log('mofad', modalAction);
    if (modalAction === 'createPin') {
      setCreatePinVerified(!createPinVerified);
    }
    if (modalAction === 'verifyPin') {
      validatePin();
    }
    if (modalAction === 'resetPin') {
      validatePin();
    }
    if (modalAction === 'changePassword') {
      setLoading(true);
      setChangePasswordVerified(!changePasswordVerified);
    }
    if (modalAction === 'createWallet') {
      validatePin();
    }
    if (modalAction === 'verifyTimeoutPin') {
      validatePin();
    }
  };

  const resendOtpForCreatePin = async (option, data) => {
    setShowPinVerify(false);
    if (validator?.isEmpty(option)) {
      setLoading(false);
      swal('Oops!', 'Select email or phone to proceed', 'error');
      return;
    }
    if (validator.isEmpty(profile?.loginId)) {
      setLoading(false);
      swal('Oops!', 'Business Id can not be null!', 'error');
      return;
    }
    setBusinessId(profile?.loginId);
    if (option === 'email') {
      // send otp to email (contains letters)
      const res = await sendResetPinOtpViaEmail(
        modalAction,
        data.email,
        profile?.loginId
      );
      if (res) {
        setShowResendOtp(false);
        setShowPinVerify(true);
        setIsValidatePin(true);
      }
    } else {
      const res = await sendResetPinOtpViaPhone(
        modalAction,
        data.phoneNumber,
        profile?.loginId
      );
      if (res) {
        setShowResendOtp(false);
        setShowPinVerify(true);
        setIsValidatePin(true);
      }
    }
  };

  const resendOtp = async (option, data) => {
    if (validator.isEmpty(option)) {
      setLoading(false);
      swal('Oops!', 'Select email or phone to proceed', 'error');
      return;
    }
    if (option === 'email') {
      // send otp to email (contains letters)
      const res = await sendResetPinOtpViaEmail(
        modalAction,
        data.email,
        profile?.loginId
      );
      if (res) {
        setShowResendOtp(false);
        setShowResetPin(true);
      }
    } else {
      const res = await sendResetPinOtpViaPhone(
        modalAction,
        data.phoneNumber,
        profile?.loginId
      );
      if (res) {
        setShowResendOtp(false);
        setShowResetPin(true);
      }
    }
  };

  const resendOtpForChangePassword = async (option, data) => {
    if (validator.isEmpty(option)) {
      setLoading(false);
      swal('Oops!', 'Select email or phone to proceed', 'error');
      return;
    }
    if (option === 'email') {
      // send otp to email (contains letters)
      const res = await sendResetPinOtpViaEmail(
        modalAction,
        data.email,
        profile?.loginId
      );
      if (res) {
        setShowResendOtp(false);
        setShowPinVerify(true);
        setIsValidatePin(true);
        setLoading(false);
        setOtp('');
      }
    } else {
      const res = await sendResetPinOtpViaPhone(
        modalAction,
        data.phoneNumber,
        profile?.loginId
      );
      if (res) {
        setShowResendOtp(false);
        setShowPinVerify(true);
        setIsValidatePin(true);
        setLoading(false);
        setOtp('');
      }
    }
  };

  const handleResendOtp = (option, data) => {
    console.log({ option, data });
    setLoading(true);
    setOtpType(option);
    setBusinessId(profile?.loginId);
    if (modalAction === 'createPin') {
      resendOtpForCreatePin(option, data);
    }
    if (modalAction === 'resetPin') {
      resendOtp(option, data);
    }
    if (modalAction === 'changePassword') {
      resendOtpForChangePassword(option, data);
    }
    setLoading(false);
  };

  const handleShowPinVerify = () => {
    setShowPinVerify(false);
    if (modalAction === 'verifyTimeoutPin') history.goBack();
  };

  return (
    <ModalContext.Provider
      value={{
        setCreateProfile,
        setShowPinVerify,
        showPinVerify,
        reload,
        setReload,
        verified,
        setVerified,
        setShowResendOtp,
        showResendOtp,
        setShowResetPin,
        setShowCreatePin,
        showCreatePin,
        setModalAction,
        modalAction,
        changePasswordVerified,
        setChangePasswordVerified,
        otp,
        otpType,
        setOtpType,
        setLoading,
        setIsValidatePin,
        businessId,
      }}
    >
      {children}
      {showClientModal ? (
        <Modal
          isOpen={showClientModal}
          toggle={() => setShowClientModal(false)}
          centered
        >
          <div className="modal-body-rs p-2 pb-3">
            <div className="waya-modal-body">
              <div className="inputbox-with-one-input-log mt-5">
                <div className="mb-5">
                  Please input the OTP sent to your email address or phone
                  number
                </div>
                {/* <input
                  placeholder="Client ID"
                  type="text"
                  value={businessId}
                  onChange={(e) => {
                    setBusinessId(e.target.value);
                  }}
                /> */}
              </div>
              <div className="submit-modal-btn-wrap-log">
                <Button
                  type="button"
                  loading={loading}
                  disabled={loading && false}
                  onClick={handleClientId}
                  content="Continue"
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
      {showPinVerify ? (
        <PinModal
          center
          showModal={showPinVerify}
          hideModal={handleShowPinVerify}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          numInputs={
            modalAction === 'createPin' || modalAction === 'changePassword'
              ? 6
              : 4
          }
          separator=""
          buttonLabel={
            modalAction === 'verifyTimeoutPin' ? 'Unlock' : 'Continue'
          }
          title={
            modalAction === 'createPin'
              ? messages.createPin.title
              : modalAction === 'changePassword'
              ? messages.changePassword.title
              : modalAction === 'verifyTimeoutPin'
              ? messages.verifyTimeoutPin.title
              : isValidatePin
              ? messages.verifyPin.title
              : messages.others.title
          }
          description={
            modalAction === 'createPin'
              ? messages.createPin.description
              : modalAction === 'changePassword'
              ? messages.changePassword.description
              : modalAction === 'verifyTimeoutPin'
              ? messages.verifyTimeoutPin.description
              : isValidatePin
              ? messages.verifyPin.description
              : messages.others.description
          }
          handleSubmit={handleSubmit}
          resendOtp={setShowResendOtp}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
          handleResendOtpto={handleResendOtp}
          modalAction={modalAction}
          isResendOtp={
            modalAction === 'createPin' ||
            modalAction === 'changePassword' ||
            modalAction === 'others' ||
            false
          }
          isResendPin={
            modalAction === 'verifyTimeoutPin' || isValidatePin || false
          }
          setShowResetPin={setShowResetPin}
        />
      ) : (
        ''
      )}
      {showResendOtp ? (
        <ResendOtp
          center="true"
          showModal={showResendOtp}
          hideModal={setShowResendOtp}
          title={modalAction === 'resetPin' ? '' : 'Confirm this is you'}
          description={
            modalAction === 'resetPin'
              ? 'Please select channel to receieve OTP'
              : 'Please select a channel to receive confirmation code'
          }
          handleSubmit={handleResendOtp}
          loading={loading}
        />
      ) : (
        ''
      )}
      {showResetPin ? (
        <ResetPin
          center
          showModal={showResetPin}
          hideModal={setShowResetPin}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 new digit PIN"
          handleResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
          otpType={otpType}
          businessId={businessId}
        />
      ) : (
        ''
      )}
      {showCreatePin ? (
        <SecurePin
          center
          showModal={showCreatePin}
          hideModal={setShowCreatePin}
          separator=""
          title="Create Secure PIN"
          description="Keep your account safe with a 4 digit PIN"
          buttonLabel="Continue"
          setShowResendOtp={setShowResendOtp}
          createPinVerified={createPinVerified}
          setCreatePinVerified={setCreatePinVerified}
          otp={otp}
          setOtp={setOtp}
          setShowPinVerify={setShowPinVerify}
          otpType={otpType}
          setOtpType={setOtpType}
          setLoading={setLoading}
          loading={loading}
          businessId={businessId}
          setCreateProfile={setCreateProfile}
        />
      ) : (
        ''
      )}
      {showCreateProfile ? (
        <Profile
          center="true"
          hideModal={setCreateProfile}
          showModal={showCreateProfile}
          mode="inside"
        />
      ) : (
        ''
      )}
    </ModalContext.Provider>
  );
};
