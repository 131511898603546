import React, { useContext, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
// import { AiOutlineCopy } from 'react-icons/ai';
import CopyToClipboard from 'react-copy-to-clipboard';
import './setting.scss';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { Col, Row } from '../../shared/containers/containers';
import InviteModal from '../../shared/Modals/inviteModal';
import { httpGet, customBaseUrl } from '../../../action/http';
import Copy from '../../../assets/image/u_copy-alt.svg';

export default function EditProfile() {
  const { userDetails, referralCode } = useContext(ProfileContext);
  // const { setActiveTab } = props;
  const [invite, setInvite] = useState(false);
  const [copied, setCopied] = useState(false);
  const [referalData, setReferalData] = useState({});
  const [referalAmount, setReferalAmount] = useState('');
  const history = useHistory();

  const getReferals = async () => {
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/admin/get-users-that-have-been-referred/${referralCode}`
    );
    if (res?.status) {
      setReferalData(res.data);
    }
    return [];
  };

  const getTotalReferalAmount = async () => {
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/admin/get-users-total-referral-earnings/${userDetails.userId}`
    );
    if (res && res.status) {
      setReferalAmount(res.data);
    }
    return [];
  };
  useEffect(() => {
    getTotalReferalAmount();
    getReferals();
  }, [userDetails?.userId]);

  return (
    <Route
      exact
      path="/settings/share-invite"
      component={() => (
        <div className="setting-inner-item">
          <div className="card p-4 mx-auto mt-5" id="accountSettings">
            <div>
              <div className="text-center my-5 head">Invite Friends</div>
              <div className="profile-detail mt-3">
                <Row align="flex-start">
                  <Col width="100%" align="flex-start">
                    <div className="text-center head">
                      Invite your friends and Earn
                    </div>
                    <div className="text-left">
                      Invite your friends and earn for yourself when they
                      complete registration and start performing transactions
                    </div>
                  </Col>
                  <Col
                    width="100%"
                    align="center"
                    justify="center"
                    style={{ pt: 2 }}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        width: '100%',
                        pl: 1,
                        py: 1,
                        pr: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      className="invite_card"
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ my: 2 }} variant="p">
                          Refferal Code
                        </Typography>
                        <Typography variant="h5">{referralCode}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <CopyToClipboard
                          text={referralCode}
                          onCopy={() => {
                            setCopied(true);
                            setTimeout(() => {
                              setCopied(false);
                            }, 1000);
                          }}
                        >
                          <div className="d-flex">
                            <img src={Copy} alt="" className="me-2" />
                            <div>
                              {copied === false ? 'Copy link' : 'Copied'}
                            </div>
                          </div>
                        </CopyToClipboard>
                      </Box>
                    </Card>
                    <Card
                      sx={{
                        display: 'flex',
                        width: '100%',
                        pl: 1,
                        py: 1,
                        pr: 1,
                        mt: 2,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      className="invite_card"
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ my: 2 }} variant="p">
                          Total Earning
                        </Typography>
                        <Typography variant="h5">{`N ${
                          referalAmount || 0.0
                        }`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <Typography sx={{ my: 2 }} variant="p">
                          Total Signups
                        </Typography>
                        <Typography variant="h5">
                          {' '}
                          {referalData?.referredUsers?.length || 0}
                        </Typography>
                      </Box>
                    </Card>
                    <Row width="100%" justify="flex-end" align="flex-end">
                      <Typography
                        color="#FF6700"
                        style={{ textDecoration: 'underline', marginTop: 20 }}
                        variant="p"
                        onClick={() => {
                          // setActiveTab('profile');
                          history.push('/referal-table');
                        }}
                      >
                        VIEW DETAILS
                      </Typography>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="log-out add-cursor flex-center1 sudo">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#FF6700',
                    width: '50%',
                    marginTop: 80,
                  }}
                  onClick={() => {
                    setInvite(true);
                  }}
                >
                  Share
                </Button>
              </div>
              <div style={{ height: 100 }} />
            </div>
          </div>
          {invite ? (
            <InviteModal
              center
              showModal={invite}
              separator=""
              buttonLabel="Continue"
              title="Invite Contact"
              description="Invite your friend to join you on Wayabank"
              hideModal={setInvite}
            />
          ) : (
            ''
          )}
        </div>
      )}
    />
  );
}
