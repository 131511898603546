import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Layout from '../../shared/Layout';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpGet } from '../../../action/http';
import { Pagination } from 'antd';
import moment from 'moment';

export default function ReferalTable() {
  const { referralCode } = useContext(ProfileContext);
  const [users, setUsers] = useState([]);
  const [metaData, setMetatData] = useState(0);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 20 });

  const getRefferals = async (page, size) => {
    const pages = page ? page : page === 0 ? 0 : pagination.current;
    const limit = size ? size : pagination.pageSize;
    console.log('resf', referralCode);
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/fetchReferralCustomers?pageNo=${
        pages + 1
      }&pageSize=${limit}`
    );
    console.log('res', res);
    setUsers(res?.data?.content);
    setMetatData(res?.data?.numberOfElements);
  };
  const handleChangePage = async (newPage, s) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage, pageSize: s });
    getRefferals(newPage, s);
  };
  const handleChangeRowsPerPage = async (page, size) => {
    getRefferals(0, size);
    setPagination({ ...pagination, current: 0, pageSize: size });
  };
  useEffect(() => {
    getRefferals();
  }, []);
  return (
    <Layout>
      <MaterialTable
        title="ReferalTable"
        columns={[
          { title: 'USER NAME', field: 'name' },
          { title: 'USER EMAIL', field: 'email' },
          // { title: 'CATEGORY', field: 'category' },
          { title: 'USER PHONE', field: 'phoneNumber' },
          {
            title: 'SIGN UP DATE',
            field: 'createdAt',
            render: (row) => moment(row?.createdAt).format('DD/MM/YYYY'),
          },
          {
            title: 'BUSINESS TYPE',
            field: 'businessType',
            render: (row) => row?.businessType ?? 'N/A',
          },
          {
            title: 'ORGANIZATION NAME',
            field: 'organizationName',
            render: (row) => row?.organizationName ?? 'N/A',
          },
        ]}
        data={users}
        options={{
          pageSize: pagination.pageSize,
          headerStyle: {
            backgroundColor: '#FF6700',
            color: '#FFF',
            zIndex: 0,
          },
        }}
        // actions={[
        //   {
        //     icon: 'save',
        //     tooltip: 'Save User',
        //     // onClick: (event, rowData) => alert("You saved " + rowData.name)
        //   },
        // ]}
      />
      <Pagination
        onShowSizeChange={handleChangeRowsPerPage}
        total={metaData}
        onChange={handleChangePage}
      />
    </Layout>
  );
}
