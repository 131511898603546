import React, { useState, useEffect, useContext } from 'react';
// import merge from 'react-merge';
// import _ from 'lodash';
import { customBaseUrl, httpGet } from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';
import Layout from '../../shared/Layout';
import CreateInvoice from './createInvoice';
import { Table } from 'antd';
import AntDropdown from '../../shared/Dropdown/antdDropdown';
import { hideLoader, showLoader } from '../../../utils/loader';
import InvoicePdf from '../Setting/invoicePdf';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';

export default function Invoice(props) {
  const {
    userDetails: { email, phoneNumber },
    profile: {
      address,
      // otherDetails: { organisationName },
    },
    BusinessName,
  } = useContext(ProfileContext);
  const [active, setActive] = useState('Create Invoice');

  const [status, setStatus] = useState('');
  const [data, setData] = useState({});
  const [teamMode, setTeamMode] = useState('table');
  const [selectedRow, setSelectedRow] = useState([]);
  const [showUpdateInvoice, setShowUpdateInvoice] = useState(false);

  const downloadInvoice = async (id) => {
    showLoader();
    const res = await httpGet(`
      ${customBaseUrl.invoiceUrl}/api/v1/wayabank/invoice-number?invoiceNumber=${id}
    `);

    if (res.status) {
      InvoicePdf(res?.data, {});
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const items = ['Create Invoice', 'My Invoice'];
  const handleDropdownAction = (item, row) => {
    console.log({ row });
    setSelectedRow(row);
    if (item === 'Download invoice') {
      downloadInvoice(row.invoiceNumber);
    } else if (item === 'Update invoice status') {
      setShowUpdateInvoice(true);
    }
  };
  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Buyers Name',
      dataIndex: 'buyerName',
      key: 'buyerName',
    },
    {
      title: 'Buyers Email',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Payment Due date',
      dataIndex: 'paymentDueDate',
      key: 'paymentDueDate',
    },
    {
      title: 'Invoice Status',
      dataIndex: 'invoiceStatus',
      key: 'invoiceStatus',
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
    },
    {
      title: 'Total Amount',
      dataIndex: 'sumTotalWithVAT',
      key: 'sumTotalWithVAT',
      render: (text, record) => new Intl.NumberFormat('en-US').format(text),
    },
    {
      title: 'VAT Amount',
      dataIndex: 'vatAmount',
      key: 'vatAmount',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: 'Action',
      dataIndex: 'bankName',
      key: 'bankName',
      render: (text, record) => (
        <AntDropdown
          dropDownOptions={['Update invoice status']}
          handleDropdownAction={(item) => handleDropdownAction(item, record)}
          isArrow
        />
      ),
    },
  ];

  const getInvoice = async () => {
    const res = await httpGet(
      `${customBaseUrl.invoiceUrl}/api/v1/wayabank/all/user-invoice`
    );

    if (res?.status) {
      // setTeam(res?.data);
      setData(res.data);
    }
  };
  const handleUpdateStatus = async (id) => {
    if (!status) return;
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.invoiceUrl}/api/v1/wayabank/update/invoice-status?invoiceNumber=${selectedRow?.invoiceNumber}&status=${status}`
    );

    if (res?.status) {
      hideLoader();
      swal(res?.message);
      setShowUpdateInvoice(false);
      getInvoice();
      // setTeam(res?.data);
    } else {
      hideLoader();
      swal('Oops!');
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <Layout route={props} currentPage="invoice">
      <Modal
        isOpen={showUpdateInvoice}
        toggle={() => setShowUpdateInvoice(false)}
      >
        <ModalHeader
          className="justify-content-center"
          style={{
            background: '#F1F7FA',
            fontFamily: 'Lucida Bright',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '28px',
            color: '#064A72',
            padding: '30px',
            borderRadius: '14px 14px 0 0 !important',
          }}
        >
          Change RoleUpdate invoice status
        </ModalHeader>
        <ModalBody
          className="text-center"
          style={{
            padding: '49px 55px 33px 55px',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '135.6%',
            color: '#4F4F4F',
          }}
        >
          <div className="mx-2 px-2 col">
            <label>Select invoice status</label>
            <div className="my-5 w-100">
              <select
                name=""
                id=""
                className="w-100"
                style={{
                  fontFamily: 'Lucida Bright',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '19px',
                  /* identical to box height */
                  color: '#828282',
                }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option> ... select</option>
                <option value="Unsettled">Unsettled</option>
                <option value="Settled">Settled</option>
                <option value="Cancelled">Cancel</option>
              </select>
            </div>
            <button onClick={handleUpdateStatus}>Update</button>
          </div>
        </ModalBody>
      </Modal>
      <div
        className="menu-options mt-3 w-100"
        style={{
          minHeight: '50px',
          position: 'relative',
          borderBottom: '1px solid #e5e5e5',
        }}
      >
        <div
          className="lato-text px-3 w-100"
          style={{
            color: '#b6b3b3',
            display: 'flex',
          }}
        >
          {items?.map((val) => (
            <div
              className={
                val === active ? 'active add-cursor me-5' : 'add-cursor me-5'
              }
              onClick={() => {
                setActive(val);
              }}
              key={Math.random()}
            >
              {val}
            </div>
          ))}
        </div>
      </div>

      <div
        className="bg-white mx-auto mt-5"
        // style={{
        //   boxSizing: 'border-box',
        //   boxShadow: '0px 0px 0px #E5E5E5',
        //   borderRadius: '4px',
        //   width: '85%',
        // }}
      >
        {active === 'My Invoice' ? (
          <div className="w-100 ">
            {teamMode === 'table' ? (
              <div className="border p-5">
                <div className="top w-100 d-flex mb-3 justify-content-between">
                  <div className="left">Invoice-{data?.length ?? 0}</div>
                </div>
                <Table dataSource={data ?? []} columns={columns} />;
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <CreateInvoice
            getInvoice={getInvoice}
            setActive={setActive}
            organisationName={BusinessName}
          />
        )}
      </div>
    </Layout>
  );
}
