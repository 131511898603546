import React, { useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import ChatMessage from './ChatMessage';
import { ChatHistoryContainer } from './styles';
import CustomDropdown from '../../shared/Dropdown';
import UnsavedContact from './UnknownContact';
import record from '../../../assets/images/record.png';
import arrowLeft from '../../../assets/images/arrowLeft.png';
import contactAvata from '../../../assets/images/contactAvata.png';
import video from '../../../assets/images/video.png';
import call from '../../../assets/images/call.png';
import chatUploadOptions from '../../../assets/images/chatUploadOptions.png';
import { chatHistoryOptions } from './data';
import { sort } from '../../../utils/helper';
import { getWayaProfile, handleBlockUser } from '../Wayagram/container';
import TransferModal from '../../shared/Modals/Transfer';

const ChatHistory = (props) => {
  const history = useHistory();
  const {
    data,
    user,
    handleSendMessage,
    setMessage,
    message,
    loggedInUser,
    handleUpload,
    deleteMessage,
    clearAllChatHistory,
  } = props;
  const [showTransferModal, setShowTransferModal] = useState(false);
  const sortedData = sort(data);
  const {
    otherDisplayName: contactName,
    toIdTyping: isTyping,
    // otherImageURL: avatar,
    otherId,
  } = user;
  const savedNumber = true;
  const [fromData, setFromData] = useState({});

  const toggleSendMoneyModal = () => {
    console.log('Toggle Send Money Modal......');
    setShowTransferModal(true);
  };

  const blockUser = async () => {
    const authId = loggedInUser.UserId;
    const res = await handleBlockUser(authId, user?.otherUserName);
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const handleOption = async (type, id) => {
    if (type === 'sendMoney') {
      const findUserId = await getWayaProfile(otherId);
      setFromData({ userId: findUserId.UserId, name: contactName });
      setShowTransferModal(true);
      toggleSendMoneyModal();
    }
    if (type === 'clearChat') {
      clearAllChatHistory();
    }
    if (type === 'block') {
      blockUser();
    }
    console.log({ type, id, otherId });
    console.log('hi');
  };

  return (
    <ChatHistoryContainer>
      <div className="chat-right-nav">
        <div className="chat-top-left">
          <img src={arrowLeft} alt="arrow" className="nav-arrow" />
          <img
            src={user.otherImageURL || contactAvata}
            alt="user"
            className="contact-avatar add-cursor"
            style={{ width: '25px', height: '25px', borderRadius: '13px' }}
            onClick={() => history.push(`/wayagram-profile/${otherId}`)}
          />
          <div
            className="contact-name add-cursor"
            onClick={() => history.push(`/wayagram-profile/${otherId}`)}
          >
            <p>{contactName}</p>
            <p className={isTyping ? 'status italic' : 'status'}>
              {isTyping ? 'is typing...' : 'Online'}
            </p>
          </div>
        </div>
        <div className="activity-group">
          <img src={video} alt="video" />
          <img src={call} alt="call" />
          <div
            className="option-div ml-auto my-auto"
            // style={{ fontSize: '24px', marginTop: '-12px', marginLeft: '26px' }}
          >
            <div className="dot-3x" style={{ position: 'relative' }}>
              <CustomDropdown
                id={12}
                direction="vertical"
                handleOption={handleOption}
                options={chatHistoryOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="chat-box-container">
        <div className="chat-box-content">
          {sortedData.length
            ? sortedData.map((item) => (
                <ChatMessage
                  key={Math.random()}
                  data={item}
                  loggedInUser={loggedInUser}
                  deleteMessage={deleteMessage}
                />
              ))
            : ''}
          {!savedNumber && <UnsavedContact />}
        </div>
        <div className="chat-input-container">
          <div className="input-container">
            <input
              placeholder="Write a message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              onKeyDown={handleSendMessage}
            />
            <img src={chatUploadOptions} alt="upload" className="chat-upload" />
            <input
              type="file"
              onChange={handleUpload}
              className="chat-upload chat-upload-input"
            />
          </div>
          <div className="send-button">
            <img src={record} alt="record" />
          </div>
        </div>
      </div>
      {showTransferModal ? (
        <TransferModal
          showModal={showTransferModal}
          hideModal={setShowTransferModal}
          id="success-wrapper"
          fromLocation="chats"
          fromData={fromData}
        />
      ) : (
        ''
      )}
    </ChatHistoryContainer>
  );
};

export default ChatHistory;
