import React, { useState, useEffect, useContext } from 'react';
import { Pagination } from '@mui/material';
import Table from '../../../shared/Table/DisputeTable';
import Layout from '../../../shared/Layout';
import DisputeWrapper from './style';
// import search from '../../../../assets/search.svg';
import Delete from '../../../../assets/image/delete.svg';
// import log from '../../../../assets/log.svg';
import './index.css';
import CreateDispute from './CreateDispute';
import {
  customBaseUrl,
  httpGet,
  // httpPostFormData,
} from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { ProfileContext } from '../../../../store/context/ProfileContext';
// import { dateFormater } from '../../../../utils/formatDate';
import Requests from '../dispute-comp/request/request';
import ViewMore from './ViewMore';

const Index = (props) => {
  const [showModal, hideModal] = useState(false);
  const [data, setData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [status, setStatus] = useState('');
  const [filteredObj, setFilteredObj] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [allDis, setAllDis] = useState(0);
  const [rej, setRej] = useState(0);
  const [resolve, setRes] = useState(0);
  const [rev, setRev] = useState(0);
  const [meta, setMeta] = useState(0);

  const { userDetails } = useContext(ProfileContext);

  const getDisputes = async () => {
    showLoader();
    const pages = page > 0 ? page - 1 : page;
    const limit = 10;
    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/users/${userDetails.userId}/disputes-ex?page=${pages}&size=${limit}`
    );
    // const url = `https://services.staging.wayabank.ng/complaint-service/disputes`;
    // const res = await httpGet(url);
    if (res.status) {
      console.log('req', res?.data?.disputes);
      setData(res?.data?.disputes);
      setMeta(res?.data?.totalItems);
    }
    hideLoader();
    console.log('dataDis', data);
  };

  const getStat = async () => {
    const allDisp = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-dispute/${userDetails.userId}`
    );
    const rejected = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-rejected/${userDetails.userId}`
    );
    const resolved = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-resolved/${userDetails.userId}`
    );
    const review = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-under_review/${userDetails.userId}`
    );
    setAllDis(allDisp);
    setRej(rejected);
    setRes(resolved);
    setRev(review);
    // const url = `https://services.staging.wayabank.ng/complaint-service/disputes`;
    // const res = await httpGet(url);
  };

  const handleFilter = (obj) => {
    setFilteredObj(obj);
  };
  const handleChange = (event, value) => {
    setPage(value);
    // eslint-disable-next-line
  };

  // // console.log('xxx>>>', data);
  // const createDisputeType = async () => {
  //   // const TypeData = {
  //   //   disputeTypeDescription: 'string',
  //   //   disputeTypeName: 'string',
  //   //   userId: 0,
  //   // };
  //   const fmd = new FormData();
  //   fmd.append('disputeTypeName', 'Socials');
  //   fmd.append('disputeTypeDescription', 'nothing yet');
  //   fmd.append('userId', 0);

  //   showLoader();
  //   const res = await httpPostFormData(
  //     '/api/v1/dispute/addDisputeType',
  //     fmd,
  //     customBaseUrl.disputeUrl
  //   );
  //   console.log('createDisputeType', res);
  //   if (res.status) {
  //     setData(res?.data?.content);
  //   }
  //   hideLoader();
  // };
  useEffect(() => {
    if (filteredObj) {
      console.log('filteredobj', filteredObj);
      if (
        (filteredObj.type && filteredObj.type !== '') ||
        (filteredObj.ticketId && filteredObj.ticketId !== '') ||
        (filteredObj.status && filteredObj.status !== '')
      ) {
        const d = data.filter(
          (x) =>
            x.category === filteredObj.type ||
            x.trackingNumber === filteredObj.ticketId ||
            x.status === filteredObj.status
        );
        setFiltered(d);
        console.log('filter2', filtered);
      } else {
        setFiltered(data);
        console.log('filter2', filtered);
      }
    } else {
      setFiltered(data);
      console.log('filter3', filtered);
    }
  }, [data, filteredObj]);

  console.log(setData);
  useEffect(() => {
    getStat();
    getDisputes();
    setFiltered(data);
  }, [userDetails.userId, page]);

  return (
    <Layout route={props} currentPage="dispute">
      {!viewMore && (
        <DisputeWrapper>
          <Requests
            refresh={refresh}
            data={data}
            LogClick={() => hideModal(true)}
            header={[
              { name: 'TRACKING NUMBER', key: 'trackingNumber' },
              { name: 'DATE', key: 'transactionDate' },
              { name: 'STATUS', key: 'status' },
              { name: 'ACTION', key: 'action' },
            ]}
            exportData={data}
            allDis={allDis}
            res={resolve}
            rev={rev}
            rej={rej}
            type={type}
            setType={setType}
            ticketId={ticketId}
            setTicketId={setTicketId}
            status={status}
            setStatus={setStatus}
            handleFilter={handleFilter}
          />
          <Table
            hideModal={hideModal}
            columns={[
              // { title: 'TRACKING NUMBER', field: 'trackingNumber' },
              // { title: 'DATE', field: 'transactionDate' },
              // { title: 'STATUS', field: 'status' },
              // { title: 'ACTION', field: 'action' },
              { title: 'TICKETID', field: 'trackingNumber' },
              { title: 'TYPE', field: 'disputeType' },
              // { title: 'CATEGORY', field: 'category' },
              { title: 'DURATION', field: 'transactionDate' },
              { title: 'STATUS', field: 'status' },
              { title: 'ACTION', field: 'action' },
            ]}
            data={
              filtered?.length &&
              filtered?.map((item) => {
                return {
                  trackingNumber: item?.trackingNumber,
                  disputeType: item?.disputeType,
                  transactionDate: new Date(
                    item?.ticketCategory?.createdAt
                  ).toLocaleDateString(),
                  status: item?.status,
                  action: (
                    <div className="d-flex align-content-center">
                      <span
                        style={{
                          background: '#FFFFFF',
                          border: '1px solid #D0CFCF',
                          boxSizing: 'border-box',
                          borderRadius: '3px',
                          padding: '5px 5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedData(item);
                          setViewMore(true);
                        }}
                      >
                        View More
                      </span>
                      <img
                        className="my-auto"
                        src={Delete}
                        alt=""
                        style={{
                          width: '16px',
                          height: '16px',
                          marginLeft: '25px',
                        }}
                      />
                    </div>
                  ),
                };
              })
            }
          />
          <Pagination
            count={Math.ceil(meta / 10)}
            page={page}
            onChange={handleChange}
          />
        </DisputeWrapper>
      )}
      <CreateDispute
        refresh={refresh}
        setRefresh={setRefresh}
        showModal={showModal}
        hideModal={hideModal}
        id="dispute-modal"
      />
      {viewMore && <ViewMore data={selectedData} setShow={setViewMore} />}
    </Layout>
  );
};

export default Index;
