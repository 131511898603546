import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from '../../../../shared/containers/containers';
import TableHeaders from '../../../../shared/tableHeaders/headers';
import TransactionStats, {
  TransactionCategory,
} from '../../../../shared/transactions-components/transactions';
import styles from '../styles.module.css';
import { httpGet, customBaseUrl } from '../../../../../action/http';
import { ProfileContext } from '../../../../../store/context/ProfileContext';

const Requests = ({
  allDis,
  res,
  rev,
  rej,
  LogClick,
  refresh,
  header,
  exportData,
  type,
  setType,
  ticketId,
  setTicketId,
  status,
  setStatus,
  handleFilter,
}) => {
  const { userDetails } = useContext(ProfileContext);

  const [data, setData] = useState({});

  const getDisputes = async () => {
    // const url = `https://services.staging.wayabank.ng/complaint-service/disputes`;
    // const res = await httpGet(url);
    const res1 = await httpGet(
      `${customBaseUrl.wayapayDispute}/users/${userDetails.userId}/disputes?page=0`
    );

    if (res1?.status) {
      const allDisputes = res1?.data?.userDisputes;

      const ALL_DISPUTE_COUNT = allDisputes?.length;
      const RESOLVED_DISPUTES = allDisputes?.filter(
        (element) => element.status === 'RESOLVED_DISPUTE'
      )?.length;
      const UNDER_REVIEW_DISPUTES = allDisputes?.filter(
        (element) => element.status === 'WAYAPAY_REVIEWING'
      )?.length;
      const DISPUTES_REJECTED = allDisputes?.filter(
        (element) => element.status === 'DISPUTE_REGECTED'
      )?.length;
      const PAYMENT_DISPUTES = allDisputes?.filter(
        (element) => element.category === 'Payment Dispute'
      )?.length;
      const SOCIAL_DISPUTES = allDisputes?.filter(
        (element) => element.category === 'Social Dispute'
      )?.length;
      const AUTH_DISPUTES = allDisputes?.filter(
        (element) => element.category === 'Auth & Notification'
      )?.length;
      const OTHER_DISPUTES = allDisputes?.filter(
        (element) => element.category === 'Other Disputes'
      )?.length;
      setData({
        ALL_DISPUTE_COUNT,
        RESOLVED_DISPUTES,
        DISPUTES_REJECTED,
        UNDER_REVIEW_DISPUTES,
        PAYMENT_DISPUTES,
        SOCIAL_DISPUTES,
        AUTH_DISPUTES,
        OTHER_DISPUTES,
      });
    }
  };

  useEffect(() => {
    getDisputes();
  }, [userDetails.userId, refresh]);

  return (
    <>
      <Col style={{ marginTop: 20 }} className={styles.TableHeaders}>
        <TableHeaders
          LogClick={LogClick}
          exportData={exportData}
          exportHeader={header}
          type={type}
          setType={setType}
          ticketId={ticketId}
          setTicketId={setTicketId}
          status={status}
          setStatus={setStatus}
          handleFilter={handleFilter}
        />
      </Col>
      <Row justify="space-between" className={styles.transactionAmount}>
        <TransactionStats
          color="#000000"
          name="All Dispute Logs "
          count={allDis}
        />
        <TransactionStats
          color="#05B862"
          name="Total Resolved Disputes "
          count={res}
        />
        <TransactionStats
          color="rgba(0, 0, 0, 0.6)"
          name="Total Disputes Under Review "
          count={rev}
        />
        <TransactionStats
          color="#FF6700"
          name="Total Dispute Rejected"
          count={rej}
        />
      </Row>
      <Row justify="space-between" className={styles.disputeTypes}>
        <TransactionCategory
          color="#000000"
          name="Payment Dispute"
          count={data?.PAYMENT_DISPUTES}
        />
        <TransactionCategory
          color="#000000"
          name="Social Dispute"
          count={data?.SOCIAL_DISPUTES}
        />
        <TransactionCategory
          color="000000"
          name="Auth & Notification"
          count={data?.AUTH_DISPUTES}
        />
        <TransactionCategory
          color="#000000"
          name="Other Disputes"
          count={data.OTHER_DISPUTES}
        />
      </Row>
    </>
  );
};

export default Requests;
