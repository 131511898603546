import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toJpeg } from 'html-to-image';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { Row, notification } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdOutlineContentCopy } from 'react-icons/md';
import moment from 'moment';
import './index.scss';
import CustomDropdown from '../../Dropdown';
// import logo from '../../../../assets/images/wayaBankLogo1.png';
import logo from '../../../../assets/newlog.svg';
import { capitalize } from '../../../../utils/helper';
import { customBaseUrl, httpGet } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import moneyFomatter from '../../../../utils/moneyFormatter';

const TransReceipt = React.forwardRef((props) => {
  const { showModal, hideModal, id, data, title, tab, maskingString } = props;
  const componentRef = useRef();
  const [receiptData, setReceiptData] = useState([]);
  const [pinD, setPinsD] = useState([]);
  const [copied, setCopied] = useState(false);
  // console.log('datadata', receiptData);y
  const getReceipt = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.withdrawalUrl}/withdrawal/trans-detail?paymentRef=${data['Reference Id']}`
    );
    if (res?.status) {
      hideLoader();
      setReceiptData({
        'Transaction Status': res?.data?.txnStatus,
        'Transaction Amount': `${moneyFomatter(Number(res?.data?.amount))}${
          res?.data?.currencyCode
        }`,
        Date: moment(res?.data?.txnDate).format('LLL'),
        'Reference Number': res?.data?.txnReference,
        'Transaction Type':
          data['Transaction Type'] === 'C' ? 'Credit' : 'Debit',
        'Reference Category': res?.data?.txnType,
        'Sender Name': res?.data?.sourceAccountName || '----',
        'Sender Account Number': maskingString(
          res?.data?.sourceAccountNumber,
          3,
          7
        ),
        'Receiver Name': res?.data?.destinationAccountName || '----',
        'Receiver Account Number': maskingString(
          res?.data?.destinationAccountNumber,
          3,
          7
        ),
        'Receiver Bank Name': res?.data?.destinationBankName,
        Narration: res?.data?.narration,
      });
      console.log(res?.data);
      console.log(copied);
    } else {
      hideModal(false);
      hideLoader();
    }
  };
  const getUtilityReceipt = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchTransactionByReference/{reference}?reference=${data['Reference Id']}`
    );
    if (res?.status) {
      const pins = [];
      if (res?.data?.pins.length > 0) {
        await res?.data?.pins?.map((item) =>
          pins.push({
            Pin: item?.pin,
            Serial: item?.serialNumber,
          })
        );
        setPinsD(pins);
      }
      hideLoader();
      setReceiptData({
        'Transaction Status': res?.data?.status,
        'Transaction Amount': `${moneyFomatter(Number(res?.data?.amount))}NGN`,
        Token: `${res?.data?.customerDataToken}` || '---',
        Pins: pins ?? '---',
        Date: moment(res?.data?.txnDate).format('LLL'),
        'Reference Number': res?.data?.paymentReferenceNumber,
        'Transaction Type':
          data['Transaction Type'] === 'C' ? 'Credit' : 'Debit',
        'Reference Category': res?.data?.categoryName,
        'Sender Name': res?.data?.senderName || '----',
        'Sender Account Number': maskingString(res?.data?.accountNumber, 3, 7),
        'Receiver Name': res?.data?.serviceProviderBiller?.name || '----',
        Narration: res?.data?.narration || '---',
      });
      console.log(res?.data);
    } else {
      hideModal(false);
      hideLoader();
    }
  };
  useEffect(() => {
    console.log('data', data);
    if (data['Transaction Category'] === 'WITHDRAW') {
      getReceipt();
    } else if (data['Transaction Category'] === 'UTILITY') {
      getUtilityReceipt();
    } else if (data?.Narration === 'BILLS_PAYMENT') {
      getUtilityReceipt();
    } else {
      setReceiptData(data);
    }
  }, []);
  const shareImage = () => {
    toJpeg(document.getElementById('qr-body')).then((dataUrl) => {
      // const link = document.createElement('a');
      // link.download = 'profile_code.jpeg';
      // link.href = dataUrl;
      const description = 'Receipt';
      window.open(
        `https://wa.me/?text=${description}%0D%0A${dataUrl}`,
        '_blank'
      );
    });
  };

  const downloadPdf = () => {
    html2canvas(document.getElementById('qr-body')).then((canvas) => {
      const imageCanvas = canvas.toDataURL('images/png');

      const invoicePDF = new JSPDF('p', 'cm', [7, 14]);
      // invoicePDF.addImage(imageCanvas, 'PNG', 0, 0);
      invoicePDF.addImage(imageCanvas, 'PNG', 0.5, 0.5, 6, 12);
      invoicePDF.save('transaction_receipt.pdf');
    });
  };

  const handleOption = async (type) => {
    if (type === 'download') {
      // exportComponentAsPDF(componentRef);
      downloadPdf();
    } else if (type === 'save') {
      exportComponentAsJPEG(componentRef, { fileName: 'transaction_receipt' });
      // downloadDiv();
    } else if (type === 'share') {
      shareImage();
    }
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader
        className="text-center border-0"
        // toggle={() => hideModal(false)}
      >
        <h5 className="justify-content-center">{'  '}</h5>
        <div className="ml-auto" style={{ marginTop: '12px' }}>
          <div className="dot-3x" style={{ position: 'relative' }}>
            <CustomDropdown
              id={12}
              direction="vertical"
              handleOption={handleOption}
              options={[
                { name: 'Print', type: 'download', id: 1 },
                { name: 'Save', type: 'save', id: 2 },
                // { name: 'Share', type: 'share', id: 2 },
                { name: 'Cancel', type: 'cancel', id: 3 },
              ]}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="body-wrapper m-5 p-0" id="qr-body">
        <div ref={componentRef}>
          <div className="top text-center my-4">
            <img
              src={logo}
              alt="waya"
              style={{ height: '30px', marginTop: '20px' }}
            />
            <h4>{title}</h4>
          </div>
          <div
            className="middle mx-auto"
            style={{ width: '90%', fontSize: '10px' }}
          >
            <table className="table table-borderless">
              <tbody>
                {receiptData &&
                  Object.keys(receiptData).map((keyName) =>
                    keyName === 'Date' ? (
                      <tr key={keyName}>
                        {/* <div
                          key={keyName}
                          className="d-flex justify-content-between mb-3"
                        >
                          <p className="text-secondary">Transaction Date:</p>
                          <p>{receiptData.Date}</p>
                        </div> */}
                        <td>Transaction Date</td>
                        <td>{receiptData.Date}</td>
                      </tr>
                    ) : keyName === 'Pins' ? (
                      pinD?.length > 0 ? (
                        <tr>
                          <td>Pins</td>
                          <td>
                            {Object?.keys(pinD)?.map((e) => (
                              <tr key={e}>
                                <td>
                                  <Row>
                                    <span className="ms-2">
                                      Pin: {pinD[e]?.Pin ?? pinD[e]?.pin}
                                    </span>
                                    <span
                                      style={{
                                        color: '#828282',
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <CopyToClipboard
                                        text={pinD[e]?.Pin ?? pinD[e]?.pin}
                                        onCopy={() => {
                                          setCopied(true);
                                          notification.success('copied');
                                          setTimeout(() => {
                                            setCopied(false);
                                          }, 1000);
                                        }}
                                      >
                                        <span className="d-flex">
                                          <MdOutlineContentCopy size={10} />
                                          {/* <img src={Copy} alt="" className="me-2" /> */}
                                          {/* <div>{copied === false ? '' : 'Copied'}</div> */}
                                        </span>
                                      </CopyToClipboard>
                                    </span>
                                  </Row>
                                </td>
                                {pinD[e]?.Serial || pinD[e]?.serialNumber ? (
                                  <td>
                                    Serial:
                                    {pinD[e]?.Serial ?? pinD[e]?.serialNumber}
                                  </td>
                                ) : (
                                  ''
                                )}
                              </tr>
                            ))}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )
                    ) : keyName === 'Token' ? (
                      receiptData?.Token?.length > 0 &&
                      receiptData?.Token !== 'null' ? (
                        <tr>
                          <td>Token</td>
                          <td>
                            <Row>
                              <span className="ms-2">{receiptData?.Token}</span>
                              <span
                                style={{
                                  color: '#828282',
                                  marginLeft: 5,
                                  cursor: 'pointer',
                                }}
                              >
                                <CopyToClipboard
                                  text={receiptData?.Token}
                                  onCopy={() => {
                                    setCopied(true);
                                    notification.success('copied');
                                    setTimeout(() => {
                                      setCopied(false);
                                    }, 1000);
                                  }}
                                >
                                  <span className="d-flex">
                                    <MdOutlineContentCopy size={20} />
                                    {/* <img src={Copy} alt="" className="me-2" /> */}
                                    {/* <div>{copied === false ? '' : 'Copied'}</div> */}
                                  </span>
                                </CopyToClipboard>
                              </span>
                            </Row>
                          </td>
                        </tr>
                      ) : (
                        ''
                      )
                    ) : (
                      receiptData[keyName] !== '' &&
                      receiptData[keyName] !== '----' && (
                        <tr>
                          {/* <div
                          className="d-flex justify-content-between mb-3"
                          key={Math.random()}
                        >
                          {/* <p className="text-secondary">{capitalize(keyName)}:</p>
                          <p>{receiptData[keyName]}</p> */}
                          <td>{capitalize(keyName)}: </td>
                          <td>{receiptData[keyName]}</td>
                        </tr>
                      )
                    )
                  )}
              </tbody>
            </table>
          </div>
          {!tab && (
            <div className="bottom p-4 text-center">
              <p>Note</p>
              <p>
                You&lsquo;ll be able to dispute his transaction after 24 hours.
              </p>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
});

TransReceipt.defaultProps = {
  id: '',
};

TransReceipt.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default TransReceipt;
