import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
// import { Button } from 'antd';
// import { MenuOutlined } from '@ant-design/icons';
// import { NavbarMenu } from './style';
import { handleSetWalletDefault } from '../../../store/calls';
import { WalletContext } from '../../../store/context/WalletContext';
import Toggle from '../Toggle';
import CustomDropdown from '../Dropdown';
import { ProfileContext } from '../../../store/context/ProfileContext';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
import ProfileMenu, { Actions } from './profileMenu';
import Profile from '../Modals/Profile';
// import { customBaseUrl, httpGet } from '../../../action/http';

const mapStateToProps = (state) => {
  return { selectedWallet: state.wallet };
};
const ConnectedWayaNavbar = ({
  history,
  setShowManageBankAccount,
  setShowCreateWallet,
  setShowManageCard,
  setShowManageWallet,
  selectedWallet,
}) => {
  const [toggleState, setToggleState] = useState(false);
  const location = useLocation();
  const [showProfile, setShowProfile] = useState(false);
  const [showProfileUpdate, setShowProfileUpdate] = useState(false);
  const { pathname } = location;
  const {
    defaultWallet: currentWallet,
    reload,
    setReload,
  } = useContext(WalletContext);
  const {
    profile: { profileImage },
    reload: { reloadP },
    profile,
  } = useContext(ProfileContext);
  const handleOption = async (type) => {
    if (type === 'manageBankAccount') {
      setShowManageBankAccount(true);
    } else if (type === 'createAccount') {
      setShowCreateWallet(true);
    } else if (type === 'manageCard') {
      setShowManageCard(true);
    } else if (type === 'manageWallet') {
      setShowManageWallet(true);
    } else if (type === 'contacts') {
      history.push('/contacts?p=payment');
    }
  };

  const handleWalletDefault = async (change) => {
    setToggleState(change);
    await handleSetWalletDefault(selectedWallet?.accountNo);
    setReload(!reload);
  };
  useEffect(() => {
    if (
      profile.otherDetails?.organizationAddress === '' ||
      profile.otherDetails?.organizationCity === '' ||
      profile.otherDetails?.organizationState === '' ||
      (profile.cacBusiness && profile?.cacRegistrationNumber === '')
    ) {
      setShowProfileUpdate(true);
    } else {
      setShowProfileUpdate(false);
    }
  }, [profile]);
  return (
    <div className="topbar">
      {pathname === '/web-pos' && (
        <div className="toggle-switch">
          <Toggle
            text="Test Mode"
            onChange={handleWalletDefault}
            checked={selectedWallet?.walletDefault || false}
            position="back"
          />
        </div>
      )}
      {showProfileUpdate ? (
        <div className="d-flex" style={{ color: 'white' }}>
          <p className="my-0 mx-2 text-primary" style={{ color: 'red' }}>
            You need to{' '}
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setShowProfile(true);
              }}
            >
              complete
            </a>{' '}
            your profile
          </p>
        </div>
      ) : (
        ''
      )}
      {pathname === '/wallet' && (
        <>
          <div className="toggle-switch flex-basis-4 mr-5">
            <Toggle
              text="Default account"
              onChange={handleWalletDefault}
              checked={selectedWallet?.walletDefault || false}
              position="front"
            />
            <div
              className="dot-3x"
              style={{ position: 'relative', marginLeft: 55 }}
            >
              <CustomDropdown
                id={12}
                handleOption={handleOption}
                options={[
                  {
                    name: 'Create new wallet',
                    type: 'createAccount',
                    id: '1',
                  },
                  {
                    name: 'Account settings',
                    type: 'accountSettings',
                    id: '1',
                  },
                  { name: 'Manage Card', type: 'manageCard', id: '1' },
                  { name: 'Manage Bank', type: 'manageBankAccount', id: '1' },
                  { name: 'Manage Account', type: 'manageWallet', id: '1' },
                  // { name: 'Contacts', type: 'contacts', id: '1' },
                  { name: 'Cancel', type: 'cancel', id: '1' },
                ]}
              />
            </div>
          </div>
        </>
      )}
      <div className="">
        <Actions />
      </div>
      <div className="">
        <ProfileMenu
          name="Richard Daniel"
          merchantId="HVdmOcctiHg2nN"
          email="merchantemail@example.com"
          profileImage={profileImage}
          imgPlaceholder={imgPlaceholder}
        />
      </div>
      {showProfile ? (
        <Profile
          center="true"
          hideModal={setShowProfile}
          showModal={showProfile}
        />
      ) : (
        ''
      )}
    </div>
  );
};

const WayaNavbar = connect(mapStateToProps)(ConnectedWayaNavbar);

export default WayaNavbar;
