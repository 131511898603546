import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet, httpPost } from '../../action/http';

export const WalletContext = createContext();

export const WalletContextProvider = (props) => {
  const { children } = props;
  const [walletList, setWalletList] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [reload, setReload] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [metaData, setMetaData] = useState(0);
  const [debitLimit, setDebitLimit] = useState('');

  const getWalletList = async (userId, profileId) => {
    setWalletLoading(true);
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}/${profileId}`
    );

    if (res && res?.status) {
      setWalletList(res.data);
      if (res?.data?.length) {
        const result = res?.data?.filter((item) => item?.walletDefault);
        /* const commission = res?.data?.filter((el) =>
          el.accountNo.startsWith('90')
        );
        setDefaultWallet(result[0]); */
        //  commission?.length <= 0 ? createCommissionWallet(userId) : '';
      }
      setWalletLoading(false);
    }
  };
  const getTransactionLimit = async (userId, profileId) => {
    setWalletLoading(true);
    const res = await httpPost(
      `/api/v1/wallet/getCustomerDebitLimit?profileId=${profileId}&userId=${userId}`,
      {},
      customBaseUrl.walletUrl
    );
    if (res?.body?.status) {
      setDebitLimit(res?.body?.data);
      setWalletLoading(false);
    }
  };
  // const userId = localStorage.getItem('userId');
  /* const createCommissionWallet = async (userId) => {
    const res = await httpPost(
      `/api/v1/wallet/create/commission_account/${userId}`,
      {},
      customBaseUrl.walletUrl
    );

    if (res && res?.status) {
      console.log(res?.message);
    } else {
      console.log('Commission wallet error', res?.message);
    }
  }; */
  const getTransactionHistory = async (page, size) => {
    console.log('fff', page);
    const pages =
      page > 0 ? page - 1 : page === 0 ? 0 : page < 0 ? 0 : currentPage;
    const limit = size ? size : pageSize;
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/transactions/${defaultWallet?.accountNo}?page=${pages}&size=${limit}`
    );

    if (res.status) {
      setTransactionHistory(res.data.content);
      setMetaData(res?.data?.totalElements);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const profileId = localStorage.getItem('profileId');

    getWalletList(userId, profileId);
    getTransactionLimit(userId, profileId);
    // getTransactionHistory();
  }, []);

  useEffect(() => {
    if (defaultWallet?.accountNo) {
      getTransactionHistory(0, pageSize);
    }
  }, [pageSize, defaultWallet?.accountNo]);

  return (
    <WalletContext.Provider
      value={{
        setWalletList,
        walletList,
        reload,
        setReload,
        setCurrentPage,
        metaData,
        walletLoading,
        setWalletLoading,
        transactionHistory,
        setPageSize,
        pageSize,
        setDefaultWallet,
        defaultWallet,
        getTransactionHistory,
        debitLimit,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
