import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { notification } from 'antd';
import swal from 'sweetalert';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { customBaseUrl, httpGet } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import CurrencyInput from 'react-currency-input';
import NumberFormat from 'react-number-format';

export default function PayToAccount(props) {
  const { data, setData, walletList, requestType, getCharges } = props;
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    if (data.senderAccountNumber && data.amount) {
      getCharges(data.senderAccountNumber, data.amount);
    }
  }, [data]);

  // const getDetails = async (acctNo) => {
  //   const res = await httpGet(
  //     `${customBaseUrl.temporalservice}/api/v1/wallet/account/${acctNo}`
  //   );
  //   console.log('res', res);
  //   if (res.status) {
  //     setData({
  //       ...data,
  //       recipientName: res?.data?.accountName,
  //       receiverAccount: res?.data?.accountNo,
  //     });
  //   } else {
  //     setData({ ...data, recipientName: '' });
  //   }
  //   console.log('Charges', res?.data?.receiverAccount);
  // };
  const getDetails = async (receiverAccount) => {
    //  if (!data?.receiverAccount) return swal('Account No can not be null');
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/fetchUserByAccountNo/${receiverAccount}`
    );
    showLoader();
    if (res?.status) {
      hideLoader();
      notification.success(res?.message);
      setData({
        ...data,
        receiverAccount,
        beneficiaryName: res?.data?.accountName,
        receiverProfileId: res?.data?.profileId,
        receiverEmail: res?.data?.email,
        receiverPhoneNumber: res?.data?.phoneNumber,
        receiverName: res?.data?.accountName,
        receiverId: res?.data?.userId,
      });
    } else {
      hideLoader();
      setData({
        ...data,
        receiverProfileId: '',
        receiverEmail: '',
        receiverPhoneNumber: '',
        receiverId: '',
        receiverName: '',
      });
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
    return res;
  };
  return (
    <ModalBody>
      <div className="text-center mt-3 mb-3 ">Pay to Waya Account No</div>
      <form className="mx-auto mt-3">
        {/* <div className="inputbox-with-one-input">
          <select
            name="filter"
            className=""
            value={requestType}
            onChange={(e) => {
              setRequestType(e.target.value);
            }}
          >
            <option value="Select" key="select" selected disabled>
              Select User Type
            </option>
            <option value="wayaUser">Waya User</option>
            <option value="nonWayaUser">Non Waya User</option>
          </select>
          <span className="input-detail">Select Recipient Type</span>
        </div> */}

        <div className="inputbox-with-one-input">
          <input
            placeholder="Recipent Account No."
            type="text"
            onChange={(e) => {
              if (e.target.value.length === 10) {
                getDetails(e.target.value);
              } else {
                setData({ ...data, receiverName: '' });
              }
            }}
          />
          <span className="input-detail">Recipent Account No.</span>
        </div>
        <div className="inputbox-with-one-input">
          <input disabled value={data.receiverName} />
        </div>
        <div className="inputbox-with-one-input">
          {/* <CurrencyInput
            id="input-amount"
            name="input-amount"
            placeholder="Amount"
            defaultValue={0.0}
            value={data.amount}
            decimalsLimit={2}
            onChangeEvent={(e) => setData({ ...data, amount: e?.target.value })}
          /> */}
          <NumberFormat
            placeholder="Amount"
            thousandSeparator
            decimalScale="2"
            fixedDecimalScale
            value={data.amount}
            onChange={(e) => setData({ ...data, amount: e?.target.value })}
          />
          <span className="input-detail">How much do you want to Send?</span>
        </div>

        {requestType === 'nonWayaUser' && (
          <div className="inputbox-with-one-input">
            <input
              placeholder="Full name"
              type="text"
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
            <span className="input-detail">Name of recipent</span>
          </div>
        )}

        <div className="inputbox-with-one-input">
          <input
            placeholder="Add a Note"
            type="text"
            value={data.reason}
            onChange={(e) => {
              setData({ ...data, reason: e.target.value });
            }}
          />
          <span className="input-detail">Add descriptive notes</span>
        </div>

        <div className="inputbox-with-one-input">
          <select
            name="filter"
            className=""
            defaultValue={data.senderAccountNumber}
            onChange={(e) => {
              const [acccountNo, amount] = e.target.value.split(',');
              setData({
                ...data,
                balance: amount,
                senderAccountNumber: acccountNo,
              });
              setBalance(amount);
            }}
          >
            <option value="Select" selected disabled>
              Select
            </option>
            {walletList.length
              ? walletList
                  .filter((el) => !el.accountNo.startsWith('90'))
                  .map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item?.accountNo}
                    >
                      {`${item?.description} - ${item?.accountNo} (${
                        item.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
              : ''}
          </select>
          <span className="input-detail">
            Your Balance is N{balance ? moneyFomatter(balance) : 0}.00
          </span>
        </div>
      </form>
    </ModalBody>
  );
}
