import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import Button from '../../shared/Button';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { hideLoader, showLoader } from '../../../utils/loader';
// import { ProfileContext } from '../../../store/context/ProfileContext';

const UpdateRole = (props) => {
  const { showModal, hideModal, privilleges, getCustom, selectedRole } = props;
  // const {
  //   profile: { userId },
  // } = useContext(ProfileContext);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [priv, setPriv] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [roleData, setRoleData] = useState({
    permissions: [],
  });

  const handleToggle = (value) => () => {
    const currentIndex = roleData.permissions.findIndex(
      (item) => item.id === value.id
    );
    const newChecked = [...roleData.permissions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRoleData({ ...roleData, permissions: newChecked });
  };
  const handleAddRole = async () => {
    // const Priviledgedata = {
    //   default : true
    //   description: chipdat
    //   name
    // }

    // const arrayOfObj = Object.entries(chipData).map(([key, value]) => ({
    //   id: key,
    //   value,
    // }));
    const data = {
      description,
      name,
      permissions: chipData,
    };

    console.log('chipD', chipData);
    console.log('dataaa', roleData);
    showLoader();
    const res = await httpPost(
      `/api/v1/roles/${selectedRole.id}/permission`,
      roleData.permissions,
      customBaseUrl.roleUrl
    );
    if (res.status !== false) {
      swal('Done', res.message, 'success');
      hideModal();
      hideLoader();
      getCustom();
    } else {
      swal('Oops!', res.message, 'error');
      hideModal();
      hideLoader();
      getCustom();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      size="md"
    >
      <ModalHeader
        className="justify-content-center"
        style={{
          background: '#F1F7FA',
          fontFamily: 'Lucida Bright',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '28px',
          color: '#064A72',
          padding: '30px',
          borderRadius: '14px 14px 0 0 !important',
        }}
      >
        Add Permissions to {selectedRole.name}
      </ModalHeader>
      <ModalBody
        style={{
          padding: '49px 50px 33px 50px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '135.6%',
          color: '#4F4F4F',
        }}
      >
        <div className="my-3 w-100">
          <div className="mb-1">Select Permissions</div>
        </div>
        {privilleges?.map((value, index) => {
          return (
            <div key={index} className="col-md-12 row">
              <ListItem key={index} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        roleData.permissions.findIndex(
                          (item) => item.id === value.id
                        ) > -1
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': value.description,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={value.id} primary={value.description} />
                </ListItemButton>
              </ListItem>
            </div>
          );
        })}
        <Button
          content="Add role"
          onClick={handleAddRole}
          style={{
            width: '100%',
            marginTop: '50px',
          }}
        />
        <div
          className="text-primary mt-2 text-center"
          onClick={() => {
            hideModal(false);
          }}
        >
          Cancel
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateRole;
