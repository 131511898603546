import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { toJpeg } from 'html-to-image';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import CustomDropdown from '../../Dropdown';
import '../FundWallet/index.scss';
import Container from '../../Container';
import ScanToReceive from './ScanToReceive';
import RequestPayment from './RequestPayment';
import { ProfileContext } from '../../../../store/context/ProfileContext';

const Index = (props) => {
  const { showModal, hideModal, id } = props;
  const [mode, setMode] = useState('requestPayment');
  const [showQrCode, setShowQrCode] = useState(false);
  const { profile } = useContext(ProfileContext);
  const [qrData, setQrData] = useState({
    email: profile.email,
    firstName: profile.firstName,
    surname: profile.surname,
    userId: profile.userId,
    amount: '',
  });

  const downloadDiv = () => {
    toJpeg(document.getElementById('qr-body')).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'profile_code.jpeg';
      link.href = dataUrl;
      link.click();
    });
  };

  const downloadPdf = () => {
    html2canvas(document.getElementById('qr-body')).then((canvas) => {
      const imageCanvas = canvas.toDataURL('images/png');

      const invoicePDF = new JSPDF();
      invoicePDF.addImage(imageCanvas, 'PNG', 0, 0);
      invoicePDF.save('profile_code.pdf');
    });
  };

  const handleOption = async (type) => {
    if (type === 'download') {
      downloadDiv();
    } else if (type === 'save') {
      downloadPdf();
    } else if (type === 'cancel') {
      hideModal(false);
    }
  };

  return (
    <Modal
      className="mx-auto mt-3"
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id={id}
      centered
    >
      <ModalHeader className="text-center qr-body">
        <h5
          className={mode === 'requestPayment' ? 'justify-content-center' : ''}
        >
          Receive Funds
        </h5>
        <div
          className="ml-auto"
          style={
            mode === 'requestPayment'
              ? { display: 'none' }
              : { marginTop: '12px' }
          }
        >
          <div
            className={showQrCode ? 'dot-3x' : 'd-none'}
            style={{ position: 'relative' }}
          >
            <CustomDropdown
              id={12}
              direction="vertical"
              handleOption={handleOption}
              options={[
                { name: 'Download', type: 'download', id: 1 },
                { name: 'Save', type: 'save', id: 2 },
                { name: 'Cancel', type: 'cancel', id: 3 },
              ]}
            />
          </div>
        </div>
      </ModalHeader>
      <div className="text-center fund-tab-wrap pb-2 pb-3">
        <div className="row m-0 fund-tab">
          <Container
            className={
              mode === 'requestPayment' ? 'col fund-tab-active' : 'col'
            }
            onClick={() => setMode('requestPayment')}
          >
            Retrieve Payment
          </Container>
          <Container
            className={mode === 'scanToReceive' ? 'col fund-tab-active' : 'col'}
            onClick={() => setMode('scanToReceive')}
          >
            Scan to Receive
          </Container>
        </div>
      </div>
      {mode === 'requestPayment' ? (
        <RequestPayment hideModal={hideModal} />
      ) : mode === 'scanToReceive' ? (
        <ScanToReceive
          showQrCode={showQrCode}
          setShowQrCode={setShowQrCode}
          data={qrData}
          setData={setQrData}
          hideModal={hideModal}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
