// /* eslint-disable no-undef */
// /* eslint-disable jsx-a11y/alt-text */
// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import 'react-multi-carousel/lib/styles.css';
import Layout from '../../shared/Layout';
import { MomentWrapper } from './style';
import './index.scss';
import GramCard from '../../shared/Cards/Gram';
import {
  customBaseUrl,
  httpDelete,
  httpPost,
  httpPut,
} from '../../../action/http';
import PostModal from '../../shared/Modals/Post';
import CreatePostModal from '../../shared/Modals/Post/CreatePost';
import { UploadPost } from './GramUtils';
import { WayagramContext } from '../../../store/context/WayagramContext';
import {
  handleBookmark,
  handleBlockUser,
  handleFollow,
  handleRepost,
  handleToggleMute,
  handleGetBookmark,
} from './container';
import RightSidebar from './RightSidebar';
import Pagination from '../../shared/Pagination/index';

export default function Index(props) {
  const [feeds, setFeeds] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { wayagramProfile, wayagramProfileId, setReload, reload } =
    useContext(WayagramContext);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(1);

  const handleShowPostModal = async (data) => {
    setShowCreatePost(true);
    setSelectedData(data);
  };

  const getAllInfo = async () => {
    const allBookmarks = await handleGetBookmark(
      wayagramProfileId,
      pageNo,
      pageSize
    );
    setFeeds(allBookmarks?.data?.data || []);
  };

  useEffect(() => {
    if (wayagramProfileId) {
      getAllInfo();
    }
    return () => {
      setPageSize(10);
      setPageNo(1);
    };
  }, [wayagramProfileId, reload, pageNo]);

  const handleDeletePost = async (postId) => {
    const data = {
      post_id: postId,
    };

    const res = await httpDelete(
      `/all-posts/delete-bookmark?profile_id=${wayagramProfileId}`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setFeeds(feeds.filter((item) => item.Post.id !== postId));
    }
    return {};
  };

  const handleEditPost = async (data) => {
    const postData = {
      description: data.description,
      profile_id: wayagramProfile.id,
      post_id: data.postId,
      isPoll: false,
      isPaid: false,
      forceTerms: false,
    };

    const res = await httpPut(
      `/all-posts/update`,
      postData,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setShowCreatePost(false);
        getAllInfo();
      });
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const handleLikePost = async (postId) => {
    const data = {
      post_id: postId,
      profile_id: wayagramProfile.id,
      type: 'user',
    };
    const res = await httpPost(
      '/all-posts/like-post',
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setReload(true);
      swal('Done', res.message, 'success');
    }
  };

  const apiAlertResponse = (res) => {
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const addBookmark = async (postId) => {
    const profileId = wayagramProfile.id;
    const res = await handleBookmark(profileId, postId);
    apiAlertResponse(res);
  };

  const blockUser = async (username) => {
    const authId = wayagramProfile.UserId;
    const res = await handleBlockUser(authId, username);
    apiAlertResponse(res);
  };

  const handleFollowUser = async (username) => {
    const authId = wayagramProfile.UserId;
    const res = await handleFollow(authId, username);
    apiAlertResponse(res);
  };

  const repostPost = async (postId) => {
    const profileId = wayagramProfile.id;
    console.log({ postId, profileId });
    const res = await handleRepost(originalPostId, profileId, comment);
    apiAlertResponse(res);
  };

  const handleMuteUser = async (username) => {
    const authId = wayagramProfile.UserId;
    const res = await handleToggleMute(authId, username);
    apiAlertResponse(res);
  };

  return (
    <Layout route={props} currentPage="wayagram">
      {/* <div className="dashboard-two-col-wrap wayagram-div"> */}
      <div className="row wayagram-div">
        <div className="col-8">
          <MomentWrapper className="row m-2">
            <div className="moment-header">Post</div>
            <div className="mt-0">
              <Pagination
                dataLength={feeds.length}
                setPageNo={setPageNo}
                pageNo={pageNo}
              >
                {feeds.map((post) => (
                  <GramCard
                    key={post.id}
                    data={post.Post}
                    handleDeletePost={handleDeletePost}
                    showPostModal={handleShowPostModal}
                    handleLikePost={handleLikePost}
                    addBookmark={addBookmark}
                    blockUser={blockUser}
                    handleFollowUser={handleFollowUser}
                    repostPost={repostPost}
                    handleMuteUser={handleMuteUser}
                    fromLocation="bookmarks"
                  />
                ))}
              </Pagination>
            </div>
          </MomentWrapper>
          <UploadPost showPost={setShowPostModal} />
        </div>

        <RightSidebar />
      </div>
      {setShowPostModal ? (
        <PostModal
          showModal={showPostModal}
          hideModal={setShowPostModal}
          set={setReload}
        />
      ) : (
        ''
      )}
      {showCreatePost ? (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="edit"
          handleSubmit={handleEditPost}
          type="user"
          selectedData={selectedData}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
