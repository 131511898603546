import React from 'react';
import { Card, Button, Row, Col } from 'antd';
import Layout from '../../shared/Layout';
import Api from '../../../assets/image/Api.png';
import Contact from '../../../assets/image/Contact.png';
import whatsapp from '../../../assets/image/whatsapp.png';
const SupportPage = (props) => {
  const handleContact = () => {
    window.location = 'https://wayabank.ng/contact';
  };

  return (
    <div
      style={{
        backgroundColor: '#fff1ed',
        height: 270,
      }}
    >
      <Layout route={props} currentPage="support">
        <div className="d-flex justify-content-center">
          <h2>Support</h2>
        </div>
        <Row gutter={16}>
          <Col gutter={8}>
            <Card
              className="d-flex justify-content-center my-2"
              style={{
                width: 300,
              }}
            >
              <div className="d-flex justify-content-center">
                <img src={Api} style={{ width: 70 }} alt="" />
              </div>
              <h5 className="d-flex justify-content-center my-2">
                Api Documentation
              </h5>
              <div className="d-flex justify-content-center">
                <p>
                  Integrate with our Api <br /> documentation
                </p>
              </div>
              <Button
                type="primary"
                onClick={() => props.history.push('/activity-log')}
                danger
              >
                View Doc
              </Button>
            </Card>
          </Col>
          <Col gutter={8}>
            <Card
              style={{
                width: 300,
              }}
              className="d-flex justify-content-center my-2"
            >
              <div className="d-flex justify-content-center">
                <img src={Contact} style={{ width: 70 }} alt="" />
              </div>
              <h5 className="d-flex justify-content-center my-2">Contact us</h5>
              <p>
                Contact us via email or phone <br /> number for support
              </p>
              <Button type="primary" onClick={handleContact} danger>
                Explore
              </Button>
            </Card>
          </Col>
        </Row>
        <a
          href="https://api.whatsapp.com/send/?phone=2348066252349&text=Hello Wayabank Admin I have a question for you."
          className="d-flex justify-content-end align-items-end p-5"
        >
          <img src={whatsapp} style={{ width: 70 }} />
        </a>
      </Layout>
    </div>
  );
};

export default SupportPage;
