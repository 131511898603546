import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinModal from '../PinModal';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { ModalContext } from '../../../../store/context/ModalContext';
import Button from '../../Button';

const Index = (props) => {
  const { showModal, hideModal, id, nibssUrl } = props;
  const { profile, verifiedEmailReload, setVerifiedEmailReload } =
    useContext(ProfileContext);
  const { setShowResendOtp, setModalAction } = useContext(ModalContext);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmOtp, setShowConfirmOtp] = useState(false);
  const [showBvnY, setShowBvnY] = useState(false);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [bvnCode, setBvnCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResendOtp, resendOtp] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [externalPopup, setExternalPopup] = useState(null);

  const handleLinkBvn = async () => {
    const postData = {
      bvn: bvnCode,
      dob: dob || profile.dateOfBirth,
      firstName: profile.firstName,
      lastName: profile.surname,
      userId: profile.userId,
      phoneNumber,
    };
    const res = await httpPost(`/bvn/linkBvn`, postData, customBaseUrl.bvnUrl);
    if (res.status) {
      setLoading(false);
      swal('Done', `${res.message}`, 'success').then(() => {
        if (
          res.message ===
          'BVN linking was successful, check your phone for OTP to validate your BVN'
        ) {
          setShowConfirmOtp(true);
          return;
        }
        hideModal(false);
        setVerifiedEmailReload(!verifiedEmailReload);
      });
    } else {
      setLoading(false);
      if (
        res.message ===
        'Bvn has already been added to this user Pls Validate your OTP'
      ) {
        setShowConfirmOtp(true);
        return;
      }
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    if (res.status) {
      setShowPin(false);
      handleLinkBvn();
      setOtp('');
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const resendBvnOtp = async () => {
    setLoading(true);
    const res = await httpPost(
      `/bvn/resendOTP/${profile.userId}`,
      {},
      customBaseUrl.bvnUrl
    );
    if (res.status) {
      setLoading(false);
      setOtp('');
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const connectClick = (e) => {
    /*  const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth — width) / 2;
    const top = window.screenY + (window.outerHeight — height) / 2.5;*/
    const title = `WINDOW TITLE`;
    console.log(nibssUrl[0]);
    console.log('http', nibssUrl);

    const url = nibssUrl;
    const popup = window.open(
      url,
      title,
      `width=${500},
      height=${400},
      left=${200},
      top=${200}`
    );
    setExternalPopup(popup);
  };
  useEffect(() => {
    connectClick();
    if (isResendOtp) resendBvnOtp();
  }, [isResendOtp]);
  /*  useEffect(() => {
    if (!externalPopup) {
      return;
    }

      const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      console.log('urll', currentUrl);
      const searchParams = new URL(currentUrl).searchParams;
      // const code = searchParams.get('code');
      /*  if (code) {
        externalPopup.close();
        console.log(`The popup URL has URL code param = ${code}`);
        YourApi.endpoint(code)
          .then(() => {
            // change UI to show after the code was stored
          })
          .catch(() => {
            // API error
          })
          .finally(() => {
            // clear timer at the end
            setExternalPopup(null);
            timer && clearInterval(timer);
          });
      }/*
    }, 5000);
  }, [externalPopup]);*/
  const validateBvnOtp = async () => {
    setLoading(true);
    const res = await httpPost(
      `/bvn/validateBvn/${profile.userId}/${otp}`,
      {},
      customBaseUrl.bvnUrl
    );
    if (res.status) {
      swal('Done', `${res.message}`, 'success').then(() => {
        setLoading(false);
        setVerifiedEmailReload(!verifiedEmailReload);
        setOtp('');
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  useEffect(() => {
    if (
      phoneNumber === '' ||
      dob === '' ||
      bvnCode === '' ||
      bvnCode.length < 11 ||
      bvnCode.length > 11
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [phoneNumber, dob, bvnCode]);
  return (
    <Modal isOpen={false} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Link BVN
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <p
            className="text-center text-primary mb-4 bvn-why"
            onClick={() => setShowBvnY(!showBvnY)}
          >
            Why we need your bvn ?
            <div
              className="bvn-info-div text-dark"
              style={!showBvnY ? { display: 'none' } : {}}
            >
              <h4>Why we need your BVN ?</h4>
              <p className="text-primary bvn-reason">
                Linking your BVN doesn’t give WAYA access to your bank
                information or balances.
              </p>

              <p>We only to need to access to your:</p>
              <p className="text-center mb-0">Full Name</p>
              <p className="text-center mb-0">Phone Number</p>
              <p className="text-center mb-0">Date of Birth</p>
            </div>
          </p>
          <div className="inputbox-with-one-input">
            <input
              placeholder="BVN (11 digits)"
              type="number"
              onChange={(e) => setBvnCode(e.target.value)}
            />
            <span className="input-detail">
              Enter your 11 digits BVN Number
            </span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              placeholder="Phone number"
              type="number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <span className="input-detail">
              This has to be the phone number tied to your BVN
            </span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              placeholder="Enter date of birth"
              type="date"
              onChange={(e) => setDob(e.target.value)}
            />
            <span className="input-detail">Enter date of birth</span>
          </div>

          {/* <div className="inputbox-with-one-input">
            <input placeholder="Enter Verification Code" type="text" />
            <span className="input-detail">
              Enter verification code sent to you
            </span>
          </div> */}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-5 mt-2"
          color="primary"
          loading={loading}
          disabled={(loading ? true : null) || disabled}
          onClick={() => {
            setShowPin(true);
          }}
          style={{ opacity: disabled ? 0.5 : 1 }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleVerifyPin}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}

      {showConfirmOtp ? (
        <PinModal
          center
          showModal={showConfirmOtp}
          hideModal={setShowConfirmOtp}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your otp"
          description="Please input your 6 digit otp to confirm bvn"
          handleSubmit={validateBvnOtp}
          isResendOtp
          resendOtp={resendOtp}
          numInputs={6}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
