import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Navbar from './navbar';
import Sidebar from './sidebar';
import './layout.scss';
// import MobileSidebar from './mobileSidebar';
import { ChildWrapper } from './style';

export default function Index(props) {
  const history = useHistory();
  const locations = useLocation();
  const { children, bgColor, currentPage, pageName, goBack, showName } = props;
  return (
    <>
      {/* <Navbar history={routeHistory.history} currentPage={currentPage} /> */}
      {props?.doNot === locations.pathname ? (
        <></>
      ) : (
        <Navbar
          history={history}
          currentPage={currentPage}
          pageName={pageName}
          goBack={goBack}
          showName={showName}
        />
      )}
      <Sidebar currentPage={currentPage} history={history} />
      <ChildWrapper bgColor={bgColor}>
        <Scrollbars autoHide>
          <div className="layout-container">{children}</div>
        </Scrollbars>
      </ChildWrapper>
      {/* <MobileSidebar currentPage={currentPage} /> */}
    </>
  );
}

Index.defaultProps = {
  bgColor: '',
};

Index.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string.isRequired,
};
