import React from 'react';

import { Modal, ModalBody, Button } from 'reactstrap';
import './update.scss';
import Container from '../../shared/Container';

const KYCUploadMode = (props) => {
  const { showModal, hideModal, uploadMode, setUploadMode } = props;
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      {/* <ModalHeader>
        <h5 className="modal-header-data"> VNINs are replacing NINs! </h5>
      </ModalHeader> */}
      <ModalBody>
        <div>
          <Container>
            <div
              className="pl-3 py-2 border rounded p-3"
              onChange={(e) => setUploadMode(e.target.value)}
            >
              <div className="d-flex mb-0 flex-row justify-content-between">
                <div>Instant Kyc</div>
                <div>
                  <input
                    style={{ cursor: 'pointer' }}
                    // disabled
                    type="radio"
                    value="auto"
                    name="uploadmode"
                    checked={uploadMode === 'auto'}
                  />
                </div>
              </div>
              <div className="border rounded mt-2 bg-light p-1">
                <h6>
                  Submit your required documents and get verified instantly.
                  This means you can start using our services right away.
                </h6>
              </div>
            </div>
          </Container>
          <Container>
            <div
              className="pl-3 py-2 border rounded p-3 mt-3"
              onChange={(e) => setUploadMode(e.target.value)}
            >
              <div className="d-flex mb-0 flex-row justify-content-between">
                <div>Manual Veification</div>
                <div>
                  <input
                    style={{ cursor: 'pointer' }}
                    type="radio"
                    value="manual"
                    name="uploadmode"
                    checked={uploadMode === 'manual'}
                  />
                </div>
              </div>
              <div className="border rounded mt-2 bg-light p-1">
                <h6>
                  By chosing manual verification, it will take about 2 to 48
                  hours for us to verify your documents. Our KYC verification
                  team will have to review each document submited.
                </h6>
              </div>
            </div>
          </Container>
          <div className="text-center m-4">
            <Button
              style={{ width: '200px' }}
              color="primary"
              onClick={() => hideModal(false)}
            >
              Proceed
            </Button>
          </div>
        </div>
        {/* <ModalFooter>
          <Button color="secondary" onClick={() => hideModal(false)}>
            Proceed
          </Button>
        </ModalFooter> */}
      </ModalBody>
    </Modal>
  );
};

export default KYCUploadMode;
