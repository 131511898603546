import React, { useState, useContext } from 'react';
import moment from 'moment';

// import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { GramCard } from './style';
import Report from '../Modals/ReportAndDispute';
import commentIcon from '../../../assets/image/message-icon.png';
import paidPostIcon from '../../../assets/images/paidPostIcon.png';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
import CustomDropdown from '../Dropdown';
import MakeDonationModal from '../Modals/Post/MakeDonation';
import DonationProgress from '../Modals/Post/DonationModal/DonationProgress';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { WalletContext } from '../../../store/context/WalletContext';
import OtherDropdown from '../Dropdown/OtherDropdown';
import dateToFromNowDaily, { dateToNow } from '../../../utils/formatDate';
import PayForProduct from '../Modals/Post/PayForProduct';
import { objectToGetParams, whatsappLink } from '../ShareButton';
import truncateWithEllipses from '../../../utils/truncate';

export default function Gram(props) {
  const { wayagramProfile: userProfile } = useContext(WayagramContext);
  const { defaultWallet } = useContext(WalletContext);
  const profileId = userProfile.id;
  const [showReportModal, setShowReportModal] = useState(false);
  const {
    data,
    handleDeletePost,
    showPostModal,
    handleLikePost,
    addBookmark,
    blockUser,
    handleFollowUser,
    handleMuteUser,
    fromLocation,
  } = props;
  const userId = data.ProfileId;
  const profile = data?.profile || data?.Profile;
  const {
    description,
    likeCount,
    commentCount,
    repostCount,
    id: postId,
    PostImages,
    isLiked,
    isRepost,
    kind,
    Donation,
    paidPostProfile,
    Poll,
    createdAt,
    isPaid,
    isPoll,
  } = data;

  const [selectedOption, setSelectedOption] = useState();
  const [likes, setlikes] = useState(likeCount);
  const [repost, setRepost] = useState(repostCount);
  const [isReposted, setIsReposted] = useState(false);
  const [onIsLiked, setOnIsLiked] = useState(false);
  const [selectedLikedPostId, setSelectedLikedPostId] = useState(null);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [showPayProduct, setShowPayProduct] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const history = useHistory();
  const myRef = React.useRef(null);

  const handleOption = async (type, id) => {
    if (type === 'editPost') {
      const selectedData = {
        postId: id,
        description,
      };
      showPostModal(selectedData);
    } else if (type === 'bookmark') {
      addBookmark(postId);
    } else if (type === 'deletePost') {
      handleDeletePost(id);
    } else if (type === 'reportUser') {
      // handleDeletePost(id);
      setShowReportModal(true);
    } else if (type === 'blockUser') {
      blockUser(profile.username);
    } else if (type === 'followUser') {
      handleFollowUser(profile.username);
    } else if (type === 'muteUser') {
      handleMuteUser(profile.username);
    }
  };

  const handleSubmit = () => {};

  return (
    <>
      <GramCard ref={myRef} className="p-3">
        <div className="top-content d-flex">
          <div className="d-flex">
            <img
              src={profile?.avatar || imgPlaceholder}
              alt="gram avatar"
              className="me-3"
              style={{ height: '42px', width: '42px', borderRadius: ' 50%' }}
              onClick={() => history.push(`/wayagram-profile/${userId}`)}
            />
            <div className="me-3 mb-2">
              <p
                className="mb-0 add-cursor"
                aria-hidden="true"
                onClick={() => history.push(`/wayagram-profile/${userId}`)}
              >
                {`${profile?.displayName || ''}`}
              </p>
              <p className="mb-0 text-muted">@{profile?.username}</p>
            </div>
          </div>
          <div className="option-div ml-auto my-auto">
            <div className="dot-3x" style={{ position: 'relative' }}>
              <CustomDropdown
                id={12}
                direction="horizontal"
                handleOption={handleOption}
                options={
                  fromLocation === 'bookmarks'
                    ? [{ name: 'Delete', type: 'deletePost', id: postId }]
                    : profileId === userId
                    ? [
                        { name: 'Edit Post', type: 'editPost', id: postId },
                        { name: 'Bookmark', type: 'bookmark', id: postId },
                        { name: 'Delete Post', type: 'deletePost', id: postId },
                      ]
                    : [
                        { name: 'Bookmark', type: 'bookmark', id: postId },
                        { name: 'Report User', type: 'reportUser', id: postId },
                        { name: 'Block User', type: 'blockUser', id: postId },
                        { name: 'Follow User', type: 'followUser', id: postId },
                        { name: 'Mute User', type: 'muteUser', id: postId },
                      ]
                }
              />
            </div>
          </div>
        </div>
        <div className="middle-content">
          <div className="middle-content-description">
            {kind === 'donation' ? (
              <div className="Donation-description-box mt-3 wl-100">
                <div className="Donation-text">{Donation?.title}</div>
                <div>
                  <div>
                    <div className="img-div">
                      <img src={Donation?.imageUrl} alt="pic" />
                    </div>
                  </div>
                  <div className="Donation-progress mt-3">
                    {`${Donation?.currentAmount} raised of ${Donation?.estimatedAmount}`}
                  </div>
                  <DonationProgress
                    current={Donation?.currentAmount}
                    total={Donation?.estimatedAmount}
                  />
                </div>
              </div>
            ) : (
              <div className="mb-3 mt-3">
                <p>{description || ''}</p>
                {/* {poll && poll.map((item) => (
                <div>

                  <p>{item}</p>
                </div>
              ))} */}
              </div>
            )}
          </div>

          {PostImages?.length && PostImages.length < 2 ? (
            <div>
              <div className="img-div">
                <img src={PostImages[0]?.imageURL} alt="pic" />
              </div>
            </div>
          ) : (
            ''
          )}
          {PostImages?.length > 1 ? (
            <div>
              <div className={`image-grid-div-${PostImages?.length}`}>
                {PostImages.map((item, index) => (
                  <div
                    className="image-each"
                    key={Math.random()}
                    style={{
                      backgroundImage: `url(${item.imageURL})`,
                      gridArea: `grid${index + 1}`,
                      position: 'relative',
                      borderRadius: '4px',
                    }}
                  />
                  // </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
          {isPaid && (
            <div>
              <div
                className={`image-grid-div-${paidPostProfile?.PostImages?.length}`}
              >
                {PostImages?.length &&
                  PostImages.map((item, index) => (
                    <div
                      className="image-each"
                      key={Math.random()}
                      style={{
                        backgroundImage: `url(${item})`,
                        gridArea: `grid${index + 1}`,
                        position: 'relative',
                        borderRadius: '4px',
                      }}
                    />
                    // </div>
                  ))}
              </div>

              <div className="d-flex mt-1 px-4">
                <img
                  src={paidPostIcon}
                  alt="paid post"
                  style={{ width: '2.2em', height: '1.7em' }}
                />
                <p className="mx-3">Amount: ####</p>
              </div>
            </div>
          )}
          {isPoll && (
            <div className="">
              <div className="d-flex flex-column my-2">
                {(Poll?.options || []).map((item, index) => (
                  <div
                    className="form-check my-2 d-flex align-items-center"
                    key={Math.random()}
                  >
                    <input
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '25px',
                      }}
                      className="form-check-input"
                      type="radio"
                      name={item}
                      value={item}
                      id={index}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      checked={selectedOption === item}
                    />
                    <label className="form-check-label" htmlFor={index}>
                      {item}
                    </label>
                  </div>
                ))}
              </div>

              <div className="d-flex text-black-50">
                {Poll?.expiresIn ? (
                  <div className=" me-3">
                    {`${dateToNow(moment(parseFloat(Poll.expiresIn)))} left`}
                  </div>
                ) : (
                  ''
                )}

                {Poll?.amount ? (
                  <div className=" me-3">`N${Poll?.amount} per Vote`</div>
                ) : (
                  ''
                )}

                <div className=" me-3">
                  {`${Object.values(Poll?.Votes)?.reduce(
                    (total, value) => total + value,
                    0
                  )} votes`}
                </div>
              </div>
            </div>
          )}
        </div>

        <span className="text-muted">
          {/* {moment(createdAt).format('dddd, MM DD YYYY')} */}
          {dateToFromNowDaily(createdAt)}
        </span>
        <div className="bottom-content">
          <div className="bottom-content-container">
            <div className="comment-div">
              <img
                src={commentIcon}
                alt="comment"
                onClick={() => history.push(`/post/${postId}`)}
                style={{ width: '1em', height: '1em', cursor: 'pointer' }}
              />
              <span className="ms-2">{commentCount}</span>
            </div>
            <div className="comment-div">
              <i
                className={
                  isLiked || (onIsLiked && selectedLikedPostId === postId)
                    ? 'mdi mdi-heart text-danger'
                    : 'mdi mdi-heart-outline text_text add-cursor'
                }
                role="button"
                tabIndex={0}
                aria-hidden="true"
                onClick={() => {
                  if (likes >= likeCount + 1) {
                    setlikes(likes - 1);
                    setOnIsLiked(false);
                    return;
                  }
                  setlikes(likes + 1);
                  setOnIsLiked(true);
                  setSelectedLikedPostId(postId);
                  handleLikePost(postId);
                }}
              />
              <span className="ms-2">{likes}</span>
            </div>
            <div className="comment-div">
              <i
                className={
                  isRepost || isReposted
                    ? 'mdi mdi-twitter-retweet text-success'
                    : 'mdi mdi-twitter-retweet'
                }
                role="button"
                tabIndex={0}
                aria-hidden="true"
                onClick={() => {
                  if (repost >= repostCount + 1) {
                    setRepost(repost - 1);
                    setIsReposted(false);
                    return;
                  }
                  setRepost(repost + 1);
                  setIsReposted(true);
                  // repostPost(postId);
                }}
              />
              <span className="ms-2">{repost}</span>
            </div>
            <div className="comment-div" style={{ position: 'relative' }}>
              <i
                className="mdi mdi-share-variant"
                role="button"
                tabIndex={0}
                aria-hidden="true"
                onClick={() => {
                  setShowSharePost(!showSharePost);
                  setSelectedPostId(postId);
                }}
              />
              {showSharePost && postId === selectedPostId ? (
                <OtherDropdown
                  hide={setShowSharePost}
                  options={[
                    { name: 'Share to Twitter', type: 'twitter' },
                    { name: 'Share to Facebook', type: 'facebook' },
                    { name: 'Send in Whatsapp', type: 'whatsapp' },
                    { name: 'Send in Mail', type: 'mail' },
                  ]}
                  postId={postId}
                  handleClick={(item) => {
                    const url = `${process.env.REACT_APP_FRONTEND}/post/${postId}?from=shared`;
                    const shareText = description
                      ? truncateWithEllipses(description, 30)
                      : '';
                    if (item === 'whatsapp') {
                      window.open(
                        // `https://wa.me/?text=${description}%0D%0A${url}`,
                        whatsappLink(url, shareText),
                        '_blank'
                      );
                    } else if (item === 'twitter') {
                      window.open(
                        `https://twitter.com/share${objectToGetParams({
                          url,
                          text: shareText,
                        })}`,
                        '_blank'
                      );
                    } else if (item === 'facebook') {
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php${objectToGetParams(
                          {
                            u: url,
                            quote: shareText,
                            hashtag: '#wayapaychat',
                          }
                        )}`,
                        '_blank'
                      );
                    } else if (item === 'mail') {
                      window.open(
                        `mailto:${objectToGetParams({
                          subject: 'share post',
                          body: shareText
                            ? `${shareText} Check out this post ${url}`
                            : url,
                        })}`
                      );
                    }
                  }}
                />
              ) : (
                ''
              )}
            </div>
            {/* {isPaid !== 'free' ? (
            <div
              className="donation-button"
              onClick={() => setShowDonationModal(true)}
            >
              {kind === 'donation'
                ? 'Donate'
                : kind === 'shop'
                ? 'Make Payment'
                : kind === 'poll'
                ? 'Vote'
                : ''}
            </div>
          ) : (
            ''
          )} */}
            {kind === 'donation' && (
              <div
                className="donation-button"
                onClick={() => setShowDonationModal(true)}
              >
                Donate
              </div>
            )}
            {isPaid === true && (
              <div
                className="donation-button"
                style={{
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setShowPayProduct(true)}
              >
                Make Payment
              </div>
            )}
          </div>
        </div>
        {showDonationModal ? (
          <MakeDonationModal
            showModal={showDonationModal}
            hideModal={setShowDonationModal}
            userData={userProfile}
            donationId={Donation.id}
            data={data}
          />
        ) : (
          ''
        )}
        <PayForProduct
          handleSubmit={handleSubmit}
          values={data}
          defaultWallet={defaultWallet}
          showModal={showPayProduct}
          hideModal={setShowPayProduct}
        />
      </GramCard>
      {showReportModal ? (
        <Report
          showModal={showReportModal}
          hideModal={setShowReportModal}
          title="Report User"
          reported={profile}
          type="User"
          reporter={userProfile}
        />
      ) : (
        ''
      )}
    </>
  );
}

Gram.propTypes = {
  data: PropTypes.shape.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  showPostModal: PropTypes.func.isRequired,
  handleLikePost: PropTypes.func.isRequired,
};
