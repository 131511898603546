import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import moment from 'moment';
import swal from 'sweetalert';
import validator from 'validator';
import { FiChevronDown } from 'react-icons/fi';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPut,
} from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import PinModal from '../PinModal';
import SingleRequest from './SingleRequest';
// import placeholder from '../../../../assets/image/p-placeholder.png';
import truncateWithEllipses from '../../../../utils/truncate';
import { WalletContext } from '../../../../store/context/WalletContext';
import { PaymentContext } from '../../../../store/context/PaymentContext';
import { ModalContext } from '../../../../store/context/ModalContext';
import TransReceipt from '../Receipt/TransReceipt';

export default function AllPayment(props) {
  // const { hideModal, sentRequest, receivedRequest } = props;
  const {
    hideModal,
    receivedRequest,
    sentRequest,
    getSentPaymentRequest,
    getReceivedPaymentRequest,
  } = props;

  const { request, totalItems } = sentRequest || {};
  const { request: receivedPaymentRequest, totalItems: totalRecieved } =
    receivedRequest || {};
  const [mode, setMode] = useState('sent');
  const [otp, setOtp] = useState('');
  const [verificationOtp, setVerificationOtp] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRequestDetails, setShowRequestDetails] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [reference, setReference] = useState('');
  const [selected, setSelected] = useState('');
  const { setReload, reload } = useContext(WalletContext);
  const { setPaymentReload, reload: paymentReload } =
    useContext(PaymentContext);
  const { setModalAction, setShowResendOtp } = useContext(ModalContext);
  const [notes, setNotes] = useState('');

  // console.log('request', sentRequest);
  const handleSettlePaymentRequest = async () => {
    showLoader();

    if (validator.isEmpty(reference)) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }

    const res = await httpPost(
      `/settle-payment-request-waya-users/${reference}/${otp}`,
      {},
      customBaseUrl.paymentRequestUrl,
      otp
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        setShowPin(false);
        setShowVerifyOtp(true);
        // hideModal(false);
        // setReload(!reload);
        // setPaymentReload(!paymentReload);
      });
    } else {
      if (
        res.message === 'PIN validation failed: Referrence Code Already Exist'
      ) {
        setShowPin(false);
        setOtp('');
        setShowVerifyOtp(true);
        return;
      }
      hideLoader();
      setOtp('');
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleCompletePaymentRequest = async () => {
    showLoader();

    if (validator.isEmpty(verificationOtp)) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }

    const res = await httpPost(
      `/completePaymentWithOTP/${reference}/${verificationOtp}`,
      {},
      customBaseUrl.paymentRequestUrl,
      otp
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        setShowVerifyOtp(false);
        hideModal(false);
        setReload(!reload);
        setPaymentReload(!paymentReload);
        setOtp('');
      });
    } else {
      hideLoader();
      setOtp('');
      swal('Oops!', res.message || 'Something went wrong', 'error');
      hideModal(false);
    }
  };

  const handleReject = async () => {
    showLoader();
    const res = await httpPut(
      `/reject-payment-request/${reference}`,
      {
        note: notes,
      },
      customBaseUrl.paymentRequestUrl
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        hideModal(false);
        setPaymentReload(!paymentReload);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      swal('Done', `PIN verified`, 'success').then(() => {
        setLoading(false);
        setShowPin(false);
        handleReject();
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleSubmit = () => {
    if (selected === 'settle') {
      handleSettlePaymentRequest();
      return;
    }
    handleVerifyPin();
  };
  const handlePaginate = (type = 'sent') => {
    const userId = localStorage.getItem('userId');
    if (type === 'sent') getSentPaymentRequest(userId, totalItems);
    else getReceivedPaymentRequest(userId, totalRecieved);
  };
  useEffect(() => {
    console.log('data', request);
  }, [request]);
  return (
    <>
      <ModalBody>
        <div className="sub-header">
          <div className="row text-center">
            <div
              className={
                mode === 'sent'
                  ? 'col-6 sub-text active-bottom'
                  : 'col-6 sub-text'
              }
              onClick={() => setMode('sent')}
            >
              Sent Request <small>{totalItems}</small>
            </div>
            <div
              className={
                mode === 'received'
                  ? 'col-6 sub-text active-bottom'
                  : 'col-6 sub-text'
              }
              onClick={() => setMode('received')}
            >
              Received Request{' '}
              <small
                style={{
                  background: '#FF6634',
                  borderRadius: '50%',
                  padding: '2px 5px',
                  color: '#fff',
                }}
              >
                {totalRecieved}
              </small>
            </div>
          </div>
        </div>

        <div className="" style={{ paddingTop: 7 }}>
          {mode === 'sent' && (
            <>
              {request?.length
                ? request?.map((item) => (
                    <div
                      className="transaction-details request-details mb-3 list"
                      key={item.reference}
                      onClick={() => {
                        setDisplayData({
                          From: item.senderName,
                          Phone: `+${item.receiverPhoneNumber}`,
                          Amount: item.amount,
                          Reason: item.reason,
                          Date: moment([item.createdAt]).format(
                            'MMMM Do, YYYY'
                          ),
                          Time: moment([item.createdAt]).format('h:mm a'),
                          status: item.status,
                        });
                        setReference(item.reference);
                        setShowReceipt(true);
                      }}
                    >
                      <div className="detail-items">
                        <div className="me-3">
                          <h6 className="mb-0">
                            {truncateWithEllipses(item.reason, 20)}
                          </h6>
                          <small>To {item.receiverName}</small>
                        </div>
                        <div className="text-right">
                          <h5 className="mb-0">N{item.amount}.00</h5>
                          <small
                            className={
                              item.status === 'PENDING'
                                ? 'text-warning'
                                : item.status === 'PAID'
                                ? 'text-success'
                                : 'text-danger'
                            }
                          >
                            {item.status.toLowerCase()}
                          </small>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
              {totalItems > request?.length && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePaginate()}
                >
                  <FiChevronDown />
                </div>
              )}
            </>
          )}
          {mode === 'received' && (
            <>
              {receivedPaymentRequest?.length
                ? receivedPaymentRequest.map((item) => (
                    <div
                      className="transaction-details request-details mb-3 add-cursor list"
                      key={item.reference}
                      onClick={() => {
                        setDisplayData(
                          item?.rejectionNote
                            ? {
                                From: item.senderName,
                                Phone: `+${item.receiverPhoneNumber}`,
                                Amount: item.amount,
                                Reason: item.reason,
                                Date: moment([item.createdAt]).format(
                                  'MMMM Do YYYY'
                                ),
                                Time: moment([item.createdAt]).format('h:mm a'),
                                status: item.status,
                                Note: item?.rejectionNote ?? '',
                              }
                            : {
                                From: item.senderName,
                                Phone: `+${item.receiverPhoneNumber}`,
                                Amount: item.amount,
                                Reason: item.reason,
                                Date: moment([item.createdAt]).format(
                                  'MMMM Do YYYY'
                                ),
                                Time: moment([item.createdAt]).format('h:mm a'),
                                status: item.status,
                              }
                        );
                        setReference(item.reference);
                        if (
                          item.status === 'PENDING' ||
                          item.status === 'FAILED'
                        ) {
                          setShowRequestDetails(true);
                        } else {
                          setShowReceipt(true);
                        }
                      }}
                    >
                      <div className="detail-items">
                        <div className="me-3">
                          <h6 className="mb-0">
                            {truncateWithEllipses(item.reason, 22)}
                          </h6>
                          <small>From {item.senderName}</small>
                        </div>
                        <div className="text-right">
                          <h5 className="mb-0">N{item.amount}.00</h5>
                          <small
                            className={
                              item.status === 'PENDING'
                                ? 'text-warning'
                                : item.status === 'PAID'
                                ? 'text-success'
                                : 'text-danger'
                            }
                          >
                            {item.status.toLowerCase()}
                          </small>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
              {totalRecieved > receivedPaymentRequest?.length && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePaginate('not')}
                >
                  <FiChevronDown />
                </div>
              )}
            </>
          )}
        </div>
      </ModalBody>

      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleSubmit}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
      {showVerifyOtp ? (
        <PinModal
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={verificationOtp}
          setOtp={setVerificationOtp}
          separator=""
          title="Otp Confirmation"
          description="Please input the Otp sent to your email address or phone number"
          isResendOtp={false}
          isResendPin={false}
          buttonLabel="Submit"
          handleSubmit={handleCompletePaymentRequest}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
      {showRequestDetails ? (
        <SingleRequest
          showModal={showRequestDetails}
          hideModal={setShowRequestDetails}
          handleNext={(val) => {
            setSelected(val);
            setShowPin(true);
          }}
          id="single-request-wrapper"
          title="Received Request"
          displayData={displayData || null}
          reference={reference}
          notes={notes}
          setNotes={setNotes}
        />
      ) : (
        ''
      )}

      {showReceipt ? (
        <TransReceipt
          showModal={showReceipt}
          hideModal={setShowReceipt}
          data={displayData}
          id="receipt-wrapper"
          title="Payment Request Invoice"
        />
      ) : (
        ''
      )}
    </>
  );
}

AllPayment.propTypes = {
  hideModal: PropTypes.bool.isRequired,
};
