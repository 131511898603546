import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { CommentCard } from './style';
import commentIcon from '../../../assets/image/message-icon.png';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
import CustomDropdown from '../Dropdown';
import DonationProgress from '../Modals/Post/DonationModal/DonationProgress';
import { ProfileContext } from '../../../store/context/ProfileContext';

export default function Gram(props) {
  const { profile: userProfile } = useContext(ProfileContext);
  const {
    data,
    handleDeletePost,
    showPostModal,
    handleLikePost,
    handleComment,
  } = props;
  const { username, avatar, displayName, UserId, id } = data?.Profile;
  const {
    description,
    likesCount,
    commentCount,
    id: postId,
    PostImages,
    isLiked,
    donationProfile,
    isDonation,
  } = data;
  const [likes, setlikes] = useState(likesCount);
  const history = useHistory();
  const myRef = React.useRef(null);

  const handleOption = async (type, postID) => {
    if (type === 'editPost') {
      const selectedData = {
        postId: postID,
        description,
      };
      showPostModal(selectedData);
    } else if (type === 'bookmark') {
      // console.log('delete');
    } else if (type === 'deletePost') {
      handleDeletePost(postID);
    }
  };

  return (
    <CommentCard ref={myRef} className="p-3">
      <div className="top-content d-flex">
        <div className="d-flex">
          <img
            src={avatar || imgPlaceholder}
            alt="gram avatar"
            className="me-3"
            style={{ height: '30px', width: '30px', borderRadius: ' 50%' }}
          />
          <p
            className="me-3 "
            aria-hidden="true"
            onClick={() => history.push(`/wayagram-profile/${id}`)}
          >
            {`${displayName}`}
          </p>
          <p className="me-3 text-muted">@{username}</p>
          <span className="text-muted">2h</span>
        </div>
        <div className="option-div ml-auto my-auto">
          <div className="dot-3x" style={{ position: 'relative' }}>
            <CustomDropdown
              id={12}
              direction="horizontal"
              handleOption={handleOption}
              options={
                userProfile?.userId === UserId
                  ? [
                      { name: 'Edit Post', type: 'editPost', id: postId },
                      { name: 'Bookmark', type: 'bookmark', id: postId },
                      { name: 'Delete Post', type: 'deletePost', id: postId },
                    ]
                  : [
                      { name: 'Bookmark', type: 'bookmark', id: postId },
                      { name: 'Report User', type: 'reportUser', id: postId },
                      { name: 'Block User', type: 'blockUser', id: postId },
                      { name: 'Follow User', type: 'followUser', id: postId },
                      { name: 'Mute User', type: 'muteUser', id: postId },
                    ]
              }
            />
          </div>
        </div>
      </div>
      <div className="middle-content">
        <div className="middle-content-description">
          {isDonation ? (
            <div className="donation-description-box">
              <div className="donation-text">{donationProfile?.title}</div>
              <div>
                <div className="donation-progress mt-3">
                  {`${donationProfile?.amountRaised} raised of ${donationProfile?.total}`}
                </div>
                <DonationProgress
                  current={donationProfile?.amountRaised}
                  total={donationProfile?.total}
                />
              </div>
            </div>
          ) : (
            <div>{description || ''}</div>
          )}
        </div>
        {PostImages?.length ? (
          <div className="img-div">
            <img src={PostImages[0]?.imageURL} alt="pic" />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="bottom-content">
        <div className="bottom-content-container mt-3">
          <div className="comment-div">
            <img
              src={commentIcon}
              alt="comment"
              style={{ width: '1em', height: '1em', cursor: 'pointer' }}
              onClick={() => {
                handleComment(true);
                showPostModal();
              }}
            />
            <span className="ms-2">{commentCount}</span>
          </div>
          <div className="comment-div">
            <i
              className={
                isLiked
                  ? 'mdi mdi-heart text-danger'
                  : 'mdi mdi-heart-outline text_text add-cursor'
              }
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => {
                if (likes >= likesCount + 1) {
                  return;
                }
                setlikes(likes + 1);
                handleLikePost(postId);
              }}
            />
            <span className="ms-2">{likes}</span>
          </div>
          <div className="comment-div">
            <i
              className={
                isLiked
                  ? 'mdi mdi-twitter-retweet text-success'
                  : 'mdi mdi-twitter-retweet'
              }
              role="button"
              tabIndex={0}
              aria-hidden="true"
              // onClick={}
            />
          </div>
          <div className="comment-div">
            <i
              className={
                isLiked
                  ? 'mdi mdi-share-variant text-success'
                  : 'mdi mdi-share-variant'
              }
              role="button"
              tabIndex={0}
              aria-hidden="true"
              // onClick={}
            />
          </div>
          {/* {isDonation ? (
            <div
              className="donation-button"
              onClick={() => setShowDonationModal(true)}
            >
              Donate
            </div>
          ) : (
            ''
          )} */}
        </div>
      </div>
    </CommentCard>
  );
}

Gram.propTypes = {
  data: PropTypes.shape.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  showPostModal: PropTypes.func.isRequired,
  handleLikePost: PropTypes.func.isRequired,
  // handleComment: PropTypes.func.isRequired,
};
