import React, { useContext, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import swal from 'sweetalert';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { Button } from 'antd';
import PinDetails from '../../../shared/Modals/Confirm/PinDetails';
import { useEffect } from 'react';

export default function WithdrawCommission(props) {
  const { showModal, hideModal, id } = props;
  const [data, setData] = useState({});
  const [showPin, setShowPin] = useState(false);
  const [pin, setPin] = useState('');
  const [balanceTo, setBalanceTo] = useState(null);
  const [balanceFrom, setBalanceFrom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const { walletList, setReload, reload } = useContext(WalletContext);
  const {
    profile: { userId },
  } = useContext(ProfileContext);
  const [walletListOne, setWalletListOne] = useState([]);
  const [walletListTwo, setWalletListTwo] = useState([]);

  const handleSubmit = async () => {
    showLoader();
    console.log('amount', data.amount);
    setLoading(true);
    const res = await httpPost(
      `/contact/account/service/send/money/userWallet/to/wallet/${userId}/${data.senderAcctNo}/${data.beneficialAcctNo}?amount=${data.amount}`,
      {},
      customBaseUrl.contactUrl,
      pin
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Transfer Successful`, 'success').then(() => {
        setLoading(false);
        setPin('');
        setReload(!reload);
        hideModal(false);
        setVerifyLoading(false);
      });
    } else {
      setLoading(false);
      hideLoader();
      setVerifyLoading(false);
      setShowPin(false);
      swal('Wrong', res.message || 'Something went wrong', 'error');
    }
  };
  const handleWallet = (acctNo) => {
    const removedSelected = [...walletList].filter(
      (item) => item.accountNo !== acctNo
    );
    setWalletListTwo(removedSelected);
  };
  const createCommissionWallet = async (uid) => {
    const res = await httpPost(
      `/api/v1/wallet/create/commission_account/${uid}`,
      {},
      customBaseUrl.walletUrl
    );

    if (res && res?.status) {
      console.log(res?.message);
    } else {
      console.log('Commission wallet error', res?.message);
    }
  };
  useEffect(() => {
    const comAcct = [...walletList]?.filter(
      (item) => item?.description === 'COMMISSION ACCOUNT'
    );
    const nonComAcct = [...walletList]?.filter(
      (item) => item?.description !== 'COMMISSION ACCOUNT'
    );
    if (comAcct.length <= 0) {
      // createCommissionWallet(userId);
    }
    setWalletListOne(comAcct);
    setWalletListTwo(nonComAcct);
  }, []);
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Transfer Funds</ModalHeader>
      <ModalBody>
        <div className="text-center mt-3 mb-3 ">Withdraw commission</div>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              defaultValue={data.senderAcctNo}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setData({
                  ...data,
                  senderAcctNo: accountNo,
                });
                handleWallet(accountNo);
                setBalanceFrom(amount);
              }}
            >
              <option value="Select" selected disabled>
                Select Commission Account to Debit
              </option>
              {walletList.length
                ? walletList
                    .filter((el) => el.accountNo.startsWith('90'))
                    .map((item) => (
                      <option
                        value={`${item?.accountNo},${item?.clr_bal_amt}`}
                        key={item?.accountNo}
                      >
                        {`${item?.description} - ${item?.accountNo} (${
                          item.acct_crncy_code
                        } ${moneyFomatter(
                          Number(item?.clr_bal_amt).toFixed(2)
                        )})`}
                      </option>
                    ))
                : ''}
            </select>

            <span className="input-detail">
              Your Balance is N{balanceFrom ? moneyFomatter(balanceFrom) : 0}.00
            </span>
          </div>

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              defaultValue={data.beneficialAcctNo}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setData({
                  ...data,
                  beneficialAcctNo: accountNo,
                });
                setBalanceTo(amount);
              }}
            >
              <option value="Select" selected disabled>
                Select Account to Credit
              </option>
              {walletListTwo.length
                ? walletListTwo.map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item?.accountNo}
                    >
                      {`${item?.description} - ${item?.accountNo} (${
                        item.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balanceTo ? moneyFomatter(balanceTo) : 0}.00
            </span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              type="number"
              placeholder="0.00"
              onChange={(e) => {
                setData({ ...data, amount: e.target.value });
              }}
              value={data.amount}
            />
            <span className="input-detail">How much do you want to Send?</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="btn btn-primary mx-auto mb-3 mt-3"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            if (!data.beneficialAcctNo || data.beneficialAcctNo === '') {
              swal('Oops!', 'fields can not be empty', 'error');
              setLoading(false);
              return;
            }
            if (!data.senderAcctNo || data.senderAcctNo === '') {
              swal('Oops!', 'fields can not be empty', 'error');
              setLoading(false);
              return;
            }
            if (!data.amount || data.amount === '') {
              swal('Oops!', 'amount field can not be empty', 'error');
              setLoading(false);
              return;
            }
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Transfer Funds"
          otp={pin}
          setOtp={setPin}
          displayData={
            {
              sender: `${data.senderAcctNo}`,
              recipent: `${data.beneficialAcctNo}`,
              amount: `${data.amount} NGN`,
              'Beneficiary Bank': `Wayabank`,
              // 'Transaction Fee': `${charges} NGN`,
              Total: `${+data.amount} NGN`,
            } || null
          }
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
}
