import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import Button from '../../Button';
import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';
//  import { ProfileContext } from '../../../../store/context/ProfileContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../../utils/loader';

const DataPurchase = (props) => {
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [network, setNetwork] = useState('');
  const [pin, setPin] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  // const [postData, setPostData] = useState({});
  const [phone, setPhone] = useState('');
  const [balance, setBalance] = useState();
  // const [inputFields, setInputFields] = useState([]);
  const [biller] = useState([]);
  const [billerData, setBillerData] = useState([]);
  const [accountData, setAccountData] = useState();
  const [dataAmount, setDataAmont] = useState();
  const [charges, setCharges] = useState(0);
  const [activeAggregator, setAggregator] = useState('');
  const { showModal, hideModal, id, walletdata, setWalletData } = props;
  const { data } = useContext(BillerContext);
  const { walletList, setReload, reload } = useContext(WalletContext);
  //  const { profile } = useContext(ProfileContext);
  const [product, setProduct] = useState([]);
  const [bundle, setBundle] = useState([]);
  const [dataBundle, setDataBundle] = useState([]);

  const getbillerCategory = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category/${biller}`
    );
    if (res?.status) {
      console.log('billerCategory', res);
      setBillerData(res.data);
    }
    console.log('billerNameData', billerData);
  };

  // const getAgregator = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`
  //   );
  //   const aggregator = res?.data;
  //   const quick =
  //     aggregator?.filter((e) => e.aggregator === 'QUICKTELLER') || {};
  //   const baxi = aggregator?.filter((e) => e.aggregator === 'BAXI') || {};
  //   const itex = aggregator?.filter((e) => e.aggregator === 'ITEX') || {};
  //   if (quick[0]?.active) {
  //     setAggregator('QUICKTELLER');
  //   } else if (baxi[0]?.active) {
  //     setAggregator('BAXI');
  //   } else if (itex[0]?.active) {
  //     setAggregator('ITEX');
  //   }
  // };

  const getCharges = async () => {
    const res = await httpGet(
      `${customBaseUrl.temporalservice}/api/v1/wallet/transaction/get-user-transaction-fee/${walletdata?.walletAccountNo}/${dataAmount}/${activeAggregator}`
    );
    console.log('res', res);
    if (res?.status !== 404) {
      setCharges(res);
    }
    console.log('Charges', charges);
  };
  useEffect(() => {
    if (activeAggregator) getCharges();
  }, [walletdata?.walletAccountNo, dataAmount]);
  // useEffect(() => {
  //   getAgregator();
  // }, []);
  useEffect(() => {
    getbillerCategory();
  }, [activeAggregator]);
  // const getFieldParams = async (billerId) => {
  //   setLoading(true);
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/category/databundle/biller/${billerId}`
  //   );
  //   if (res.status) {
  //     setLoading(false);
  //     setInputFields(res.data.items);
  //   }
  // };

  const handleSubmit = async () => {
    setLoading(true);
    if (validator.isEmpty(phone)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }

    /*  const formattedPhone = `${parsePhoneNumber(phone).countryCallingCode}${
      parsePhoneNumber(phone).nationalNumber
    }`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }

    if (formattedPhone.length > 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    } */

    if (phone.length < 11) {
      swal('Oops', 'Invalid Phone Number', 'error');
      setLoading(false);
      return;
    }

    // const walletId = walletList.filter((item) => item.defaultWallet && item);
    showLoader();
    const bundleData = {
      amount: dataAmount,
      phone: phone.replace('+', ''),
      productCode: dataBundle?.serviceProviderProduct?.productCode,
      type: dataBundle?.serviceProviderProduct?.type,
    };
    const res = await httpPost(
      `/api/v1/dataBundle/pay?serviceProviderBillerId=${
        dataBundle?.serviceProviderProduct?.serviceProviderBiller?.billerId ??
        dataBundle?.serviceProviderBiller?.billerId
      }&serviceProviderBundleId=${dataBundle?.id}&serviceProviderId=${
        dataBundle?.serviceProviderProduct?.serviceProviderBiller
          ?.serviceProviderId ??
        dataBundle?.serviceProviderBiller?.serviceProviderId
      }&userAccountNumber=${accountData}`,
      bundleData,
      customBaseUrl.billPaymentUrl2,
      pin
    );
    if (res.status) {
      setLoading(false);
      swal('Done', res?.data?.providerMessage, 'success');
      hideLoader();
      hideModal(false);
      setReload(!reload);
      setPin('');
    } else {
      setLoading(false);
      hideLoader();
      swal('Oops', res.message, 'error');
      setPin('');
    }
    hideLoader();
  };
  const getProduct = async (bid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchProductByBiller?serviceProviderBillerId=${bid}`
    );
    if (res?.status) {
      hideLoader();
      setProduct(res?.data);
    } else {
      hideLoader();
    }
  };
  const getBundle = async (pid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBundleByProduct?serviceProviderProductId=${pid}`
    );
    if (res?.status) {
      hideLoader();
      setBundle(res?.data);
    } else {
      hideLoader();
    }
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Data Purchase
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              //  value={network}
              onChange={(e) => {
                // getFieldParams(e.target.value);
                // setProduct([]);
                // setBundle([]);
                // setCustomerDetails([]);
                setProduct([]);
                setBundle([]);
                setDataAmont('');
                const {
                  hasProduct,
                  id,
                  serviceProviderCategory: {
                    serviceProvider: { name },
                  },
                } = JSON.parse(e.target.value);
                setAggregator(name);
                if (hasProduct) {
                  getProduct(id);
                }
                setNetwork(JSON.parse(e.target.value));
              }}
            >
              <option value="" selected data-default hidden>
                Network Provider
              </option>
              {data.length
                ? data.map((item) => (
                    <option value={JSON.stringify(item)} key={item.id}>
                      {item.name}
                    </option>
                  ))
                : ''}
            </select>
          </div>
          {product?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  //  getAmount(e.target.value);
                  setDataAmont('');
                  if (JSON.parse(e.target.value)?.hasBundle) {
                    getBundle(JSON.parse(e.target.value)?.id);
                  } else {
                    setDataAmont(JSON.parse(e.target.value)?.amount);
                    setDataBundle(JSON.parse(e.target.value));
                  }
                  //  setAirtimeProd(e.target.value);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Product
                </option>
                {product
                  ? product.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {bundle?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  setDataBundle(JSON.parse(e.target.value));
                  setDataAmont(JSON.parse(e.target.value)?.amount);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Bundle
                </option>
                {bundle
                  ? bundle.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name} - N{moneyFomatter(item.amount)} -{}
                        {item.validity}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {dataAmount ? (
            <div className="inputbox-with-one-input">
              <input
                type="text"
                disabled
                value={dataAmount ? `N${moneyFomatter(dataAmount)}` : ''}
                placeholder="Amount"
              />
            </div>
          ) : (
            ''
          )}
          {network ? (
            <div className="inputbox-with-one-input">
              <input
                maxLength={11}
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          ) : (
            ''
          )}

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={walletdata.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setWalletData({
                  ...walletdata,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
                setAccountData(accountNo);
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {/* {`${item?.acct_crncy_code}-${item?.accountNo}`} */}
                          {`${item?.description} - ${item?.accountNo} (${
                            item.acct_crncy_code
                          } ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
          <div>
            <span> Transaction Fees N{charges || 0}.00 </span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Data Purchase"
          otp={pin}
          setOtp={setPin}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

DataPurchase.defaultProps = {
  id: '',
};

DataPurchase.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default DataPurchase;
