import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import ButtonComponent from '../../shared/Button';
import moneyFomatter from '../../../utils/moneyFormatter';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const AccountRoles = ({ bankList }) => {
  const [disabled, setDisables] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedAcount, setSelectedAccount] = useState('');
  const [showRoles, setShowAddRoles] = useState(false);
  const [data, setData] = useState([]);

  const getAddedUsers = async (act) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/all/added-users?accountNumber=${act}`
    );
    if (res.status) {
      console.log(res);
    } else {
      console.log(res);
    }
  };

  useEffect(() => {
    setDisables(false);
    setLoading(false);
    if (bankList.length > 0) {
      setData({ ...data, accountNo: bankList[0].accountNo });
    }
  }, [bankList]);

  useEffect(() => {
    if (bankList.length > 0 && selectedAcount === '') {
      setSelectedAccount(bankList[0].accountNo);
    }
    if (selectedAcount) getAddedUsers(selectedAcount);
  }, [selectedAcount]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    const profileId = localStorage.getItem('profileId');
    const approverdData = {
      accountNumber: data?.accountNo,
      approvers: [
        {
          approverLimit: data?.approver ? data?.approverLimit : null,
          emailAddress: data?.approver ? data?.emailAddress : '',
        },
      ],
      profileId,
    };
    const initData = {
      accountNumber: data?.accountNo,
      initiators: [
        {
          emailAddress: data?.initiator ? data.emailAddress : '',
          initiatorLimit: data?.initiator ? data.initiatorLimit : null,
        },
      ],
      profileId,
    };
    const reviewData = {
      accountNumber: data?.accountNo,
      profileId,
      reviewers: [
        {
          emailAddress: data?.reviewer ? data?.emailAddress : '',
          reviewerLimit: data?.reviewer ? data?.reviewerLimit : null,
        },
      ],
    };
    const res = await httpPost(
      '/api/v1/user/create/other-users',
      data.roleType === 'reviewer'
        ? reviewData
        : data.roleType === 'initiator'
        ? initData
        : approverdData,
      customBaseUrl.authUrl
    );
    if (res?.status) {
      swal(res?.message);
      hideLoader();
    } else {
      hideLoader();
      swal(res?.message);
      console.log(res);
    }
  };

  return (
    <div className="account-statement">
      {!showRoles ? (
        <div>
          <div className="text-end">
            <button
              className="btn btn-primary"
              onClick={() => setShowAddRoles(true)}
            >
              Add Role
            </button>
          </div>
          <div className="as-body">
            <div>Select Account Number</div>
            <select
              name=""
              onChange={(e) => {
                setData({ ...data, accountNo: e.target.value });
                setSelectedAccount(e.target.value);
              }}
              id=""
              className="border rounded"
              value={data.accountNo}
            >
              {bankList.length > 0 ? (
                bankList.map((item) => (
                  <option key={item.accountNo} value={item.accountNo}>
                    {item.accountNo} - {item?.description} - NGN
                    {moneyFomatter(item?.clr_bal_amt)}
                  </option>
                ))
              ) : (
                <option value="0">No Account Yet</option>
              )}
            </select>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="as-head">
            <div>Account Roles</div>
          </div>
          <div className="as-body">
            <div>
              <div>Select Account Number</div>
              <select
                name=""
                onChange={(e) => {
                  setData({ ...data, accountNo: e.target.value });
                  setSelectedAccount(e.target.value);
                }}
                id=""
                className="border"
                value={data.accountNo}
              >
                {bankList.length > 0 ? (
                  bankList.map((item) => (
                    <option key={item.accountNo} value={item.accountNo}>
                      {item.accountNo} - {item?.description} - NGN
                      {moneyFomatter(item?.clr_bal_amt)}
                    </option>
                  ))
                ) : (
                  <option value="0">No Account Yet</option>
                )}
              </select>
            </div>
            <div>
              <div>Email Address</div>
              <input
                className="form-input"
                type="email"
                required
                value={data?.emailAddress}
                onChange={(e) =>
                  setData({ ...data, emailAddress: e.target.value })
                }
              />
            </div>
            {/* <div>
              <div>Select Role</div>
              <label>
                <input
                  type="checkbox"
                  onClick={(e) => {
                    setData({ ...data, initiator: e.target.checked });
                  }}
                />
                Initiator
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={data.reviewer}
                  onClick={(e) =>
                    setData({ ...data, reviewer: e.target.checked })
                  }
                />
                Reviewer
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={data.approver}
                  onClick={(e) =>
                    setData({ ...data, approver: e.target.checked })
                  }
                />
                Approver
              </label>
            </div> */}
            <div>
              <div>Select Role</div>
              <select
                name=""
                onChange={(e) => {
                  setData({ ...data, roleType: e.target.value });
                }}
                id=""
                className="border"
                value={data.roleType}
              >
                <option value="initiator">INITIATOR</option>
                <option value="reviewer">REVIEWER</option>
                <option value="approver">APPROVER</option>
              </select>
            </div>
            {data?.roleType === 'initiator' ? (
              <div>
                <label className="form-label mt-3">Initiator Limit</label>
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  required
                  value={data?.initiatorLimit}
                  onChange={(e) =>
                    setData({ ...data, initiatorLimit: e.target.value })
                  }
                />
              </div>
            ) : (
              ''
            )}
            {data?.roleType === 'reviewer' ? (
              <div>
                <label className="form-label mt-3">Reviewer Limit</label>
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  required
                  value={data?.reviewerLimit}
                  onChange={(e) =>
                    setData({ ...data, reviewerLimit: e.target.value })
                  }
                />
              </div>
            ) : (
              ''
            )}
            {data?.roleType === 'approver' ? (
              <div>
                <label className="form-label mt-3">Approver Limit</label>
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  required
                  value={data?.approverLimit}
                  onChange={(e) =>
                    setData({ ...data, approverLimit: e.target.value })
                  }
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="as-button">
            <button
              type="submit"
              loading={loading}
              disabled={(loading ? true : null) || disabled}
              // onClick={handleSubmit}
              style={{ opacity: disabled ? 0.5 : 1 }}
              content="Add Role"
              className="btn btn-primary"
            >
              Add Role
            </button>
          </div>
        </form>
      )}
      <div style={{ height: 100 }} />
    </div>
  );
};

export default AccountRoles;
