import React, { useState, useContext, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import swal from 'sweetalert';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { hideLoader, showLoader } from '../../../utils/loader';
import AdvanceNotification from './advanceNotification';
import ConfirmModal from './confirmModal';
import './setting.scss';

function Notification(props) {
  const { userDetails } = useContext(ProfileContext);
  const [smsAlertStatus, setSmsAlertStatus] = useState(false);
  const [twoFAStatus, set2FAStatus] = useState(false);
  const { options } = props;
  const [showModal, hideModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showSms, setShowSms] = useState(false);

  const getUsersSmsAlertStatus = async () => {
    const res = await httpGet(
      `${
        customBaseUrl.authUrl
      }/api/v1/profile/sms-alert/status/${userDetails?.phoneNumber?.slice(1)}`
    );
    if (res && res.status) {
      setSmsAlertStatus(res.data?.active);
    }
  };
  const getUser2FAStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/status-check-2fa/${userDetails?.userId}`
    );
    if (res && res.status) {
      set2FAStatus(res.data['2fa']);
    }
  };

  const handleClick = (title) => {
    if (title === 'SMS notifications') {
      setConfirmTitle(title);
      if (smsAlertStatus === false) {
        setDescription('Do you want to enable SMS charges ?');
      } else {
        setDescription('Do you want to disable SMS charges ?');
      }
      hideModal(true);
    }
    if (title === '2FA') {
      setConfirmTitle(title);
      if (twoFAStatus === false) {
        setDescription('Do you want to enable 2FA ?');
      } else {
        setDescription('Do you want to disable 2FA ?');
      }
      hideModal(true);
    }
  };
  const handleConfirmation = async () => {
    if (confirmTitle === 'SMS notifications') {
      showLoader();
      const postData = {
        phoneNumber: userDetails?.phoneNumber?.slice(1),
      };
      const res = await httpPost(
        `/api/v1/profile/sms-alert`,
        postData,
        customBaseUrl.authUrl
      );
      if (res.status) {
        setSmsAlertStatus(res.data?.active);
        hideLoader();
        hideModal(false);
        swal('Success', 'Update Successfully', 'success');
      } else {
        getUsersSmsAlertStatus();
        hideLoader();
        hideModal(false);
        swal('Error', res.message, 'error');
      }
    }
    if (confirmTitle === '2FA') {
      showLoader();
      const postData = {
        phoneNumber: userDetails?.phoneNumber?.slice(1),
      };
      const res = await httpPost(
        `/api/v1/auth/toggle-2fa?userId=${userDetails?.userId}`,
        postData,
        customBaseUrl.authUrl
      );
      if (res.status) {
        set2FAStatus(res.data?.active);
        hideLoader();
        hideModal(false);
        swal('Success', 'Update Successfully', 'success');
      } else {
        getUser2FAStatus();
        hideLoader();
        hideModal(false);
        swal('Error', res.message, 'error');
      }
    }
  };

  useEffect(() => {
    getUsersSmsAlertStatus();
    getUser2FAStatus();
  }, []);

  const handleClick1 = (title) => {
    console.log(title);
    if (title === 'Email notifications') {
      setShowSms(true);
    }
  };
  return (
    <div className="setting-inner-item">
      <div className="menu-items p-2">
        {options &&
          options.map(({ id, title }) => (
            <Container
              key={id}
              className="row m-0 col-12 d-flex"
              onClick={() => handleClick1(title)}
            >
              <div className="col-11 pl-3 py-2 my-auto">
                <div className="mb-0">{title}</div>
              </div>
              <div className="col-1 my-auto">
                <div className="form-check form-switch mb-20">
                  {title === 'SMS notifications' ? (
                    <input
                      checked={smsAlertStatus}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      onChange={() => handleClick(title)}
                    />
                  ) : title === 'Email notifications' ? (
                    <div className="col-1 my-auto form-input mr-10">{'>'}</div>
                  ) : title === '2FA' ? (
                    <input
                      checked={twoFAStatus}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      onChange={() => handleClick(title)}
                    />
                  ) : (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      onChange={() => handleClick(title)}
                    />
                  )}
                </div>
              </div>
            </Container>
          ))}
        <div style={{ height: 100 }}></div>
      </div>
      <ConfirmModal
        showModal={showModal}
        hideModal={hideModal}
        description={description}
        title={confirmTitle}
        handleSubmit={handleConfirmation}
      />
      {showSms ? (
        <AdvanceNotification
          showModal={showSms}
          hideModal={setShowSms}
          description={description}
          title={confirmTitle}
          handleSubmit={handleConfirmation}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Notification;
